import React, { useState, useEffect } from 'react'
import '../../Style/login_popup.css';

function AccountSetup_captcha() {
  const intialValues = {
    mobileNumber: "",
  };
  const [mobileNumber, setMobileNumber] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [formValues,] = useState(intialValues);

  const [selectedCountry, setSelectedCountry] = useState('in');
  const countries = [
    { code: 'in', name: 'India', countryCode: '+91' },
    { code: 'sa', name: 'Saudi Arabia', countryCode: '+966' },
    { code: 'eg', name: 'Egypt', countryCode: '+20' },
    { code: 'au', name: 'Australia', countryCode: '+61' },
    { code: 'cn', name: 'Canada', countryCode: '+1' },
    { code: 'fr', name: 'France', countryCode: '+33' },
    { code: 'pk', name: 'Pakistan', countryCode: '+92' },
    { code: 'sl', name: 'Sri Lanka', countryCode: '+94' }
  ];

  const validate = (values) => {
    const errors = {};
    var mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile Number is required!";
    } else if (!mobileNumberRegex.test(values.mobileNumber)) {
      errors.mobileNumber = "This is not a valid mobile Number format!";
    }
    return errors;
  }

  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    setSelectedCountry(selectedCountryCode);
  };

  const getCountryCode = (selectedCountry) => {
    const country = countries.find((c) => c.code === selectedCountry);
    return country ? country.countryCode : '';
  };

  const handleSubmit = () => {
    const errors = validate(formValues);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully!");
    } else {
      setFormErrors(errors);
    }
  };
  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };
  const [captcha, setCaptcha] = useState('');

  // Function to generate a random captcha
  const generateCaptcha = () => {
    const randomCaptcha = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
    setCaptcha(randomCaptcha.toString());
  };

  useEffect(() => {
    // Generate initial captcha on component mount
    generateCaptcha();
  }, []);
  return (
    <>
      <div className='zw_popup'>
        <div className="modal fade zw_captch_popup" id="accountsetup_captcha" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='btn-space'>
                  <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                    <i className="icon-close"></i>
                  </button>
                </div>
                {/* <div className="mob-header mob-block">Verification</div> */}
                <div className="card card-primary-light card-no-border last-child">
                  <div className="login-brd">
                    <form>
                      <div className="input-group input-group-vert mb20">
                        <label className='zw_text_color poppins-regular zw_32'>Country</label>
                        <div className="form-group zw_form_group" style={{ maxHeight: "55px" }}>
                          <div className='zw_form_control zw_secondary poppins-regular zw_16'>
                            <div>
                              <div className=' zw_country_sec zw_border_radius zw_light_bg'>
                                {selectedCountry && (
                                  <img
                                    className="form-icon img-ccode form-img zw_country_flag"
                                    src={`https://sanar-assets.com/flags/${selectedCountry}_64.png`}
                                    alt="Country Flags"
                                  />
                                )}
                                <span className="cuntry-plholder" >({getCountryCode(selectedCountry)})</span>
                              </div>

                              <select
                                id="countrycode"
                                name="countrycode"
                                className="form-control input-no-border"
                                value={selectedCountry}
                                onChange={handleCountryChange}
                              >
                                {countries.map((country) => (
                                  <option key={country.code} value={country.code}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              <i className="icon-down-arrow form-icon zw_icon_drop"></i>

                            </div>

                          </div>
                        </div>
                        <div className="form-group zw_form_group">
                          <input type='text' className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='National ID or Iqama number' style={{ width: "100%" }} />
                        </div>
                      </div>

                      {/* <div className="form-group zw_form_group"> */}
                      {/* <span className="cuntry-plholder">({getCountryCode(selectedCountry)})</span> */}
                      {/* <input type="tel" className={`form-control input-no-border err-border ${formErrors.mobileNumber && 'form-error'}`} id="mobile_number" name="Mobile Number" placeholder='Nation ID or Iqama number' value={mobileNumber} onChange={handleMobileNumberChange}></input>
                      {formErrors.mobileNumber && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.mobileNumber}</span>} */}

                      {/* </div> */}
                      <label className='poppins-regular zw_22 zw_black'>Enter Captcha</label>
                      <div className="form-group zw_form_group">
                        {/* <span className="cuntry-plholder">({getCountryCode(selectedCountry)})</span> */}
                        {/* <input type="number" placeholder='Captcha'></input> */}
                        <div className='row mb-4'>
                          <div className='col-md-7'>
                            <input type="number" className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder="Captcha" maxLength={"4"} />

                          </div>
                          <div className='col-md-3'>
                            <p className="captcha-text zw_secondary poppins-regular zw_16 mt-3">{captcha}</p>

                          </div>
                          <div className='col-md-2'>
                            <i className="icon-reload sa-otp-ref" onClick={generateCaptcha}></i>
                          </div>
                        </div>

                        {/* <div style={{ display: 'flex', alignItems: 'center', gap: "82px" }}>
                        <input type="number" className='zw_form_control zw_secondary poppins-regular zw_16' placeholder="Captcha" />
                        <span className="captcha-text">{captcha}</span>
                        <i className="icon-reload sa-otp-ref" style={{ fontSize: "35px" }} onClick={generateCaptcha}></i>
                        <button type="button" className="btn-refresh" onClick={generateCaptcha}>
                        Refresh
                      </button>
                      </div> */}

                      </div>

                      <button type="button" className="btn-primary btn-lg btn-block poppins-regular zw_bg_gradient zw_btn_18 zw_sbmtbtn_radius" style={{ width: "80%", margin: " 0 10%" }} onClick={handleSubmit} data-bs-toggle="modal" data-bs-target="#OTP">
                        Continue
                      </button>
                    </form>

                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default AccountSetup_captcha