import React, { useState } from 'react';
import '../../../Style/payment-details.css';

function Paymentdetails() {
    const [cardHolderName, setCardHolderName] = useState('');
    const [number, setNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [cvv, setCVV] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true); // State to track modal open/close

    const handleInputChange = (event) => {
        setCardHolderName(event.target.value);
    };

    const handleNumberChange = (event) => {
        const inputNumber = event.target.value.replace(/\D/g, '');
        setNumber(inputNumber);
    };

    const handleChange = (event) => {
        setMonth(event.target.value);
    };

    const handleChangeInput = (event) => {
        setYear(event.target.value);
    };

    const handleInputClick = (event) => {
        setCVV(event.target.value);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleModalClick = (event) => {
        // Prevent closing the modal when clicking inside it
        event.stopPropagation();
    };

    return (
        <div>
            {isModalOpen && (
                <div className='zw_popup' onClick={handleCloseModal}>
                    <div className="modal fade zw_Healthpackage" id="Paymentdetails" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" onClick={handleModalClick}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content" style={{ width: "75%" }}>
                                <div className="modal-body">
                                    <div className='btn-space'>
                                        <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                            <i className="icon-close"></i>
                                        </button>
                                    </div>
                                    <div className="payment-details-toe" style={{ marginLeft: "-60px" }}>
                                        <div className="group-1261154744-FzC">

                                            <p className="selected-card-add-new-card-7mW">Selected card /add new card</p>
                                            <div className="group-1261154935-Cnx">
                                                <img className="group-1261154937-LPN" src="/images/group-1261154937.png" alt='' />
                                                <p className="add-new-card-gCL">Add new card</p>
                                            </div>
                                            <div className="group-1261154699-pZS">
                                                <p className="name-on-card-ZG8">Name on card</p>
                                                <div className="auto-group-m6jy-V9n">
                                                    <input
                                                        type="text"
                                                        value={cardHolderName}
                                                        onChange={handleInputChange}
                                                        placeholder="card holder name"

                                                    />
                                                </div>
                                            </div>
                                            <div className="group-1261154729-MSt">
                                                <p className="call-number-fyN">Call Number</p>
                                                <input
                                                    type="number"
                                                    className="auto-group-joye-DEC"
                                                    value={number}
                                                    onChange={handleNumberChange}
                                                    placeholder="0000 0000 0000 0000"
                                                />
                                            </div>

                                            <div className="group-1261154743-Xu6">
                                                <div className="group-1261154742-TXr">
                                                    <div className="group-1261154741-CVS">
                                                        <div className="group-1261154730-whv">
                                                            <p className="call-number-6ap">Card Details</p>
                                                            <div className="auto-group-vezg-ES8">
                                                                <input
                                                                    type="text"
                                                                    value={month}
                                                                    onChange={handleChange}
                                                                    placeholder="MM"
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className="switch">
                                                                    <input type="checkbox"></input>
                                                                    <span className="slider1 round"></span>
                                                                </label></div>
                                                            <div style={{ marginTop: "-35px" }}>
                                                                <p className="save-this-card-6jE">Save this card</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="group-1261154739-p9S">
                                                        <input
                                                            type="text"
                                                            value={year}
                                                            onChange={handleChangeInput}
                                                            placeholder="YY"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="group-1261154740-dcg">
                                                    <p className="cid-cvv-b3i">CID/CVV</p>
                                                    <div className="auto-group-wzly-vbn">
                                                        <input
                                                            type="text"
                                                            value={cvv}
                                                            onChange={handleInputClick}
                                                            placeholder="CID/CVV"
                                                            maxLength={3}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rectangle-39577-ofa">
                                            </div>
                                            <p className="close-iGk" onClick={handleCloseModal}>Close</p>

                                            <img className="image-81-Rwr" src="/images/image-81.png" alt='' />
                                            <img className="image-82-ZYG" src="/images/image-82.png" alt='' />
                                            <img className="image-84-taY" src="/images/image-84.png" alt='' />
                                            <img className="image-85-pj6" src="/images/image-85.png" alt='' />
                                            <img className="image-86-uVe" src="/images/image-86.png" alt='' />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Paymentdetails;
