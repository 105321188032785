import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import { useNavigate } from 'react-router-dom';

function Terms_Conditions() {
    const navigate = useNavigate();
    return (
        <div>
            <Navbar />
            <div className="container content" id="scrollToTop" style={{ marginTop: "80px" }}>
                <span className="btn-back zw_btn_18 poppins-medium zw_black" onClick={() => navigate(-1)}>
                    <i className="icon-arrow-left"></i>
                    <span className="mob-none">Back</span>
                </span>
                <div className='mt-4 pt-4'>
                    <h4 className="tit zw_24 zw_text_color poppins-semibold">Terms and Conditions</h4>
                    <p className='poppins-regular zw_16 zw_text_color'>These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Zwaara mobile application (the "Service") operated by Zwaara -e- Commerce Company LLC ("us", "we", or "our"). Please read these Terms and Conditions carefully before using our Zwaara mobile application (the "Service"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
                </div>



                <div className="container zw_faq zw_privacy_faq">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item mb-4">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    Purchases
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information. You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete. By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases. We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
                                        - We accept payments online using Visa and MasterCard credit/debit card in SAR.
                                        - Refunds will be done only through the Original Mode of Payment.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    WebsiteVisitor
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Like most website operators, Sanar eCommerce Company LLC collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Sanar eCommerce Company LLC's purpose in collecting non-personally identifying information is to better understand how Sanar eCommerce Company LLC's visitors use its website. From time to time, Sanar eCommerce Company LLC may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Gathering of Personally-Identifying Information
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Certain visitors to Sanar eCommerce Company LLC's websites choose to interact with Sanar eCommerce Company LLC in ways that require Sanar eCommerce Company LLC to gather personally-identifying information. The amount and type of information that Sanar eCommerce Company LLC gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://www.sanar.sa to provide a username and email address.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    Security
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    Advertisements
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Sanar eCommerce Company LLC and does not cover the use of cookies by any advertisers.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                    Links To External Sites
                                </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    Protection of Certain Personally-Identifying Information
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Sanar eCommerce Company LLC discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Sanar eCommerce Company LLC's behalf or to provide services available at Sanar eCommerce Company LLC's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Sanar eCommerce Company LLC's website, you consent to the transfer of such information to them. Sanar eCommerce Company LLC will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Sanar eCommerce Company LLC discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Sanar eCommerce Company LLC believes in good faith that disclosure is reasonably necessary to protect the property or rights of Sanar eCommerce Company LLC, third parties or the public at large.
                                        If you are a registered user of https://www.sanar.sa and have supplied your email address, Sanar eCommerce Company LLC may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Sanar eCommerce Company LLC and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Sanar eCommerce Company LLC takes all measures reasonably necessary to protect against unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    Aggregated Statistics
                                </button>
                            </h2>
                            <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Sanar eCommerce Company LLC may collect statistics about the behavior of visitors to its website. Sanar eCommerce Company LLC may display this information publicly or provide it to others. However, Sanar eCommerce Company LLC does not disclose your personally-identifying information

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    Cookies
                                </button>
                            </h2>
                            <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        To enrich and perfect your online experience, Sanar eCommerce Company LLC uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
                                        A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Sanar eCommerce Company LLC uses cookies to help Sanar eCommerce Company LLC identify and track visitors, their usage of https://www.sanar.sa, and their website access preferences. Sanar eCommerce Company LLC visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Sanar eCommerce Company LLC's websites, with the drawback that certain features of Sanar eCommerce Company LLC's websites may not function properly without the aid of cookies.
                                        By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Sanar eCommerce Company LLC's use of cookies.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    Privacy Policy Changes
                                </button>
                            </h2>
                            <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Although most changes are likely to be minor, Sanar eCommerce Company LLC may change its Privacy Policy from time to time, and in Sanar eCommerce Company LLC's sole discretion. Sanar eCommerce Company LLC encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            {/* <div className="container content" id="scrollToTop" style={{ marginTop: "80px" }}>
               


                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Purchases
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information. You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete. By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases. We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
                                - We accept payments online using Visa and MasterCard credit/debit card in SAR.
                                - Refunds will be done only through the Original Mode of Payment.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Availability, Errors and Inaccuracies
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mis-priced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites. We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Contests, Sweepstakes and Promotions
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through the Service may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                Accounts
                            </button>
                        </h2>
                        <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third- party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorised use of your account. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorisation, or a name that is otherwise offensive, vulgar or obscene.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                Intellectual Property
                            </button>
                        </h2>
                        <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                The Service and its original content, features and functionality are and will remain the exclusive property of Sanar eCommerce Company LLC and its licensors. The Service is protected by copyright, trademark, and other laws of both the Saudi Arabia and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Sanar eCommerce Company LLC.                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                Links To Other Websites
                            </button>
                        </h2>
                        <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                Our Service may contain links to third-party web sites or services that are not owned or controlled by Sanar eCommerce Company LLC. Sanar eCommerce Company LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that Sanar eCommerce Company LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                Termination
                            </button>
                        </h2>
                        <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                Our Service may contain links to third-party web sites or services that are not owned or controlled by Sanar eCommerce Company LLC. Sanar eCommerce Company LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that Sanar eCommerce Company LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                Limitation Of Liability
                            </button>
                        </h2>
                        <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                In no event shall Sanar eCommerce Company LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damages, and even if a remedy set forth herein is found to have failed of its essential purpose.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                Disclaimer
                            </button>
                        </h2>
                        <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether expressed or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. Sanar eCommerce Company LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                Governing Law
                            </button>
                        </h2>
                        <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                These Terms shall be governed and construed in accordance with the laws of Saudi Arabia, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
                                - All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.
                                - Saudi Arabia is our country of domicile Website will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of Saudi Arabia
                                - Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of Saudi Arabia.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                Changes
                            </button>
                        </h2>
                        <div id="flush-collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-4" style={{ border: "1px solid" }}>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                Contact Us
                            </button>
                        </h2>
                        <div id="flush-collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                If you have any questions about these Terms, please contact us.
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </div>
    )
}

export default Terms_Conditions