import React, { useState } from 'react'
import '../../../Style/payment.css'

import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';

import Cancelation from './../../Home/Laboratory/Cancelation.js';
import Paymentdetails from './Paymentdetails';
import { Link } from 'react-router-dom';

function Payment() {
    const [showPopup, setShowPopup] = useState(false);

    const handlePaymentMethodChange = (event) => {
        if (event.target.value === "split") {
            setShowPopup(true);
        } else {
            setShowPopup(false);
        }
    };
    return (
        <div>
            <Navbar />
            <div className="payment-mxg" style={{ marginTop: "100px", marginLeft: "0px" }}>
                <div className="group-1261154697-tnQ">
                    <div className="group-1261154093-zqS">
                       
                        <Link to='/' style={{ textDecoration: 'none', cursor:'pointer' }}>
                        <div className="group-1261154076-Kcp" data-bs-toggle="modal" data-bs-target="#Cancellationpage">
                            <Cancelation />
                            <img src='./images/Group 1261154072.png' className='group-1261154072-sPS' alt=''></img>
                            <p className="back-xvg">Back</p>
                        </div>
                        </Link>
                        <div className="group-1261154073-tJY">
                            <div className="rectangle-39545-owJ">
                            </div>
                            <div className="rectangle-39546-Wap">
                            </div>
                            <div className="rectangle-39547-SzG">
                            </div>
                            <div className="rectangle-39548-b6U">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auto-group-fdty-WUL">
                    <div className="auto-group-xk7n-qFi">
                        <div className="group-1261154784-xbE">
                            <div className="group-1261154725-g1S">
                                <div className="group-1261154718-RUp">
                                    <img src='./images/logo-hLY.png' className='logo-9Qp' alt=''></img>
                                    <div className="group-1261154716-4Gt">
                                        <p className="zwaara-D9n">zwaara</p>
                                        <p className="laboratory-riysdh-saudi-arebia-XwA">Laboratory Riysdh, Saudi Arebia</p>
                                    </div>
                                </div>
                                <div className="group-1261154724-FsA">
                                    <div className="auto-group-qwxx-Qzx">
                                        <p className="laboratory-m4p">Laboratory</p>
                                        <div className="group-1261154723-uB2">
                                            <img src='./images/vector-H2U.png' className='vector-qqN' alt=''></img>
                                            <p className="oct-23-2023-900-pm-nVi">Oct 23, 2023 - 9;00 PM</p>
                                        </div>
                                    </div>
                                    <p className="noha-abdullah-GQt">Noha Abdullah</p>
                                </div>
                            </div>
                            <div className="group-1261154729-CJY">
                                <img src='./images/group-1261154727.png' className='group-1261154727-8xt' alt=''></img>
                                <p className="riyadh-saudia-arabia-UWx">Riyadh, Saudia Arabia</p>
                            </div>
                        </div>
                        <div className="group-1261154776-kDa">
                            <div className="group-1261154766-Ttg">
                                <div className="group-1261154735-nvx">
                                    <img src='./images/group-1261154767.png' className='group-1261154767-vXN' alt=''></img>
                                    <input className="add-promo-code-here-rR2" placeholder='Add Promo code Here'></input>
                                </div>
                                <button type="button" className="apply-nZa" style={{ background: "transparent", border: "none" }}>Apply</button>
                            </div>
                            <div className="group-1261154767-gur">
                                <div className="group-1261154775-3kQ">
                                    <p className="sar-0-use-wallet-balance-ye4">SAR 0 Use wallet balance</p>

                                    <label class="switch">
                                        <input type="checkbox"></input>
                                        <span class="slider round"></span>
                                    </label>
                                    
                                </div>
                            </div>
                            <div className="auto-group-an2g-hyN">
                                <div className="group-1261154768-4Z2">
                                </div>
                                <div className="group-1261154771-miL">
                                    <p className="pay-with-qitaf-point-smN">Pay with  qitaf point</p>
                                    <img className="vector-QFW" src="./assets/vector-Y44.png" alt='' />
                                </div>
                            </div>
                            <div className="group-1261154772-Jbn">
                                <p className="pay-with-qitaf-point-ST6">Pay with  qitaf point</p>
                                <img className="vector-Znc" src="./assets/vector-xzQ.png" alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="group-1261154781-8z8">
                        <p className="select-payment-method-tTW">Select  Payment Method</p>
                        <div className="auto-group-anua-c8c">
                            <div className="auto-group-zfxx-v9J">
                                
                                <div className="group-1261154745-d3i">
                                    
                                    <div className='radio_btn_container group-1261154734-ApL'>
                                        <label className="radio_btn_label">
                                            <input className="radio-button-4X6 form-check-input" value={""} onChange={handlePaymentMethodChange} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                            <span className='radio-button-span split-into-3-payments-ejW'>Split into 3 payments</span>
                                        </label>
                                    </div>
                                    <img src='./images/image-79.png' className='image-79-n52' alt=''></img>
                                </div>
                            </div>
                            <div className="group-1261154756-GF6">
                                <div className="group-1261154746-boA">
                                    <div className='radio_btn_container group-1261154735-xdi'>
                                        <label className="radio_btn_label">
                                            <input className="radio-button-4X6 form-check-input" value={""} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                            <span className='radio-button-span interests-free-payment-Fcp'>4 interests-free payment</span>
                                        </label>
                                    </div>
                                    
                                    <img src='./images/image-80.png' className='image-80-NxL' alt=''></img>

                                </div>
                            </div>
                            <div className="group-1261154757-SxC">
                                <div className="group-1261154750-ANQ">
                                    <div className='radio_btn_container group-1261154735-iep'>
                                        <label className="radio_btn_label">
                                            <input className="radio-button-4X6 form-check-input" value={""} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                            <span className='radio-button-span apple-pay-1tp'>Apple Pay</span>
                                        </label>
                                    </div>
                                    
                                    <img src='./images/image-83.png' className='image-83-Z9e' alt=''></img>
                                </div>
                            </div>
                            <div className="group-1261154758-2J8">
                                <div className="group-1261154751-9Nk">
                                    <div className='radio_btn_container group-1261154735-VhW' data-bs-toggle="modal" data-bs-target="#Paymentdetails">

                                        <Paymentdetails /><label className="radio_btn_label">
                                            <input className="radio-button-4X6 form-check-input" value={""} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                            <span className='radio-button-span card-payment-Cbv'>Card Payment</span>
                                        </label>
                                    </div>
                                    <div className="auto-group-46ep-Y9z">                                       
                                        <img src='./images/image-81-L8C.png' className='image-81-fVW' alt=''></img>
                                        <img src='./images/image-84-cVr.png' className='image-84-zng' alt=''></img>
                                        <img src='./images/image-82-ZJL.png' className='image-82-YZJ' alt=''></img>
                                        <img src='./images/image-85-u8g.png' className='image-85-6Kv' alt=''></img>
                                        <img src='./images/image-86-brU.png' className='image-86-Cdr' alt=''></img>
                                    </div>
                                </div>
                            </div>
                            <p className="price-breakup-KCg">Price Breakup</p>
                            <p className="selected-package-F6L">Selected Package</p>
                            <div className="auto-group-fkt2-aeQ">
                                <div className="group-1261154763-WHA">
                                    <p className="health-check-packages-home-visit-fee-vat--FkY">
                                        <span className="health-check-packages-home-visit-fee-vat--FkY-sub-0">
                                            Health Check Packages
                                            <br />

                                            <br />
                                            Home Visit Fee
                                            <br />

                                            <br />
                                            VAT
                                        </span>
                                        <span className="health-check-packages-home-visit-fee-vat--FkY-sub-1">(+)</span>
                                    </p>
                                    <p className="sar-350-sar-100-sar-673-Z2U">
                                        SAR 350
                                        <br />

                                        <br />
                                        SAR 100
                                        <br />

                                        <br />
                                        SAR 67.3
                                    </p>
                                </div>
                                <Link to='/laboratory' style={{ textDecoration: 'none', cursor:'pointer' }}>
                                    <button type="button" className="group-1261154765-sYx" style={{ border: "none" }}>
                                        <p className="amount-payable-R4g">Amount payable</p>
                                        <p className="sar-5175-kck">SAR 517.5</p>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-1261154783-zX6">
                    <div className="group-1261154782-ue4">
                        <div className="group-1261154778-Tfa">
                            <p className="c-ydv">C</p>
                            <div className="ellipse-89-tVz">
                            </div>
                        </div>
                        <p className="zwaara-llc-1qW">2023 zwaara LLC</p>
                    </div>
                    <div className="group-1261154780-kYC">
                        <p className="privacy-policy-6rx">Privacy Policy</p>
                        <p className="terms-and-conditions-FE4">Terms and conditions</p>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="popup">
                    {/* Add your content for the popup here */}
                    <p>Details regarding split 3 payments...</p>
                </div>
            )}
            <Footer />
        </div>
    )
}

export default Payment
