import React, { useState, useEffect } from 'react'

function HomeFrequentlyQue() {

    const [faqData, setFaqsData] = useState([]);
    useEffect(() => {
        fetch('https://zwarra.biztechnosys.com/api/faqs')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setFaqsData(data);
            });
    }, []);

    return (
        <>
            <section className="faq-box zw_faq">
                <div className="container">
                    <div className="faq-box-inner">
                        <h2 className="text-center  margin-hed-one zw_title_color zw_46 poppins-semibold">Frequently Asked Question</h2>
                        <p className="text-center poppins-regular zw_16">
                            Lorem ipsum dolor sit amet consectetur. Diam faucibus ac porttitor tincidunt
                            egestas sapien fermentum feugiat. Tortor aliquam  </p>
                        <div className="accordion" id="myAccordion">

                            {faqData.map((item) => (
                                <>
                                    <div className="accordion-item" key={item.key}>
                                        <h2 className="accordion-header" id={`header${item.id}`}>
                                            <button type="button" className="accordion-button collapsed poppins-regular zw_secondary" data-bs-toggle="collapse"
                                                data-bs-target={`#accordion_${item.id}`}>{item.Question}</button>
                                        </h2>
                                        <div id={`accordion_${item.id}`} className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p className='p-4 poppins-regular zw_secondary'>{item.Answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default HomeFrequentlyQue
