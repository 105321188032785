import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import '../../Style/Caregiver.css';
import care1 from '../../assets/img/care1.jpeg';
import care2 from '../../assets/img/care2.jpeg';
import care3 from '../../assets/img/care3.jpeg';
import care4 from '../../assets/img/care4.jpeg';
import { Link } from 'react-router-dom';
function Caregiver() {
    return (
        <div>
            <Navbar />
            <div class="sa-container sa-content sa-top-space60" style={{ marginTop: "150px" }}>
                <Link className="Home-link" to="/">
                    <span class="sa-btn-back">
                        <i class="icon-arrow-left">

                        </i>
                        <span class="back-text">Back</span>
                    </span>
                </Link>
                <div class="sa-mob-header">
                    <h5 class="sa-mob-block">Caregiver</h5>
                    <div class="sa-prog-grid">
                        <p>
                            <span>Step 1</span>
                            of 3
                        </p>
                        <div class="sa-prog-bar">
                            <div class="sa-prog-bar-per" style={{ "width": "33.3333%" }}></div>
                        </div>
                    </div>
                </div>
                <div class="sa-card-no-border sa-caregiver-work" style={{ border: "none" }}>
                    <h5 class="sa-primary-text sa-fw500 sa-mb10">How we are working</h5>
                    <p>
                        Our caregivers have undergone professional training to handle the task responsibly. They are also certified by the Ministry of Health, Saudi Arabia. They are experienced to aid the patient with activities of daily living such as personal care and hygiene, bathing, dressing and other defined set of duties and responsibilities according to the needs & type of service.
                    </p>
                </div>
                <h5 class="sa-sel-car-v2-task sa-fw500">Select caregiver task</h5>
                <div class="sa-row">

                    <div class="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs12 sa-d-flex sa-card-margin ">
                        <Link className="caregiver-link" to="/caregiver-task1" >
                            <div class="sa-card-shadow sa-cargiver-task">
                                <h5 class="sa-mb5 sa-fw600">Caregiver - Senior Helpers</h5>
                                <p>
                                    Assisting with daily tasks, keeping track of dietary needs, reminding of medication, accompanying the elderly to the hospital, taking care of you at home for 12 hours a day.
                                </p>
                                <h6 class="sa-mt20" >Up to 4 weeks</h6>
                                <small>12 hrs/day in house care</small><br /><br /><br />
                                <figure style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                    <img className="sa-img-cover" src={care1} alt="/" style={{ width: "112px", height: "53px" }} />
                                </figure>
                            </div>
                        </Link>
                    </div>

                    <div class="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs12 sa-d-flex  sa-card-margin" >
                        <Link className="caregiver-link" to="/caregiver-task2">
                            <div class="sa-card-shadow sa-cargiver-task">

                                <h5 class="sa-mb5 sa-fw600"> Baby-Sitter</h5>
                                <p>
                                    Taking care of your children, feeding them, keeping them clean and orderly, accompanying them in their activities, and dealing with their outbursts of anger, changing diapers, and breastfeeding. Staying at home with children for 12 hours a day.
                                </p>
                                <h6 class="sa-mt20" >Up to 4 weeks</h6>
                                <small>12 hrs/day in house care</small><br /><br />
                                <figure style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                    <img className="sa-img-cover" src={care2} alt="/" style={{ width: "112px", height: "87px" }} />
                                </figure>

                            </div>
                        </Link>
                    </div>
                    <div class="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs12 sa-d-flex sa-card-margin ">
                        <Link className="caregiver-link" to="/caregiver-task3">
                            <div class="sa-card-shadow sa-cargiver-task">


                                <h5 class="sa-mb5 sa-fw600">Caregiver - After Giving Birth (Postpartum)</h5>
                                <p>
                                    Taking care of you and your newborn during the postpartum period, monitoring your medication and diet, helping you during breastfeeding, taking care of your personal hygiene and carrying your baby. Staying at home and working for 12 hours.
                                </p>
                                <h6 class="sa-mt20" >Up to 4 weeks</h6>
                                <small>12 hrs/day in house care</small><br /><br />
                                <figure style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                    <img className="sa-img-cover" src={care3} alt="/" style={{ width: "112px", height: "67px" }} />
                                </figure>

                            </div>
                        </Link>
                    </div>
                    <div class="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs12 sa-d-flex  sa-card-margin" >
                        <Link className="caregiver-link" to="/caregiver-task4">
                            <div class="sa-card-shadow sa-cargiver-task">


                                <h5 class="sa-mb5 sa-fw600">Post-Opertive caregiver </h5>
                                <p>
                                    A post-operative caregiver provides care and support to a surgical patient, including assistance with daily activities, administering medication, monitoring recovery and in some cases coordinating overall healthcare.
                                </p>
                                <h6 class="sa-mt20" >Up to 4 weeks</h6>
                                <small>12 hrs/day in house care</small><br /><br /><br />
                                <figure style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                    <img className="sa-img-cover" src={care4} alt="/" style={{ width: "112px", height: "83px" }} />
                                </figure>

                            </div>
                        </Link>

                    </div>

                </div>
            </div><br />
            <Footer />
        </div>
    )
}

export default Caregiver
