import React, { useContext, useEffect, useState } from 'react'
import '../../../../Style/Laboratory.css'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { sortpackage } from './PackageSortList'
import { allpackage } from './ViewAllPackageList'
import { Modal } from 'react-bootstrap'
import { Context } from '../ContextProvider'

function ViewAllPackages() {

    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const slider = document.querySelector(".z-lab-carousel");
        let isDown = false;
        let startX;
        let scrollLeft;

        slider?.addEventListener("mousedown", e => {
            isDown = true;
            slider.classList.add("active");

            const pageX = e.pageX;
            startX = pageX - slider.offsetLeft;

            scrollLeft = slider.scrollLeft;
        });
        slider?.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider?.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider?.addEventListener("mousemove", e => {
            if (!isDown) return;

            e.preventDefault(); //stop any weird stuff
            const x = e.pageX - slider.offsetLeft;

            const deviation = x - startX;
            slider.scrollLeft = scrollLeft - deviation;
        });

        const carouselSlider = document.querySelector("[data-slider]");

        const track = carouselSlider.querySelector("[data-slider-track]");
        const prev = carouselSlider.querySelector("[data-slider-prev]");
        const next = carouselSlider.querySelector("[data-slider-next]");

        if (track) {
            prev?.addEventListener("click", () => {
                next.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft - track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            next?.addEventListener("click", () => {
                prev.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft + track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            track?.addEventListener("scroll", () => {
                const trackScrollWidth = track.scrollWidth;
                const trackOuterWidth = track.clientWidth;

                prev.removeAttribute("disabled");
                next.removeAttribute("disabled");

                if (track.scrollLeft <= 0) {
                    prev.setAttribute("disabled", "");
                }

                if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
                    next.setAttribute("disabled", "");
                }
            });
        };
    });

    const [num, setNum] = useState(0);

    const incNum = () => {
        if (num <= 50) {
            setNum(Number(num) + 1);
        }
    };

    const decNum = () => {
        if (num > 0) {
            setNum(num - 1);
        }
    };

    const setItemSelectedCount = (e, isInc) => {
        const updateObject = selectedPackage.map((packages) => {
            if (packages.id === e.id) {
                if (isInc) {
                    if (e.count <= 50) {
                        packages.count += 1;
                    }
                }
                else {
                    if (e.count > 0) {
                        if (packages.id === e.id) {
                            packages.count -= 1;
                        }
                    }
                }
            }
            return packages;
        });
        setSelectedPackage(updateObject);
    }

    const incNumSide = (e) => {
        setItemSelectedCount(e, true);
    }

    const decNumSide = (e) => {
        setItemSelectedCount(e, false);
    }

    const handleNum = (e, id) => {
        setNum(e.target.value);
        setPageName(id);
    };

    const [show, setShow] = useState(false);
    const { selectedPackage, setSelectedPackage } = useContext(Context);
    const [pageName, setPageName] = useState('');

    const handleShow = (e, id) => {
        // e.preventDefault();
        setShow(true);
        setPageName(id);
        setNum(0);
        selectedPackage.forEach(element => {
            if (element.id === id) {
                setNum(element.count);
            }
        });
    }

    const handlePackageSelection = (e) => {
        e.count = num;
        if (selectedPackage.find((elem) => elem.id === e.id) === undefined) {
            selectedPackage.push(e);
            setSelectedPackage(selectedPackage);
        }
        else {
            const updatePackage = selectedPackage.map((packages) => {
                if (packages.id === e.id) {
                    packages.count = num;
                }
                return packages;
            });
            setSelectedPackage(updatePackage);
        }
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleClick = () => {
        navigate('/browse/4/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04');
    }

    return (
        <>
            <Navbar />

            <div className='z-container'>
                <NavLink to='/laboratory/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04'>
                    <span className='lab-pack-btn-back'>
                        <i className='icon-arrow-left'></i>
                    </span>
                </NavLink>

                <div className='z-package-progress'>
                    <p>
                        <span> Step 1 </span> <span className='package-prog-step' style={{ color: '#000', marginLeft: '50px' }}> of 4</span>
                    </p>
                    <div className="progress z-package-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
                        <div className="progress-bar lab-package-bar-per" style={{ width: '25%' }}></div>
                    </div>
                </div>

                <div className='row z-package-gutter20'>
                    <div className='col-8 col-md-8 col-sm-8 col-xs-12'>
                        <div className='z-test-type'>
                            <NavLink to='/laboratory/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04'>
                                <span className='select-btn-back'>
                                    <i className='icon-left-arrow'></i>
                                </span>
                            </NavLink>
                            <h4 className='z-line-clamp'> Select Test Type </h4>
                            <i className='icon-search z-test-form-icon'></i>
                            <input onChange={(e) => setSearch(e.target.value)} name="search" type="text" placeholder="Search" className="form-control" required="" autoComplete="off"></input>
                        </div>

                        <div className='z-lab-scrl'>
                            <div className='z-lab-viw-slider' data-slider>
                                <ul className='z-lab-carousel' data-slider-track>
                                    <li className='z-lab-filter' id='all'>
                                        <div className='z-lab-item active'> All </div>
                                    </li>
                                    {sortpackage.map((item, index) => (
                                        <li key={index} id={item.id}>
                                            <div className='z-lab-item'> {item.name} </div>
                                        </li>
                                    ))}
                                    <li>
                                        <div className='z-lab-item' id='other'>
                                            <img src='https://sanar-assets.com/images/lab-icons/package/other.png' alt='img' />
                                            Other
                                        </div>
                                    </li>
                                </ul>
                                <button type='button' name='prev button' className='z-carousel-prev icon-left-arrow' data-slider-prev></button>
                                <button type='button' name='next button' className='z-carousel-next icon-right-arrow' data-slider-next></button>
                            </div>
                        </div>

                        <div>
                            <div className='z-package-title'>
                                <h3>
                                    Packages
                                </h3>
                            </div>

                            <div className='z-lab-package-scroll'>
                                <ul className='z-lab-tech-bg z-package-list'>
                                    {allpackage.filter((item) => {
                                        return search.toLowerCase() === '' ? item : item.packageTitle.toLowerCase().includes(search)
                                    }).map((item, index) => (
                                        <li key={index} onClick={(e) => handleShow(e, item.id)}>
                                            <h4> {item.packageTitle} </h4>
                                            <div className='z-lab-bg'>
                                                {
                                                    item.packagePrice === ""
                                                        ?
                                                        null
                                                        :
                                                        <button>
                                                            <span> Starting </span> {item.packagePrice} SAR
                                                        </button>
                                                }
                                                <figure className='z-img'>
                                                    <img src={item.packageImage} alt='PackageImage' />
                                                </figure>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-4 col-md-4 col-sm-4 col-xs-12 z-lab-pack-slct'>
                        <h4> Selected Items </h4>
                        <div className='z-selected-data'>
                            <div className='z-selected-item'>
                                {selectedPackage.map((item, index) => (
                                    <div className='z-selected-cards' key={index}>
                                        <div className='z-d-f-sbetween'>
                                            <span className='z-lab-card'>
                                                <i className='icon-package2'></i>
                                                <strong id={`selected_${item.id}`}> {item.packageTitle} </strong>
                                                <small className='z-tst-incl' id={`selected_${item.id}`}> {item.totalTest} test incl </small>
                                            </span>
                                            <div className='z-count'>
                                                <button className='z-symbol' onClick={() => decNumSide(item)}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <strong className='z-slct-count' id={item.id} onChange={(e) => handleNum(e, item.id)}> {item.count} </strong>
                                                <button className='z-symbol' onClick={() => incNumSide(item)}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {selectedPackage.length === 0 ?
                                <div className='z-item-btns' style={{ display: 'flex' }} >
                                    <button style={{ backgroundColor: "#c2c9e1", cursor: "not-allowed" }} disabled> Continue </button>
                                </div>
                                :
                                <div className='z-item-btns' onClick={() => handleClick()} style={{ display: 'flex', color: "#FFF" }}>
                                    <button style={{ backgroundColor: "#26A9E0" }}> Continue </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >

            <Footer />



            {allpackage.map((item, index) => (
                pageName.includes(item.id) && (
                    <Modal show={show} onHide={handleClose} key={index} style={{ '--bs-modal-width': '600px' }}>
                        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <div>
                                <Modal.Header>
                                    <h4 className='title' style={{ marginBottom: "-15px" }}>
                                        {item.packageTitle}
                                    </h4>
                                    <button className="z-lab-btn" onClick={handleClose} style={{ marginLeft: '570px' }}>
                                        <i className="icon-close"></i>
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='z-d-f-sbetween'>
                                        <div className='z-count z-lab-count'>
                                            <button className='z-symbol' onClick={() => decNum(item.id)}>
                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                            <strong className='z-slct-count' onChange={(e) => handleNum(e, item.id)}> {num} </strong>
                                            <button className='z-symbol' onClick={() => incNum(item.id)}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div className='z-select'>
                                            <Link onClick={handleClose}>
                                                <button onClick={() => handlePackageSelection(item)}>
                                                    Select
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <p className='package-popup-desc'>
                                        {item.packageDesc}
                                    </p>

                                    <div className='package-info'>
                                        <h6> Instructions </h6>
                                        <ul className='instruction-list'>
                                            <li>
                                                <span className='package-instraction'>
                                                    {item.instruction1}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <h6 className='package-test-title'>
                                        <strong>
                                            <span> {item.totalTest} </span>
                                            Test Included in this Package
                                        </strong>
                                    </h6>

                                    <ul className='package-test-list'>
                                        {item.test1 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test1}
                                            </li>
                                        }
                                        {item.test2 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test2}
                                            </li>
                                        }
                                        {item.test3 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test3}
                                            </li>
                                        }
                                        {item.test4 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test4}
                                            </li>
                                        }
                                        {item.test5 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test5}
                                            </li>
                                        }
                                    </ul>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>
                )
            ))}
        </>
    )
}

export default ViewAllPackages