import React, { useContext, useState } from 'react'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import '../../../Style/NurseVisit.css'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { nursevisitdata } from './NurseVisitData';
import { Context } from '../../../Context'

function NurseVisit() {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedStatusCards, setExpandedStatusCards] = useState([]);
  const { selectedItems, setSelectedItems } = useContext(Context);


  const openModel = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const toggleStatusCard = (itemId) => {
    setExpandedStatusCards((prevExpanded) =>
      prevExpanded.includes(itemId)
        ? prevExpanded.filter((id) => id !== itemId)
        : [...prevExpanded, itemId]
    );
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };



  return (
    <>
      <Navbar />

      <div className='container nv-content nv-lab-det nv-top-space60 nv-btm-space'>

        <Link to='/' className="z-btn-back">
          <i className="icon-arrow-left"></i>
          <span className="z-mob-none"> Back </span>
        </Link>

        <div className="nv-input-group nv-location-label" onClick={() => openModel()}>
          <button className="nv-btn nv-ellipsis">
            Selected Location
          </button>
          |
          <i className="icon-location2"></i>
          <input type="text" placeholder="location" className="nv-form-control nv-ellipsis nv-cursor" readOnly></input>
          <i className="icon-down-arrow nv-form-icon"></i>
        </div>

        <div className="z-doc-prog">
          <div className='z-doc-progress'>
            <p>
              <span> Step 1 </span> <span className='z-prog-step' style={{ color: 'black', marginLeft: '50px' }}> of 4</span>
            </p>
            <div className="progress nv-doc-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
              <div className="progress-bar z-prog-bar-per" style={{ width: '25%' }}></div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='nv-col8 nv-col-md8 nv-col-sm7 nv-col-xs12'>
            <div className='nv-nurse'>
              <div className='row'>
                <div className='nv-col6 nv-col-md6 nv-col-lg6'>
                  <h4 className="z-filter-title z-mob-none"> Nursing Tasks </h4>
                </div>
                <div className='nv-col6 nv-col-md6 nv-col-sm12 nv-col-lg6'>
                  <div className='nv-form-group nv-search-box nv-mob-none'>
                    <i className='icon-search nv-form-icon'></i>
                    <input onChange={(e) => setSearch(e.target.value)} id='search' name='search' type='text' placeholder='search' className='nv-form-control' required autoComplete='off'></input>
                  </div>
                </div>
              </div>
              <ul className='nv-card-list nv-nurse-checkbox'>
                {nursevisitdata
                  .filter((item) => {
                    return search.toLowerCase() === '' ? item : item.nursetitle.toLowerCase().includes(search)
                  })
                  .map((item) => (
                    <li key={item.id}>
                      <div className='nv-card-list-box'>
                        <i
                          className={`nv-bold ${expandedStatusCards.includes(item.id) ? 'icon-up-arrow' : 'icon-down-arrow'}`}
                          onClick={() => toggleStatusCard(item.id)}
                        ></i>
                        <div className='nv-doct-det'>
                          <div>
                            <input
                              className='nv-chkbox'
                              type='checkbox'
                              id={`checkbox-${item.id}`}
                              value={item.id}
                              checked={selectedItems.includes(item.id)}
                              onChange={() => {
                                handleCheckboxChange(item.id);
                              }}
                            />

                            <label className='nv-nurse-title' htmlFor='MTA23'>
                              {item.nursetitle}
                            </label>
                          </div>
                        </div>
                      </div>
                      {item.nursedescription.length > 10 && (
                        <span>
                          <span className={`nv-gray-lgt-clr ${expandedItems.includes(item.id) ? 'hidden' : ''}`}>
                            {item.nursedescription.substring(0, 100)}
                          </span>
                          <span className={`nv-gray-lgt-clr ${expandedItems.includes(item.id) ? '' : 'hidden'}`}>
                            {item.nursedescription}
                          </span>
                          <span
                            className={`nv-primary-text nv-ml5 ${expandedItems.includes(item.id) ? 'hidden' : ''}`}
                            onClick={() => setExpandedItems(prevState => [...prevState, item.id])}
                          >
                            More
                          </span>
                          <span
                            className={`nv-primary-text nv-ml5 ${expandedItems.includes(item.id) ? '' : 'hidden'}`}
                            onClick={() => setExpandedItems(prevState => prevState.filter(id => id !== item.id))}
                          >
                            Hide Details
                          </span>
                        </span>
                      )}

                      {item.nursepricetag === "" ? null :
                        <span className='nv-light-text'>  Starting{item.nursepricetag}SAR </span>
                      }
                      <div className='nv-status-card' style={{ display: expandedStatusCards.includes(item.id) ? 'block' : 'none' }}>
                        {item.nursestatuscard}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className='nv-col4 nv-col-md4 nv-col-sm5 nv-col-xs12'>
            <h4 className='z-filter-title nv-mob-none'>Selected tests</h4>
            <div className='nv-selected-data'>
              <div className='nv-item-selected'>
                {nursevisitdata.map((item, index) => (
                  selectedItems.includes(item.id) && (
                    <div className='nv-card-shadow' key={index}>
                      <input
                        className='nv-card-chkbox'
                        type='checkbox'
                        id={`checkbox-${item.id}`}
                        value={item.id}
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}

                      />
                      <label className='nv-card-nurse-title' htmlFor={`checkbox-${item.id}`}>
                        {item.nursetitle}
                      </label>
                      <div className='nv-nursevisitpopupdata' hidden>
                        <div className='nvc-gray-light-bg nvc-card-no-border'>
                          <h6 className='nvc-box-title'>Instructions</h6>
                          <ul className='nvc-unorder-list'>
                            <li>{item.instruction}</li>
                          </ul>
                        </div>
                        <div>
                          <p className='nvc-dark-text'>{item.nursetitle}</p>
                          <ul className='nvc-unorder-list-admin'>
                            <li>{item.instruction1}</li>
                            <li>{item.instruction2}</li>
                            <li>{item.instruction3}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                ))}

              </div>
              <div className='nv-item-btns'>
                {selectedItems.length > 0 ? (
                  <Link to="/NurseVisitContinue">
                    <button className='nv-btn-primary nv-btn-block'>
                      Continue
                    </button>
                  </Link>
                ) : (
                  <button
                    className='nv-btn-primary nv-btn-block'
                    disabled
                    style={{ color: '#fff', background: '#c2c9e1', borderColor: '#c2c9e1', cursor: "not-allowed" }}
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer />

      <Modal show={show} onHide={handleClose} animation={false}>
        <div className="sps-dialog sps-dialog-full">
          <div className="sps-dialog-body sps-dialog-overflow-unset sps-map">
            <button className="sps-dialog-close" onClick={handleClose}>
              <i className="icon-close"></i>
            </button>
            <div className="sps-loc-map">
              <div id="spsmapbox" className="sps-mapboxgl-map" >
                <div style={{ height: "500px", width: "100%" }}>
                  {/* <GoogleMapReact
                                            defaultCenter={{ lat: 20.9471, lng: 72.8990 }}
                                            defaultZoom={15}
                                        /> */}
                </div>
                <header className="sps-loc-header">
                  <button type="submit" className="sps-btn sps-loc-crnt-btn icon-current-location"></button>
                  <div className="sps-loc-field">
                    <i className="sps-sprite sps-search"></i>
                    <input placeholder="Search" className="sps-form-control search-location pac-target-input" type="text" id="search" name="search" autoComplete="off"></input>
                    <i className="icon-close2 sps-form-icon"></i>
                  </div>
                </header>
              </div>
            </div>
            <footer className="sps-loc-footer">
              <div className="sps-row">
                <div className="sps-col12 sps-d-f-sbetween">
                  <h5 className="sps-sprite sps-loc-map-ico sps-loc-type">
                    <b>Visit location</b>
                  </h5>
                </div>
                <div className="sps-col12 sps-mt10">
                  <div className="sps-lcn-card">
                    <div className="sps-d-f-sbetween">
                      <h5>
                        <i className="icon-location2 sps-mr5 sps-success-text"></i>
                        <b>Madinah</b>
                      </h5>
                      <small className="sps-save-lcn-text">Save Location</small>
                    </div>
                    <p>Nike Store,  Sultana street, Madinah, Medina, Saudi Arabia</p>
                  </div>
                </div>
                <div className="sps-col12 sps-ftr-res-btn">
                  <button type="submit" className="btn btn-outline-primary sps-btn-view">View saved address</button>

                  <button id='locbtn' data-bs-dismiss="modal" className="btn btn-primary sps-btn-confirm" type="submit">
                    Confirm location
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </Modal>

    </>
  )
}

export default NurseVisit
