import React, { useEffect } from 'react'
import '../../Style/CareIndividuals.css'

function BiDoctors() {

    useEffect(() => {
        const slider = document.querySelector("[data-slider]");

        const track = slider.querySelector("[data-slider-track]");
        const prev = slider.querySelector("[data-slider-prev]");
        const next = slider.querySelector("[data-slider-next]");

        if (track) {
            prev.addEventListener("click", () => {
                next.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft - track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            next.addEventListener("click", () => {
                prev.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft + track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            track.addEventListener("scroll", () => {
                const trackScrollWidth = track.scrollWidth;
                const trackOuterWidth = track.clientWidth;

                prev.removeAttribute("disabled");
                next.removeAttribute("disabled");

                if (track.scrollLeft <= 0) {
                    prev.setAttribute("disabled", "");
                }

                if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
                    next.setAttribute("disabled", "");
                }
            });
        }
    });


    return (
        <div className="bi-dr-lise-doc bi-dr-top120">
            <div className="container">
                <div className="bi-dr-section">
                    <h2 className="bi-dr-title"><b>Licensed doctors in various specialties</b></h2>
                    <div className="bi-dr-aviailable">
                        <span></span>
                        <p className="bi-dr-avi-descr">814 doctors are now available for immediate consultation</p>
                    </div>
                </div>
                <ul className="bi-dr-list-section bi-tick">
                    <li>
                        <i className="icon-checked"></i>
                        Perinatologist
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Neonatologist
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Anesthesiologist
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Occupational medicine specialist
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Orthopedic surgeon
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Urologist
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Interventional cardiologist
                    </li>
                    <li>
                        <i className="icon-checked"></i>
                        Psychiatrist
                    </li>
                    <li className="bi-dr-morelist">
                        <b>more specialties...</b>
                    </li>
                </ul>

                <div className="bi-dr-Slider" data-slider>
                    <ul className="bi-dr-Slider__track" data-slider-track>
                        <li>
                            <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                <img src="https://static.sanar.sa/mis/7c8f1ab7be17320590db5c262f506b1e.PNG" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                <h3 className="bi-dr-header-body">Dr. Hasna Alabsi</h3>
                                <p className="bi-dr-header-body">Registrar</p>
                                <p className="bi-dr-gray-text bi-dr-mb30">Dermatologist</p>
                                <span className="bi-dr-header-body">
                                    <i className="icon-star"></i>
                                    4.2/5
                                </span>
                            </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/profilepic/b0a7d073005560a78de5a004fed951c7.jpeg" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Abdullah Alhudaib</h3>
                                    <p className="bi-dr-header-body">Consultant</p>
                                    <p className="bi-dr-gray-text bi-dr-mb30">Surgeon</p>
                                    <span className="bi-dr-header-body">
                                        <i className="icon-star"></i>
                                        5/5
                                    </span>
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/mis/00e06bdb66b099a0bcf650bf291b2b4b.png" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Mohammed AlMagbili</h3>
                                    <p className="bi-dr-header-body">Subspecialty Consultant</p>
                                    <p className="bi-dr-gray-text bi-dr-mb30">Pediatrician</p>
                                    <span className="bi-dr-header-body">
                                        <i className="icon-star"></i>
                                        4.7/5
                                    </span>
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/mis/cd3de0e1c0257a907020a10918c1b681.png" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Abdulelah Alhawsawi</h3>
                                    {/* <p className="bi-dr-header-body">Registrar</p> */}
                                    <p className="bi-dr-gray-text bi-dr-mb30">Surgeon</p>
                                    {/* <span className="bi-dr-header-body">
                                    <i className="icon-star"></i>
                                    4.2/5 
                                </span> */}
                                </div>
                            
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/mis/6ece8c954899bf495c4a1cad8a009549.png" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Saeed Alghamdi</h3>
                                    <p className="bi-dr-header-body">Subspecialty Consultant</p>
                                    <p className="bi-dr-gray-text bi-dr-mb30">Vascular surgeon</p>
                                    <span className="bi-dr-header-body">
                                        <i className="icon-star"></i>
                                        4.7/5
                                    </span>
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://sanar-assets.com/images/unknown-avtar.png" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Abdulrahman Basalah</h3>
                                    {/* <p className="bi-dr-header-body">Registrar</p> */}
                                    {/* <p className="bi-dr-gray-text bi-dr-mb30">Dermatologist</p> */}
                                    {/* <span className="bi-dr-header-body">
                                    <i className="icon-star"></i>
                                    4.2/5 
                                </span> */}
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/mis/c6916beec1edfecdc2a1ba8a8f6f3fea.jpg" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Faisal  Sayegh </h3>
                                    <p className="bi-dr-header-body">Consultant</p>
                                    <p className="bi-dr-gray-text bi-dr-mb30">Otolaryngologist (ear, nose, and throat specialist)</p>
                                    <span className="bi-dr-header-body">
                                        <i className="icon-star"></i>
                                        5/5
                                    </span>
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/profilepic/8f06af89367caef3b851647db8115ea4.jpeg" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Ashjan Almasoudi</h3>
                                    <p className="bi-dr-header-body">Registrar</p>
                                    <p className="bi-dr-gray-text bi-dr-mb30">Dermatologist</p>
                                    <span className="bi-dr-header-body">
                                        <i className="icon-star"></i>
                                        5/5
                                    </span>
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor">
                                    <img src="https://static.sanar.sa/mis/0ed53224b1c4caa0939d4a547d777587.jpg" alt="img-rev" className="bi-dr-avtar bi-dr-avtar-lg bi-dr-avtar-circle"></img>
                                    <h3 className="bi-dr-header-body">Dr. Abdulelah Alhawsawi</h3>
                                    {/* <p className="bi-dr-header-body">Registrar</p> */}
                                    <p className="bi-dr-gray-text bi-dr-mb30">Surgeon</p>
                                    {/* <span className="bi-dr-header-body">
                                    <i className="icon-star"></i>
                                    4.2/5 
                                </span> */}
                                </div>
                        </li>
                        <li>
                        <div className="bi-dr-card-shadow bi-dr-card-lg bi-dr-doc-slider bi-dr-mb0 bi-dr-cursor bi-dr-hl-last-chaild">

                                    <h3 className="bi-dr-header-body">See all doctors</h3>
                                    <a href="/">
                                        <i className="icon-arrow-right"></i>
                                    </a>
                                </div>
                        </li>
                    </ul>
                    <div className="bi-dr-Slider__buttons">
                        <button className="bi-dr-prev icon-left-arrow" data-slider-prev disabled></button>
                        <button className="bi-dr-next icon-right-arrow" data-slider-next></button>
                    </div>
                </div>

                <div className="bi-dr-helth-btn">
                    <button className="bi-dr-btn-primary bi-dr-btn-lg"><b>See all doctors</b></button>
                </div>
            </div>
        </div>
    )
}

export default BiDoctors
