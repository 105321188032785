import React, { useState, useEffect } from 'react';
 
 
function HomeBannerSlider() {
    const [bannerData, setBannerData] = useState([]);
 
    useEffect(() => {
        fetch('https://zwarra.biztechnosys.com/api/header-banners')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setBannerData(data);
            });
    }, []);
    return (
 
 
 
        <div className="container  zw_home_slider">
            <div id="myCarousel" className="carousel slide slider_pt" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {bannerData.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
 
                            <img src="images/banner.jpg" width="100%" height="100%" alt="Zawaara" className="banner" />
 
                            <div className="container">
                                <div className="banner-left">
                                    <h1>{item.Title}</h1>
                                    <p>{item.Description}</p>
                                </div>
                                <div className="banner-right"><img src="images/banner-right.png" width="100%" height="100%" alt="Zawaara" />
                                </div>
                            </div>
 
                        </div>
                    ))}
                </div>
 
                <div className="carousel-indicators">
                    {bannerData.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to={index}
                            className={index === 0 ? 'active' : ''}
                            aria-current={index === 0 ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
    )
}
 
export default HomeBannerSlider