export const nursevisitcontinuedata = [
    {
        "id": 1,
        "doctorImage": "https://sanar-assets.com/mis/d5dab52a2fce74b7e9e06e37ec2cb355.jpeg",
        "doctorName": "Taibah Care",
        "doctorDesig": "Nurse visit - Home Visit",
        "currency": "SAR",
        "price": "400",
        "includefees": "Included visit fee"
    }
]