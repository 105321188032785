import React, { useState } from "react";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import '../../../Style/Home.css'
import 'react-datepicker/dist/react-datepicker.css'
import DoctorList from "./DoctorList";
import { doctorData } from './DoctorData.js'

function VirtualConsultation() {
  const [startDate, setstartDate] = useState(new Date());
  const [search, setSearch] = useState('');
  const [, setProducts] = useState(doctorData);
  const [sortBy, setSortBy] = useState(null);

  const handleSortChange = (e) => {
    const value = e.target.value;
    setSortBy(value);

    if (value === 'high') {
      setProducts(doctorData.sort((a, b) => b.price - a.price));
    }
    else if (value === 'low') {
      setProducts(doctorData.sort((a, b) => a.price - b.price));
    }
  }

  return (
    <>
      <Navbar />

      <div className="z-browser">
        <div className="z-browser-header">
          <div className="container">

            <Link to='/' className="z-btn-back">
              <i className="icon-arrow-left"></i>
              <span className="z-mob-none"> Back </span>
            </Link>

            <div className="z-srch-field z-mob-none">
              <i className="icon-search z-form-icon z-form-icon-left"></i>
              <input onChange={(e) => setSearch(e.target.value)} autoComplete="off" name="Search Doctor" type="text" placeholder="Search doctor or hospitaly" className="form-control z-form-control-sm" />
            </div>

            <div className="horizontal-calendar">
              <div className="z-date-list" style={{ scrollBehavior: 'auto' }}>
                <div className="z-date-list-scroll">
                  <div className="z-date-item z-choosed-day">
                    <div>
                      <p className="z-today"> Today </p>
                      <p className="z-date-item-date"> 8 </p>
                    </div>
                  </div>
                  <div className="z-date-item">
                    <div>
                      <p className="z-date-item-day"> Thu </p>
                      <p className="z-date-item-date"> 9 </p>
                    </div>
                  </div>
                  <div className="z-date-item">
                    <div>
                      <p className="z-date-item-day"> Fri </p>
                      <p className="z-date-item-date"> 10 </p>
                    </div>
                  </div>
                  <div className="z-date-item">
                    <div>
                      <p className="z-date-item-day"> Sat </p>
                      <p className="z-date-item-date"> 11 </p>
                    </div>
                  </div>
                  <div className="z-date-item">
                    <div>
                      <p className="z-date-item-day"> Sun </p>
                      <p className="z-date-item-date"> 12 </p>
                    </div>
                  </div>
                  <div className="z-date-item">
                    <div>
                      <p className="z-date-item-day"> Mon </p>
                      <p className="z-date-item-date"> 13 </p>
                    </div>
                  </div>
                  <div className="z-date-item">
                    <div>
                      <p className="z-date-item-day"> Tue </p>
                      <p className="z-date-item-date"> 14 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="z-date-picker">
              <i className="fa fa-calendar z-cal" aria-hidden="true"></i>
              <DatePicker className="custom-input" selected={startDate} onChange={(date) => setstartDate(date)} />
            </div>

          </div>

          <div className="container">
            <div className="z-doc-prog">
              <div className='z-doc-progress'>
                <p>
                  <span> Step 2 </span> <span className='z-prog-step' style={{ color: 'black', marginLeft: '50px' }}> of 4</span>
                </p>
                <div className="progress z-doc-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
                  <div className="progress-bar z-prog-bar-per" style={{ width: '50%' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container z-result">
          <div className="row">
            <div className="col-4 col-md-4 col-sm-4 col-xs-12">
              <div className="z-filter-data z-mob-none">
                <div className="z-filter">
                  <h4 className="z-filter-title"> Filters </h4>

                  <div className="z-filter-list z-mob-none">
                    <h5 className="z-filter-title z-primary-title"> Price </h5>

                    <div className="form-check">
                      <input className="form-check-input" value='low' type="radio" checked={sortBy === 'low'} onChange={handleSortChange} name="exampleRadios" id="exampleRadios1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        Low to High
                      </label>
                    </div>

                    <div className="form-check">
                      <input className="form-check-input" value='high' type="radio" checked={sortBy === 'high'} onChange={handleSortChange} name="exampleRadios" id="exampleRadios1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        High to Low
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-8 col-md-8 col-sm-8 col-xs-12">
              <h4 className="z-filter-title z-mob-none"> E-Clinics </h4>

              <ul className="z-doctor-card-list">
                {doctorData.filter((item) => {
                  return search.toLowerCase() === '' ? item : item.doctorName.toLowerCase().includes(search)
                }).map((item) => (
                  <li key={item.id}>
                    <DoctorList
                      doctorImage={item.doctorImage}
                      doctorName={item.doctorName}
                      doctorDesig={item.doctorDesig}
                      doctorExp={item.doctorExp}
                      teleMedicine={item.teleMedicine}
                      currency={item.currency}
                      price={item.price}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default VirtualConsultation;
