export const nursevisitpopupdata = [
    {
        "taskId": 1,
        "instruction": "Tell the Nurse about the medications you are taking and if you have any drug allergy, pregnancy, or infection",
        "instructiontitle": "Intramuscular Injection Or Subcutaneous administration",
        "instruction1": "Syringe and alcohol swabs will be brought.",
        "instruction2": "* solutions and drugs to be injected are not included.",
        "instruction3": "The patient must present a medical prescription that includes the medication name and dosages ."
    },
    {
        "taskId": 2,
        "instruction": "Tell the specialist about the medications you are taking and if you have any drug allergy, pregnancy, or infection. Show doctor’s prescription, if you have any.",
        "instructiontitle": "Injection/Home IV therapy",
        "instruction1": "Syringe and IV tube will be brought. Possibility to check allergy from the prescribed medication by taking a drug allergy test.",
        "instruction2": "* solutions and drugs to be injected are not included.",
        "instruction3": "*Instructions: The patient must present a medical prescription that includes the medication name and dosages ."
    },
    {
        "taskId": 3,
        "instruction": "Tell the specialist about the medications you are taking and if you have any drug allergy, pregnancy, or infection",
        "instructiontitle": "Nebulisation",
        "instruction1": "Nebulizer will be brought along with a single-use face mask, tube ، solution and Medications.",
        "instruction2": "",
        "instruction3": "*Instructions: The patient must present a medical prescription that includes the medication name and dosages ."
    },
    {
        "taskId": 4,
        "instruction": "Tell the specialist about the medications you are taking and if you have any drug allergy, pregnancy, or infection. Moreover, inform the specialist if you have been diagnosed with any disease in the reproductive and urinary tract systems",
        "instructiontitle": "Urinary Catheter Insertion & Removal",
        "instruction1": "",
        "instruction2": "",
        "instruction3": "Includes: Catheter will be brought with its drainage tube and drainage collection bag, alcohol swabs, cotton, medical disinfectant, sterile gloves and lubricant gel ."
    },
    {
        "taskId": 5,
        "instruction": "Tell the Nurse if you suffer from any infectious diseases that can be transmitted through blood and whether you have been diagnosed with diabetes or not.",
        "instructiontitle": "Surgical Dressing",
        "instruction1": "Medical adhesive tape and alcohol swabs, Vaseline gauze, sterile gloves and saline solution will be brought to disinfect and change the dressing.",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 6,
        "instruction": "Inform the specialist about the last time the tube was replaced, causes for intubation and if you suffer from any infectious diseases",
        "instructiontitle": "Nasogastric tube insertion",
        "instruction1": "A Nasogastric (Feeding) tube with an appropriate diameter will be brought along with medical disinfectant, cotton and tape.",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 7,
        "instruction": "",
        "instructiontitle": "",
        "instruction1": "",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 8,
        "instruction": "Disclosure of chronic diseases in the elderly",
        "instructiontitle": "Check vital signs",
        "instruction1": "Includes: Single Use Gloves , Sphygmomanometer, glucometer, thermometer, pulse oximeter",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 9,
        "instruction": "Tell the specialist if you suffer from any infectious diseases that can be transmitted through blood and whether you have been diagnosed with diabetes or not.",
        "instructiontitle": "Removal of Stitches",
        "instruction1": "Medical adhesive tape and alcohol swabs, vaseline gauze, and salt solution will be brought for disinfection",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 10,
        "instruction": "",
        "instructiontitle": "Ear or nose Piercing",
        "instruction1": "Includes: Earnings",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 11,
        "instruction": "Tell the Nurse if you suffer from any infectious diseases that can be transmitted through blood and whether you have been diagnosed with diabetes or not.",
        "instructiontitle": "Wound Care",
        "instruction1": "Sterile single-use medical gloves, alcohol wipes, gauze and medical adhesive will be brought.",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 12,
        "instruction": "",
        "instructiontitle": "",
        "instruction1": "",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 13,
        "instruction": "",
        "instructiontitle": "Burn Dressing",
        "instruction1": "Medical adhesive tape and alcohol swabs, Vaseline gauze, sterile gloves and saline solution will be brought to disinfect and change the dressing, burn medications .",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "taskId": 14,
        "instruction": "",
        "instructiontitle": "",
        "instruction1": "",
        "instruction2": "",
        "instruction3": ""
    },
] 