import React, { useState } from 'react'
import '../../Style/Navbar.css';
import { NavLink } from 'react-router-dom';
import Signin_popup from '../Login_Signin_popups/LoginPopup';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import AccountSetup_details from '../Login_Signin_popups/AccountSetup_details';
import AccountSetup_gender from '../Login_Signin_popups/AccountSetup_gender';
import AccountSetup_captcha from '../Login_Signin_popups/AccountSetup_captcha';
import Signup from '../Login_Signin_popups/Signup';
import OTP from '../Login_Signin_popups/OTP';
import Accountsetup_success from '../Login_Signin_popups/Accountsetup_success';
import Accountsetup_Bdate from '../Login_Signin_popups/Accountsetup_Bdate';
import LoginPopup from '../Login_Signin_popups/LoginPopup';

function Navbar() {

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
    }

    const { t } = useTranslation();
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white ">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src='images/logo.svg' alt="" width="68px" height="" className="d-inline-block align-text-top" />
                    </a>
                    <ul className='navbar-nav'>
                        <li className='z-mob-notific z-mob-notification'>
                            <i className='fa fa-bell-o'></i>
                        </li>
                    </ul>
                    <button className="navbar-toggler z-mob-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className='navbar-nav ms-auto mb-2 mb-lg-0 web-navbar-nav z-nav'>

                            <li className="nav-item poppins-medium zw_btn_18">
                                <NavLink className="nav-link" aria-current="page" to="/"> {t("NavHome")} </NavLink>
                            </li>
                            <li className="nav-item poppins-medium zw_btn_18 ">
                                <NavLink className="nav-link" to="/aboutus"> {t("NavAbout")} </NavLink>
                            </li>
                            <li className="nav-item poppins-medium zw_btn_18">
                                <NavLink className="nav-link" to="/joinAsProvider"> {t("NavJoin")} </NavLink>
                            </li>
                            <li className="nav-item dropdown poppins-medium zw_btn_18">
                                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t("NavGet")}
                                </a>
                                <ul className="dropdown-menu">
                                    <li className='poppins-medium zw_btn_18'>
                                        <NavLink className="dropdown-item" to="/forindividuals">
                                            {t("NavIndividual")}
                                        </NavLink>
                                    </li>
                                    <li className='poppins-medium zw_btn_18'>
                                        <NavLink className="dropdown-item" to="/forbussiness">
                                            {t("NavBusiness")}
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul className='z-lang-nav z-lang-btn'>
                            <li className='z-select-lang dropdown poppins-medium zw_btn_18'>
                                <i className='fa fa-language z-lang-icon'></i>
                                <select className='form-select z-lang' defaultValue={selectedLanguage} onChange={chooseLanguage}>
                                    <option value="en">
                                        English
                                    </option>
                                    <option value='ar'>
                                        Arabic
                                    </option>
                                </select>
                            </li>
                            <li className='z-btn-list poppins-medium zw_btn_18'>
                                <button className='z-btn-primary' data-bs-toggle="modal" data-bs-target="#Loginmodal">
                                    <span className='poppins-medium '>
                                        {t("NavSignIn")}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* <LoginPopup /> */}
            <LoginPopup />
            <Signup />
            <AccountSetup_details />
            <AccountSetup_gender />
            <AccountSetup_captcha />
            <Accountsetup_Bdate />
            <OTP />
            <Accountsetup_success />
        </>
    )
}

export default Navbar
