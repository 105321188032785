import React from 'react'
import '../.././Style/Herosection.css'
function WhyZwaara() {
    return (
        <div>
            <div className="sa-why-sanar sa-top120">
                <h2 className="h2">Why Zwaara?</h2>
                <p>
                    Zwaara will guarantees for you the best medical care from specialized consultations and home medical services such as lab tests, nursing, and etc, with an elit of consultants and specialists.
                </p>
                <ul className="sa-why-sanar-list sa-suc-tick">
                    <li >
                        <i className="icon-checked" />
                        Integrated services in variety of medical fields
                    </li>
                    <li>
                        <i className="icon-checked" />
                        Freedom to choose the place and time 24/7
                    </li>
                    <li>
                        <i className="icon-checked" />
                        Wide geographical coverage 12+ cities
                    </li>
                    <li>
                        <i className="icon-checked" />
                        An elite of consultants and specialist
                    </li>
                    <li>
                        <i className="icon-checked" />
                        24+ E-clinics.
                    </li>
                    <li>
                        <i className="icon-checked" />
                        unified EMR
                    </li>
                    <li>
                        <i className="icon-checked" />
                        Multiple & secure payment methods
                    </li>

                </ul>
            </div>
            {/* <div  className="sa-container">
        <div  className="sa-join-us-bg sa-top120">
            <h2  className="h2">Join us and get benefit from Zwaara services</h2>

            <div  className="sa-join-btns"  >
                <button type="submit"  className="sa-btn sa-btn-lg sa-btn-plain sa-mr25" >
                    <i  className="icon-welfare sa-pr10 " > </i>Provide a service</button>
                <button type="submit"  className="sa-btn sa-btn-lg sa-btn-plain">
                    <i  className="icon-users sa-pr10">    </i>
                    Get care
                </button>

            </div>
        </div>
    </div> */}

            <div className="sa-container">
                <div className="sa-join-us-bg sa-top120">
                    <h2 className="h2">Join us and get benefit from Zwaara services</h2>
                    <div className="join-btns">
                        <button type="submit" className="btn btn-light mr-25">
                            <i className="icon-welfare pr10"></i>
                            Provide a service
                        </button>
                        <button type="submit" className="btn btn-light">
                            <i className="icon-users pr10"></i>
                            Get care
                        </button>

                    </div>
                </div>
            </div>
            <a href="https://api.whatsapp.com/send?phone=966554420580&text=" className="sa-whtsp-sprt sa-mob-none">
                <i className="icon-whatsapp"></i> Support</a>
        </div>
    )
}

export default WhyZwaara
