import React from 'react';
import '../../Style/Advantages_of_joining.css'
import { Link } from 'react-router-dom';

function Advantages_of_joining() {
  return (
    <div className="top120">
      <h4 className="success-text h4 mb20">Advantages of joining</h4>
      <div className="row join-adv">
        <div className="col-6 col-md-6 col-sm-6 sa-col-xs-12">
          <h2 className="h2">For doctors and specialists</h2>
          <p className="mb30">We believe in the technology as enabler tool in health and medicine sector. So we aim to create a supportive environment where you can develop your career and extend your services</p>
          <ul className="join-doc-list">
            <li>
              <div className="common-title gap30">
                <i className="icon-hospital3 icon-bg"></i>
                <h5 className="h5">Virtual clinic</h5>
              </div>
              <p className="pl85">Ease of managing and directing appointments - setting appropriate times for your appointments and advising your clients virtually.</p>
            </li>
            <li>
              <div className="common-title gap30">
                <i className="icon-digital-nomad icon-bg"></i>
                <h5 className="h5">Digital presence</h5>
              </div>
              <p className="pl85">Enhance your presence on the Internet, search engines and through the application, Also reach to a large segment of patients.</p>
            </li>
            <li>
              <div className="common-title gap30">
                <i className="icon-purse icon-bg"></i>
                <h5 className="h5">Additional income</h5>
              </div>
              <p className="pl85">Flexible work schedule - set the price of the consultation according on your experiences and preferences</p>
            </li>
            <div className="text-right">
              <Link className='link' to="/Joinbutton">
                <button className="btn btn-primary btn-lg" >Join in</button>
              </Link>
            </div>
          </ul>
        </div>
        <div className="col-6 col-md-6 col-sm-6 col-xs-12 mob-none">
          <figure>
            <img src="https://sanar-assets.com/images/static/new-theme/care-provider-doctors-section.png" alt="" className="img-cover"></img>
          </figure>
        </div>
      </div>
    </div>
  )
}

export default Advantages_of_joining