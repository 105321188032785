import React from 'react'

function WhyBussiness() {
    return (
        <div className="container">
                <h2 className="wb-title"><center><b>Why Sanar Business</b></center></h2>
                <p className="wb-subtitle">We support you in apply occupational safety and health to enhance loyality of all of your employees</p>
                
            <div className="row">
                <div className="row cols-md-4 mt-2">
                    <div className="bs-card">
                        <div className="wb-card b-card">
                            <div className="wb-treatment-card">
                                <i className="wb-sprite-new wb-journey"></i>
                            </div>
                            <div className="card-body">
                                <h5 className="wb-card-title"><b>Productivity guarantee</b></h5>
                                <p className="wb-card-text"><small>By empowering your employees and helping them manage their physical, mental health and well-being. It allows them to focus their efforts on what they work on it. Ensure wellness at work and at home.</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="bs-card">
                        <div className="wb-card b-card">
                            <div className="wb-treatment-card">
                                <i className="wb-sprite-new wb-emp-success"></i>
                            </div>
                            <div className="card-body">
                                <h5 className="wb-card-title"><b>Employee stability and success</b></h5>
                                <p className="wb-card-text"><small>Retaining talents and creating an attractive environment for them within the organizations.and achieving job satisfaction</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="bs-card">
                        <div className="wb-card b-card">
                            <div className="wb-treatment-card">
                                <i className="wb-sprite-new wb-enhance"></i>
                            </div>
                            <div className="card-body">
                                <h5 className="wb-card-title"><b>Enhance the organization's image</b></h5>
                                <p className="wb-card-text"><small>The desire of employees to remain in the organization and their loyalty to it or your desire to attract good competencies require a health-promoting environment</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="bs-card">
                        <div className="wb-card b-card">
                            <div className="wb-treatment-card">
                                <i className="wb-sprite-new wb-money-fill"></i>
                            </div>
                            <div className="card-body">
                                <h5 className="wb-card-title"><b>Reduce health care costs</b></h5>
                                <p className="wb-card-text"><small>Which consedred as one of the most important goals of the health promotion program for organizations.</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyBussiness
