import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function Review() {

    const showToastMsg = () => {
        toast.success("Your Favourite Doctor Added Successfully !", {
            position: toast.POSITION.TOP_CENTER,
        });
    }
    return (
        <>
            <Navbar />
            <div className="z-browser">
                <div className="z-browser-header">
                    <div className="container">
                        <Link to='/browser/telemedicine/deptid=1&pId=ST11&stid=ST1' className="z-btn-back">
                            <i className="icon-arrow-left"></i>
                            <span className="z-mob-none"> Back </span>
                        </Link>
                    </div>

                    <div className="container">
                        <div className="z-doc-prog">
                            <div className='z-doc-progress'>
                                <p>
                                    <span> Step 3 </span> <span className='z-prog-step' style={{ color: 'black', marginLeft: '50px' }}> of 4</span>
                                </p>
                                <div className="progress z-doc-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
                                    <div className="progress-bar z-prog-bar-per" style={{ width: '75%' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-5 col-md-5 col-sm-5 col-xs-12'>
                            <div className='z-mob-none'>
                                <div className='z-primary-light'>
                                    <span className='z-fav-doc icon-heart' onClick={showToastMsg}></span>
                                    <ToastContainer />

                                    <div className='z-doc-info'>
                                        <figure className='z-doc-avtar'>
                                            <img src='https://static.sanar.sa/mis/d98dab30db157dff2981d2def5d380b1.JPG' alt='Doctor' className='z-doc-img-cover' />
                                        </figure>
                                        <div className='z-doc-tit'>
                                            <h6> Dr. Nora Brazanji</h6>
                                            <small> General Practitioner, </small>
                                        </div>
                                    </div>

                                    <div className='z-doc-exp'>
                                        <small>
                                            <span style={{ color: '#86909D' }}> Experience : </span> 9 years
                                        </small>
                                    </div>
                                </div>

                                <ul className='z-tab-fill'>
                                    <li>
                                        <span> About Doctor </span>
                                    </li>
                                </ul>

                                <div className='z-tab-cont'>
                                    <div>
                                        <div className='z-about'>
                                            <h6> University </h6>
                                            <ul className='z-unorder-list'>
                                                <li>
                                                    KAAU
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='z-about'>
                                            <h6> Medical License No. </h6>
                                            <ul className='z-unorder-list'>
                                                <li>
                                                    14-J-M-0035074
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='z-about'>
                                            <h6> Extensive experience in treating </h6>
                                            <ul className='z-unorder-list'>
                                                <li>
                                                Preventive medicine and public health
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-7 col-md-7 col-sm-7 col-xs-12 z-ptn-details'>
                            <div className='z-review-header'>
                                <h4 className='z-tit'> Add Details </h4>
                            </div>

                            <div className='z-patient-card'>
                                <div className='z-slct-ptn'>
                                    <span> Select Patient </span>
                                    <button>
                                        <i className='icon-plus'></i>
                                        Add Patient
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Review
