import React, { useState, useEffect } from 'react';
import '../../../Style/Laboratory.css'
import { Link } from 'react-router-dom';
import '../../../Style/lab.css'
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Location1 from './Location1';
import '../../../Style/Ourfeaturetestspopup.css'




function Laboratory() {



  const [searchQuery, setSearchQuery] = useState('');


  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [selectedTest, setSelectedTest] = useState(null);
  const [count, setCount] = useState(1);

  const handleTestSelection = (testTitle) => {
    setSelectedTest(testTitle);


  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const [bodyfunctionData, setBodyFunctionData] = useState([]);
  useEffect(() => {
    fetch('https://zwarra.biztechnosys.com/api/laborotaryservices?TypeOfService=Body Function and Health Concern')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setBodyFunctionData(data);
      });
  }, []);

  const [mostHelpData, setMostHelpData] = useState([]);
  useEffect(() => {
    fetch('https://zwarra.biztechnosys.com/api/laborotaryservices?TypeOfService=Most Help Packages')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMostHelpData(data);
      });
  }, []);

  const [ourFeatureData, setOurFeaturedData] = useState([]);
  useEffect(() => {
    fetch('https://zwarra.biztechnosys.com/api/laborotaryservices?TypeOfService=Our Featured Tests')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOurFeaturedData(data);
      });
  }, []);



  return (
    <>
      <Navbar />
      <div className="slider_ptl zw_lab_bg">
        <div className="laboratory-WM2">
          <div className='container'>
            <div className="auto-group-9h4y-a9r">
              <div className='row pt-4'>
                <div className="group-1261154093-W7N col-12">
                  <div className="group-1261154076-E3N">
                    <Link to="/" className='zw_a'>
                      <span className=" zw_btn_18 poppins-medium zw_black"><i className="icon-arrow-left"></i>Back</span>
                    </Link>
                  </div>
                  <div className="frame-37129-R7r">
                    <div className="rectangle-39545-vqJ">
                    </div>
                    <div className="rectangle-39546-G8U">
                    </div>
                    <div className="rectangle-39547-164">
                    </div>
                    <div className="rectangle-39548-wkQ">
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-8'>
                  <p className="lab-tests-packages-1Ac zw_32">Lab Tests &amp; packages</p>
                  <div className="group-1261154095-WJQ ">
                    <div className="group-1261154098-EEQ">
                      <div className="frame-37119-bKr">
                        <div className="group-1261154097-E7v">
                          <ul className="group-1261154074-axU">
                            <li className="selected-location-jaU">
                              <i class="icon-location2 sps-mr5 zw_title_color"></i>
                              Selected Location
                            </li>
                            <li className="saudi-arabia-ab6">Saudi Arabia
                              <i class="icon-location2 sps-mr5 zw_title_color"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-4 zw_lab_rightsec'>
                  <img className="converted-1-ghz" src="images/converted-1.png" alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container zw_lab_health">
        <div className="laboratory-WM2">
          <div className='row'>
            <div className='col-md-12'>
              <div className="auto-group-cncl-P6c">
                <div className="auto-group-1uqj-hsz">
                  <div className="laboratory-sub-services-dme">
                    <p className="body-function-or-health-concern-awn zw_34">body function or health concern</p>
                    <ul className="group-1261154457-J76">
                      {bodyfunctionData.map((item, index) => (
                        <li className="auto-group-evfn-RxQ" key={index}>
                          <Link to='/labfullbody' className='zw_a'>
                            <div className="auto-group-d4vz-xxL">
                              <div className="group-1261154108-Wix">
                                <img className="full-body-RL8 img-fluid" src={item.Image} alt='' />
                                <p className="full-body-Lxt">{item.Title}</p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container zw_lab_health_package">
        <div className='row'>
          <div className='col-md-7'>
            <div className="laboratory-WM2">
              <div className="auto-group-cncl-P6c">
                <div className="auto-group-ayeg-thS">
                  <div className="auto-group-9twz-df2">
                    <h1 className='zw_34 poppins-semibold'>Most help packages</h1>
                    <div className="search-container">
                      <form className="search-form">
                        <input
                          type="text"
                          className="search-input zw_16 poppins-regular"
                          placeholder="Search your lab tests & Packages"
                          value={searchQuery}
                          onChange={handleSearchInputChange}
                        />
                        <button type="submit" className="search-button zw_bgwhite">
                          <i className="icon-search zw_black"></i>
                        </button>
                      </form>
                    </div>
                    <div className='mt-4'>
                      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {mostHelpData.filter(item =>
                          item.Title.toLowerCase().includes(searchQuery.toLowerCase())
                        ).map((item, index) => (
                          <div key={index} className="card" data-bs-toggle="modal" data-bs-target="#mostHealthpackage" style={{ width: "30%", marginBottom: "20px", padding: '0px' }}
                          >
                            <div className="card-body" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <img className="img-fluid" src={item.Image} alt='' />
                              <p className='poppins-bold zw_11 zw_title_color mb-0 text-uppercase pt-2'>{item.Title}</p>
                            </div>
                          </div>
                        ))}
                        {mostHelpData.map((item, index) => (
                          <div className='zw_popup' >
                            <div className="modal fade zw_Healthpackage" id="mostHealthpackage" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
                              <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className='btn-space'>
                                      <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="icon-close"></i>
                                      </button>
                                    </div>
                                    <div className="our-featured-tests-pop-up-hEc">
                                      <div className="group-1261154596-dV6" >
                                        <div className="group-1261154601-MAC">
                                          <div className="group-1261154877-nWx">
                                            <p className="health-check-packages-VnC poppins-semibold zw_24">{item.Title}</p>
                                            <div className='row mb-4'>
                                              <div className='col-8'>
                                                <div className="group-1171275053-Wxk">
                                                  <div className="group-1171275049-1ec zw_btn_18" onClick={decrementCount}>–</div>
                                                  <p className="item-1-fz4 zw_btn_18 ">{count}</p>
                                                  <div className="group-371-Pv4">
                                                    <div className="ellipse-57-9eL item--sqE zw_btn_18" onClick={incrementCount}>+</div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='col-4 zw_select_btn'>
                                                <div className="group-1261154603-x5z poppins-regular zw_16 zw_bg_gradient zw_white_text" data-bs-dismiss="modal" aria-label="close" onClick={(e) => {
                                                  handleTestSelection(item.Title);
                                                }}>
                                                  Select
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="group-1261154877-nWx">
                                            <p className="poppins-regular zw_16 zw_333333"> A group of tests that should be performed on regular basis. These Laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors</p>
                                            <div className="group-1261154592-pxt mb-4">
                                              <div className="group-1261154551-xJQ zw_light_bg">
                                                <p className="instructions-588 poppins-semibold zw_16 zw_002a56">Instructions</p>
                                                <ul className="group-1261154604-oZv">
                                                  <li className="poppins-regular zw_16">Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).</li>
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="group-1261154592-pxt">
                                              <div className="group-1261154591-xbE">
                                                <p className="test-included-in-this-package-7j2 poppins-semibold zw_24 zw_002a56">29 Test Included in this Package</p>
                                                <div className="group-1261154590-3Mn">
                                                  <ul className="group-1261154588-MdN">
                                                    <li className="poppins-regular zw_text_color zw_16">LDL CHOLESTEROL</li>
                                                    <li className="poppins-regular zw_text_color zw_16">TRIGLYCERIDES</li>
                                                    <li className="poppins-regular zw_text_color zw_16">GPT (ALT) GLUTPYRUVIC TANSA (Serum Glutamate Pyruvate Transaminase)</li>
                                                    <li className="poppins-regular zw_text_color zw_16">Cumulative Sugar Test (HBA1C)</li>
                                                    <li className="poppins-regular zw_text_color zw_16">CHOLESTEROL</li>
                                                    <li className="poppins-regular zw_text_color zw_16">Creatinine blood test</li>
                                                    <li className="poppins-regular zw_text_color zw_16">GOT (AST) GLUT-OXALO-ACETIC TRANSA (Glutamate Oxaloacetate Transaminase)</li>
                                                    <li className="poppins-regular zw_text_color zw_16">PLATELET COUNT</li>
                                                    <li className="poppins-regular zw_text_color zw_16">ALBUMIN</li>
                                                    <li className="poppins-regular zw_text_color zw_16">VITAMIN B12 (CYANOCOBOLAMINE)</li>
                                                    <li className="poppins-regular zw_text_color zw_16">ALP(ALKALINE PHOSPHATASE)</li>
                                                    <li className="poppins-regular zw_text_color zw_16">HDL CHOLESTEROL</li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <button className="button-see-all zw_14 poppins-regular zw_title_color">See All</button>
                    </div>

                    <div >
                      <h3 className='zw_34 poppins-semibold'>Our Featured Tests</h3>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {ourFeatureData.map((item, index) => (
                          <div className="card zw_card zw_card_feature" key={index} data-bs-toggle="modal" data-bs-target="#ourfeaturedpackage" style={{ width: "30%", marginBottom: "20px" }} >
                            <div className="card-body" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <img className="img-fluid" src={item.Image} alt='' />
                              <p className='poppins-bold zw_11 zw_title_color mb-0 text-uppercase pt-2'>{item.Title}</p>
                            </div>
                          </div>
                        ))}
                        {ourFeatureData.map((item, index) => (
                          <div className='zw_popup'>
                            <div className="modal fade zw_Healthpackage" id="ourfeaturedpackage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className='btn-space'>
                                      <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close" data-bs-backdrop="static">
                                        <i className="icon-close"></i>
                                      </button>
                                    </div>
                                    <div className="our-featured-tests-pop-up-hEc">
                                      <div className="group-1261154596-dV6" >
                                        <div className="group-1261154601-MAC">
                                          <div className="group-1261154877-nWx">
                                            <p className="health-check-packages-VnC poppins-semibold zw_24">{item.Title}</p>
                                            <div className='row mb-4'>
                                              <div className='col-8'>
                                                <div className="group-1171275053-Wxk">
                                                  <div className="group-1171275049-1ec zw_btn_18" onClick={decrementCount}>–</div>
                                                  <p className="item-1-fz4 zw_btn_18 ">{count}</p>
                                                  <div className="group-371-Pv4">
                                                    <div className="ellipse-57-9eL item--sqE zw_btn_18" onClick={incrementCount}>+</div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='col-4 zw_select_btn'>
                                                <div className="group-1261154603-x5z poppins-regular zw_16 zw_bg_gradient zw_white_text" onClick={() => handleTestSelection(item.Title)}>Select </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="group-1261154877-nWx">
                                            <div className="group-1261154592-pxt mb-4">
                                              <div className="group-1261154551-xJQ zw_light_bg">
                                                <div className="row">
                                                  <div className='col-4'>
                                                    <div className='zw_bgwhite p-3 zw_sbmtbtn_radius'>
                                                      <img className='img-fluid m-2' src='images/blood.svg' alt='' />
                                                      <span className="m-2 poppins-semibold zw_btn_18 zw_000 mb-0 zw_report_name">BLOOD</span>
                                                    </div>
                                                  </div>
                                                  <div className='col-8 zw_bgwhite p-3 zw_sbmtbtn_radius'>
                                                    <div className='m-2'>
                                                      <span className="poppins-semibold zw_btn_18 zw_000 mb-0 zw_report_name">The test does not require fastting</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <button className="button-see-all zw_14 poppins-regular zw_title_color">See All</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-5 zw_lab_rightsec'>
            <div className="laboratory-WM2">
              <div className="auto-group-cncl-P6c">
                <div className="auto-group-ayeg-thS">
                  <div className="group-1261154163-rtL">
                    <p className="selected-test-ooa">Selected test</p>
                    <div className="group-1261154162-jx8">
                      {selectedTest && (
                        <div className="main-selected-element-4zQ">
                          <div className="group-1261154161-1ek">
                            <div className='row'>
                              <div className="col-md-7">
                                <p className="pregnancy-test-serum-bhcg-uk8 zw_12" style={{ marginRight: "0", whiteSpace: "normal" }}>{selectedTest}</p>
                              </div>
                              <div className="col-md-5">
                                <div className="group-1171275053-cuS">
                                  <div className="group-1171275049-xTW" onClick={decrementCount}>–</div>
                                  <p className="item-1-R6C zw_btn_18" style={{ margin: "0" }}>{count}</p>
                                  <div className="group-371-jsa" onClick={incrementCount}>+</div>
                                </div>
                              </div>
                            </div>
                            <button type="button" className="btn sps-dialog-close" data-bs-dismiss="modal" aria-label="Close"></button>

                            <button className="sps-dialog-close" ><i className="icon-close"></i></button>
                          </div>
                        </div>
                      )}
                      <button
                        className="frame-37121-6be"
                        type='button'
                        data-bs-toggle="modal"
                        data-bs-target="#LogInPopUpModal44"
                        disabled={!selectedTest}
                        style={{ backgroundColor: !selectedTest ? '#ccc' : '', cursor: !selectedTest ? 'not-allowed' : 'pointer', opacity: "1" }}
                      >
                        Continue
                      </button>
                      <Location1 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Laboratory
