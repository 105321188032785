import React from 'react'
import '../../Style/Footer.css'
import { NavLink, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <footer className="text-center text-lg-start z-footer-bg z-mob-none">
                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <div className="mt-20">
                                    <figure>
                                        <img className="z-ftr-lg z-footer-logo"     src='/images/logo-white.png' alt="footer-logo" />
                                    </figure>
                                </div>
                                {/* <p>
                                    {t("FooterTitle")}
                                </p> */}
                                <div className='z-social-media mt-4'>
                        <a href="/" className="text-reset">
                            <i className="fa fa-facebook-f z-facebook"></i>
                        </a>
                        <a href="/" className="text-reset">
                            <i className="fa fa-twitter z-twitter"></i>
                        </a>
                        <a href="/" className="text-reset">
                            <i className="fa fa-vimeo z-whatsapp"></i>
                        </a>
                        <a href="/" className="text-reset">
                            <i className="fa fa-instagram z-instagram"></i>
                        </a>
                        
                    </div>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 z-footer-list a">
                                <h6 className="text-uppercase mb-4 z-title-line poppins-semibold">
                                {t("FooterMenu1")}
                                </h6>
                                <p className='poppins-regular'>
                                    <a href="/">{t("Menu1")}</a>
                                </p>
                                <p  className="footer-item poppins-regular">
                                    <Link className="footer-link" to="/contactus">{t("Menu2")}</Link>
                                </p>
                                <p className='poppins-regular'>
                                    <a href="/">{t("Menu3")}</a>
                                </p>
                                <p className='poppins-regular'>
                                    <a href="/">{t("Menu4")}</a>
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 z-footer-list">
                                <h6 className="text-uppercase  mb-4 z-title-line poppins-semibold">
                                {t("FooterMenu2")}
                                </h6>
                                <p className='poppins-regular'>
                                    <a href="/">{t("Menu5")}</a>
                                </p>
                                <p className='poppins-regular'>
                                    <a href="/" >{t("Menu6")}</a>
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 z-footer-list">
                                <h6 className="text-uppercase  mb-4 z-title-line poppins-semibold">
                                {t("FooterMenu2")}
                                </h6>
                                <p className='poppins-regular'>
                                    <a href="/">{t("Menu5")}</a>
                                </p>
                                <p className='poppins-regular'>
                                    <a href="/" >{t("Menu6")}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>


            {/* For Mobile View */}
            <div className='sa-mob-none'>
                <ul className='z-footer-mob footer-mob-nav'>
                    <li className="nav-item">
                        <NavLink className="router-link-active z-link" aria-current="page" to="/">
                            <span>
                                <i className='icon-services'></i>
                                Services
                            </span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="router-link-active" aria-current="page" to="/aboutus">
                            <span>
                                <i className='icon-appointment3'></i>
                                Appoinment
                            </span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="router-link-active" aria-current="page" to="/joinAsProvider">
                            <span>
                                <i className='icon-patient-list'></i>
                                Patient Record
                            </span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="router-link-active" aria-current="page" to="/more">
                            <span>
                                <i className='icon-more3'></i>
                                More
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Footer
