import React from 'react'
import Header from '../Components/JoinAsProvider/Header_part';
import Advantages_of_joining from '../Components/JoinAsProvider/Advantages_of_joining';
import Medical_Center from '../Components/JoinAsProvider/Medical_Center';
import Counts from '../Components/JoinAsProvider/Counts';
import Clinics_Services from '../Components/JoinAsProvider/Clinics_Services';
import Download_Zwaara from '../Components/JoinAsProvider/Download_Zwaara';
import Join_now from '../Components/JoinAsProvider/Join_now';
import Navbar from '../Components/Layout/Navbar';
import Footer from '../Components/Layout/Footer';

function JoinAsProvider() {
  return (
    <div>
        <Navbar />
        <Header />
        <Advantages_of_joining />
        <Medical_Center />
        <Counts />
        <Clinics_Services />
        <Download_Zwaara />
        <Join_now />
        <Footer />
      </div>
  )
}

export default JoinAsProvider
