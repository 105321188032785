export const data = [
    {
        "id": 1,
        "title": "General Medicine",
        "image": "https://sanar-assets.com/images/eclinics/general-medicine.jpg"

    },
    {
        "id": 2,
        "title": "Family Medicine",
        "image": "https://sanar-assets.com/images/eclinics/family-medicine.jpg"
    },
    {
        "id": 3,
        "title": "Psychiatry",
        "image": "https://sanar-assets.com/images/eclinics/psychiatry.png"
    },
    {
        "id": 4,
        "title": "Psyclogy",
        "image": "https://sanar-assets.com/images/eclinics/psyclogy-clinic.png"
    },
    {
        "id": 5,
        "title": "Pediatric",
        "image": "https://sanar-assets.com/images/eclinics/pediatric.png"
    },
    {
        "id": 6,
        "title": "Obsterics and Gyncology",
        "image": "https://sanar-assets.com/images/eclinics/obsterics-gyncology.png"
    },
    {
        "id": 7,
        "title": "Dermatology & Plastic Surgery",
        "image": "https://sanar-assets.com/images/eclinics/dermatology.png"
    },
    {
        "id": 8,
        "title": "Ear Nose Throat (ENT)",
        "image": "https://sanar-assets.com/images/eclinics/ear-nose.png"
    },
    {
        "id": 9,
        "title": "Urology",
        "image": "https://sanar-assets.com/images/eclinics/urology.png"
    },
    {
        "id": 10,
        "title": "Edocrinology",
        "image": "https://sanar-assets.com/images/eclinics/endocrinology.png"
    },
    {
        "id": 11,
        "title": "Gastroenterology and Endoscopy",
        "image": "https://sanar-assets.com/images/eclinics/gastroentology.png"
    },
    {
        "id": 12,
        "title": "Internal Medicine",
        "image": "https://sanar-assets.com/images/eclinics/internal.png"
    },
    {
        "id": 13,
        "title": "Neurology",
        "image": "https://sanar-assets.com/images/eclinics/neurology.png"
    },
    {
        "id": 14,
        "title": "Geriatric Medicine",
        "image": "https://sanar-assets.com/images/eclinics/geriatric-medicine.png"
    },
    {
        "id": 15,
        "title": "Pulmonary & Sleep Disturbances",
        "image": "https://sanar-assets.com/images/eclinics/pulmonary-sleep.png"
    },
    {
        "id": 16,
        "title": "Nephrology",
        "image": "https://sanar-assets.com/images/eclinics/nephrology.png"
    },
    {
        "id": 17,
        "title": "Cardiology",
        "image": "https://sanar-assets.com/images/eclinics/cardiology.png"
    },
    {
        "id": 18,
        "title": "Opthalamology",
        "image": "https://sanar-assets.com/images/eclinics/opthalamology.png"
    },
    {
        "id": 19,
        "title": "Nutrition & Bariatric surgery",
        "image": "https://static.sanar.sa/images/eclinics/nutrition-bariatric.png"
    },
    {
        "id": 20,
        "title": "Orthopedics",
        "image": "https://sanar-assets.com/images/eclinics/orthopedics.png"
    },
    {
        "id": 21,
        "title": "Speech Therapy",
        "image": "https://sanar-assets.com/images/eclinics/speech-therapy.png"
    },
    {
        "id": 22,
        "title": "General Surgery",
        "image": "https://sanar-assets.com/images/eclinics/surgery.png"
    },
    {
        "id": 23,
        "title": "Oncology",
        "image": "https://static.sanar.sa/images/eclinics/oncology.png"
    },
    {
        "id": 24,
        "title": "Dentistry",
        "image": "https://static.sanar.sa/images/eclinics/dentistry.png"
    }
]