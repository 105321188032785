import React from 'react'
import BiService from '../Components/CareIndividuals/BiService'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import BiOrder from '../Components/CareIndividuals/BiOrder'
import BiDoctors from '../Components/CareIndividuals/BiDoctors'
import BiHealth from '../Components/CareIndividuals/BiHealth'
import '../Style/CareIndividuals.css'

function CareIndividuals() {
  return (
    <>
      <Navbar />
      <BiService />
      <BiOrder />
      <BiDoctors />
      <BiHealth />
      <Footer />
    </>
  )
}

export default CareIndividuals
