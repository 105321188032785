import React from 'react'
import '../../Style/Medical_center.css'
import { Link } from 'react-router-dom';

function Medical_Center() {
  return (
    <div className="row gutter50 top120 join-adv">
      <div className="col6 col-md6 col-sm6 sa-col-xs-12 mob-none">
        <figure>
          <img src="https://sanar-assets.com/images/static/new-theme/care-provider-hospitals-section.png" alt="" className="img-cover"></img>
        </figure>
      </div>
      <div className="col6 col-md6 col-sm6 sa-col-xs-12">
        <h4 className="success-text h4 mb20">Advantages of joining</h4>
        <h2 className="h2">For Hospitals &amp; medical center</h2>
        <p className="mb30">Be our partner and join in our distinguished list of hospital and medical centers who want to expand the reach of its services  through providing patients with high quality home care experines tailored to their needs,</p>
        <ul className="join-doc-list ml30">
          <li>
            <div className="common-title gap30">
              <i className="icon-date-time icon-bg"></i>
              <h5 className="h5">Manage appointments easily</h5>
            </div>
            <p className="pl85">Receiving and managing reservation requests and appointing your medical staff.</p>
          </li>
          <li>
            <div className="common-title gap30">
              <i className="icon-target icon-bg"></i>
              <h5 className="h5">Serving a wider segment</h5>
            </div>
            <p className="pl85">Reducing pressure on outpatient clinics and patient's stay in the hospital. Reducing long waiting lines, which increases patient's satisfactionز</p>
          </li>
          <li>
            <div className="common-title gap30">
              <i className="icon-skill icon-bg"></i>
              <h5 className="h5">Integrated professional services</h5>
            </div>
            <p className="pl85">Adding the medical staff and managing them - adding your medical services - a control panel for your institution and many more..</p>
          </li>
          <div className="text-right">
            <Link className='link' to="/Joinbutton">
              <button className="btn btn-primary btn-lg">Join in</button>
            </Link>
          </div>
        </ul>
      </div>
    </div>
  )
}

export default Medical_Center