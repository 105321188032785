export const tests = [
    {
        "id": "glucose",
        "testName": "GLUCOSE ( FBS )",
        "testPrice": "30"
    },
    {
        "id": "ironbinding",
        "testName": "TOTAL IRON Binding Capacity (TIBC)",
        "testPrice": "30"
    },
    {
        "id": "aso",
        "testName": "Aso Titer ( Anti Streptolysin O )",
        "testPrice": "60"
    },
    {
        "id": "fungal",
        "testName": "FUNGAL CULTURE C/S",
        "testPrice": "125"
    },
    {
        "id": "international",
        "testName": "International Normalized Ratio INR",
        "testPrice": "50"
    },
    {
        "id": "uric",
        "testName": "URIC ACID SERUM",
        "testPrice": "30"
    },
    {
        "id": "protein",
        "testName": "TOTAL PROTEIN IN URINE",
        "testPrice": "30"
    },
    {
        "id": "alp",
        "testName": "ALP(ALKALINE PHOSPHATASE)",
        "testPrice": "30"
    },
    {
        "id": "sodium",
        "testName": "NA (S) 'Sodium'",
        "testPrice": "30"
    },
    {
        "id": "g6pdh",
        "testName": "G6PDH activity ( Glucose 6 Phosphate Dehydrogenase Deficiency Test )",
        "testPrice": "125"
    },
    {
        "id": "progesterone",
        "testName": "Progesterone Blood Test",
        "testPrice": "80"
    },
    {
        "id": "rota",
        "testName": "Rota Virus in Stool",
        "testPrice": "100"
    },
    {
        "id": "troponin",
        "testName": "Troponin Test",
        "testPrice": "180"
    },
    {
        "id": "mullerian",
        "testName": "Anti Mullerian Hormone - AMH",
        "testPrice": "180"
    },
    {
        "id": "glucoserbs",
        "testName": "GLUCOSE ( RBS )",
        "testPrice": "30"
    },
    {
        "id": "wbccount",
        "testName": "WBC COUNT",
        "testPrice": ""
    },
    {
        "id": "lithium",
        "testName": "Lithium Level in Serum",
        "testPrice": "180"
    },
    {
        "id": "bnp",
        "testName": "BNP(brain natriuretic peptide)",
        "testPrice": "200"
    },
    {
        "id": "occult",
        "testName": "STOOL OCCULT BLOOD",
        "testPrice": "40"
    },
    {
        "id": "aldosterone",
        "testName": "Aldosterone in serum",
        "testPrice": "185"
    },
    {
        "id": "corrected",
        "testName": "Corrected Calcium Test",
        "testPrice": "185"
    },
    {
        "id": "proteinserum",
        "testName": "Total Protein in Serum",
        "testPrice": "30"
    },
    {
        "id": "ldlcholesterol",
        "testName": "LDL SAR CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "vitaminb6",
        "testName": "Vitamin B6 (Pyridoxal Phosphate)",
        "testPrice": "180"
    },
    {
        "id": "widal",
        "testName": "Widal Test",
        "testPrice": "50"
    },
    {
        "id": "glomerular",
        "testName": "Glomerular Filtration (eGFR)",
        "testPrice": "80"
    },
    {
        "id": "prostatic",
        "testName": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "testPrice": "100"
    },
    {
        "id": "coombs",
        "testName": "Coomb`s Test (Indirect)",
        "testPrice": "50"
    },
    {
        "id": "bence",
        "testName": "BENCE JONES PROTEIN",
        "testPrice": "565"
    },
    {
        "id": "urethral",
        "testName": "Urethral Discharge Culture C/S ( GENTAL )",
        "testPrice": "60"
    },
    {
        "id": "urineculture",
        "testName": "Urine Culture C/S",
        "testPrice": "80"
    },
    {
        "id": "folic",
        "testName": "Folic Acid",
        "testPrice": "100"
    },
    {
        "id": "triglycerides",
        "testName": "TRIGLYCERIDES",
        "testPrice": "30"
    },
    {
        "id": "magnesium",
        "testName": "MG (S) 'magnesium'",
        "testPrice": "30"
    },
    {
        "id": "cortisol",
        "testName": "Cortisol in Serum",
        "testPrice": "100"
    },
    {
        "id": "creatinine",
        "testName": "Creatinine CLEARANCE TEST",
        "testPrice": "60"
    },
    {
        "id": "gpt",
        "testName": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "testPrice": "30"
    },
    {
        "id": "cumulative",
        "testName": "Cumulative Sugar Test (HBA1C)",
        "testPrice": "30"
    },
    {
        "id": "cholesterol",
        "testName": "CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "hbs",
        "testName": "HBs Ag (HEPATITIS B MARKER)",
        "testPrice": "100"
    },
    {
        "id": "ana",
        "testName": "ANA (ANT NUCLEAR ANTIBODIES) / ANF ( Factor )",
        "testPrice": "185"
    },
    {
        "id": "igg",
        "testName": "IGG Immunoglobulin",
        "testPrice": "185"
    },
    {
        "id": "creatinine",
        "testName": "Creatinine blood test",
        "testPrice": "30"
    },
    {
        "id": "ldh",
        "testName": "LDH ( Lactate Dehydrogenase )",
        "testPrice": "30"
    },
    {
        "id": "testosterone",
        "testName": "Free Testosterone",
        "testPrice": "80"
    },
    {
        "id": "afb",
        "testName": "AFB ZN STAIN ( ACID  FAST  BACILLI STAIN ) SMEAR",
        "testPrice": "50"
    },
    {
        "id": "eosinophilic",
        "testName": "TOTAL EOSINOPHILIC COUNT ( ABSOLUTE )",
        "testPrice": "30"
    },
    {
        "id": "acth",
        "testName": "ACTH ( Adrino Cortico Trophic Hormone )",
        "testPrice": "185"
    },
    {
        "id": "lipase",
        "testName": "LIPASE",
        "testPrice": "30"
    },
    {
        "id": "bun",
        "testName": "BUN/Creatinine Ratio",
        "testPrice": "50"
    },
    {
        "id": "gamma",
        "testName": "GAMMA GT (GGT) ( Gamma Glutamyl Transferase )",
        "testPrice": "30"
    },
    {
        "id": "acp",
        "testName": "ACP - Acid Phosphatase",
        "testPrice": "30"
    },
    {
        "id": "estrogen",
        "testName": "ESTROGEN (E2: ESTRADIOL)",
        "testPrice": "80"
    },
    {
        "id": "phenytoin",
        "testName": "Phenytoin (DPH, Epanutin)",
        "testPrice": "255"
    },
]

export const bodyTest = [
    {
        "id": "cbc",
        "testName": "CBC (COMPLET BLOOD COUNT)",
        "testPrice": "255"
    },
    {
        "id": "tch",
        "testName": "TSH (THYROID STIMULATING HORMONE)",
        "testPrice": "80"
    },
    {
        "id": "ferritin",
        "testName": "FERRITIN",
        "testPrice": "60"
    },
    {
        "id": "iron",
        "testName": "Iron",
        "testPrice": "30"
    },
    {
        "id": "creatinine",
        "testName": "Creatinine blood test",
        "testPrice": "30"
    },
    {
        "id": "cholesterol",
        "testName": "CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "totaltetraiodo",
        "testName": "TOTAL T4 ( Total Tetraiodo Thyronine )",
        "testPrice": "80"
    },
    {
        "id": "hemoglobin",
        "testName": "HEMOGLOBIN ",
        "testPrice": "30"
    },
    {
        "id": "triiodothyronine",
        "testName": "FREE T3 OR : TRIIODOTHYRONINE ",
        "testPrice": "80"
    },
    {
        "id": "ldl",
        "testName": "LDL CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "triglycerides",
        "testName": "TRIGLYCERIDES",
        "testPrice": "30"
    },
    {
        "id": "gpt",
        "testName": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "testPrice": "30"
    },
    {
        "id": "bloodurea",
        "testName": "BLOOD UREA TEST",
        "testPrice": "30"
    },
    {
        "id": "ast",
        "testName": "AST/ALT Ratio",
        "testPrice": "40"
    },
    {
        "id": "got",
        "testName": "GOT (AST) GLUT-OXALO-ACETIC TRANSA ( Glutamate Oxaloacetate Transaminase )",
        "testPrice": "30"
    },
    {
        "id": "hdl",
        "testName": "HDL CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "bun",
        "testName": "BUN/Creatinine Ratio",
        "testPrice": "50"
    },
    {
        "id": "freetetraiodo",
        "testName": "FREE T4 ( Free Tetraiodo Thyronine )",
        "testPrice": "80"
    },
    {
        "id": "ironbinding",
        "testName": "TOTAL IRON Binding Capacity (TIBC)",
        "testPrice": "30"
    },
    {
        "id": "glomerular",
        "testName": "Glomerular Filtration (eGFR)",
        "testPrice": "80"
    },
    {
        "id": "lipid",
        "testName": "LIPID PROFILE",
        "testPrice": "120"
    },
    {
        "id": "alp",
        "testName": "ALP(ALKALINE PHOSPHATASE)",
        "testPrice": "30"
    },
    {
        "id": "abo",
        "testName": "ABO \ Rh typing Blood Grouping RH PHENOTYPE",
        "testPrice": "30"
    },
    {
        "id": "bil",
        "testName": "BIL TOTAL ( Bilirubin )",
        "testPrice": "30"
    },
    {
        "id": "albumin",
        "testName": "ALBUMIN",
        "testPrice": "30"
    },
    {
        "id": "gamma",
        "testName": "GAMMA GT (GGT) ( Gamma Glutamyl Transferase )",
        "testPrice": "30"
    },
    {
        "id": "bildirect",
        "testName": "BIL DIRECT  ( Bilirubin )",
        "testPrice": "30"
    },
    {
        "id": "protein",
        "testName": "TOTAL PROTEIN IN URINE",
        "testPrice": "30"
    },
]

export const diabetesTest = [
    {
        "id": "cumulative",
        "testName": "Cumulative Sugar Test (HBA1C)",
        "testPrice": "30"
    },
    {
        "id": "glucose",
        "testName": "GLUCOSE ( FBS )",
        "testPrice": "30"
    },
    {
        "id": "glucoserbs",
        "testName": "GLUCOSE ( RBS )",
        "testPrice": "30"
    },
    {
        "id": "lipase",
        "testName": "LIPASE",
        "testPrice": "30"
    },
    {
        "id": "amylase",
        "testName": "Amylase (serum)",
        "testPrice": "30"
    },
]

export const cholesterolTest = [
    {
        "id": "cholesterol",
        "testName": "CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "ldl",
        "testName": "LDL CHOLESTEROL ",
        "testPrice": "30"
    },
    {
        "id": "triglycerides",
        "testName": "TRIGLYCERIDES",
        "testPrice": "30"
    },
    {
        "id": "hdl",
        "testName": "HDL CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "lipid",
        "testName": "LIPID PROFILE",
        "testPrice": "120"
    },
    {
        "id": "lipoprotein",
        "testName": "Very-low-density lipoprotein VLDL",
        "testPrice": "30"
    },
]

export const hairTest = [
    {
        "id": "mch",
        "testName": "MCH (Mean Corpuscular Hemoglobin)",
        "testPrice": "135"
    },
    {
        "id": "mcv",
        "testName": "MCV(mean corpuscular volume)",
        "testPrice": "135"
    },
]

export const skinTest = [
    {
        "id": "vitamind",
        "testName": "Vitamin D",
        "testPrice": "100"
    },
    {
        "id": "vitaminb12",
        "testName": "VITAMIN B12 (CYANOCOBOLAMINE)",
        "testPrice": "100"
    },
    {
        "id": "zinc",
        "testName": "ZINC (serum)",
        "testPrice": "80"
    },
    {
        "id": "prolactin",
        "testName": "PROLACTIN ( PRL )",
        "testPrice": "80"
    },
    {
        "id": "fsh",
        "testName": "FSH (FOLLICLE STIULATING HORMONE)",
        "testPrice": "80"
    },
    {
        "id": "lh",
        "testName": "LH (LUTEINISING HORMONE)",
        "testPrice": "80"
    },
    {
        "id": "calciumserum",
        "testName": "SERUM CA ( Calcium )",
        "testPrice": "30"
    },
    {
        "id": "testosterone",
        "testName": "TESTOSTERONE TOTAL",
        "testPrice": "80"
    },
    {
        "id": "freetestosterone",
        "testName": "Free Testosterone",
        "testPrice": "80"
    },
    {
        "id": "progesterone",
        "testName": "Progesterone Blood Test",
        "testPrice": "80"
    },
    {
        "id": "estrogen",
        "testName": "ESTROGEN (E2: ESTRADIOL)",
        "testPrice": "80"
    },
    {
        "id": "folic",
        "testName": "Folic Acid",
        "testPrice": "100"
    },
    {
        "id": "mullerian",
        "testName": "Anti Mullerian Hormone - AMH",
        "testPrice": "180"
    },
    {
        "id": "aldosterone",
        "testName": "Aldosterone in serum",
        "testPrice": "185"
    },
]

export const vitaminTest = [
    {
        "id": "vitamind",
        "testName": "Vitamin D",
        "testPrice": "100"
    },
    {
        "id": "vitaminb12",
        "testName": "VITAMIN B12 (CYANOCOBOLAMINE)",
        "testPrice": "100"
    },
    {
        "id": "zinc",
        "testName": "ZINC (serum)",
        "testPrice": "80"
    },
    {
        "id": "calciumserum",
        "testName": "SERUM CA ( Calcium )",
        "testPrice": "30"
    },
    {
        "id": "folic",
        "testName": "Folic Acid",
        "testPrice": "100"
    },
    {
        "id": "ascorbic",
        "testName": "Vitamin C (Ascorbic Acid )",
        "testPrice": "180"
    },
    {
        "id": "thiamin",
        "testName": "Vitamin B1 (Thiamin)",
        "testPrice": "180"
    },
    {
        "id": "retinol",
        "testName": "Vitamin A (Retinol)",
        "testPrice": "180"
    },
    {
        "id": "vitaminb6",
        "testName": "Vitamin B6 (Pyridoxal Phosphate)",
        "testPrice": "180"
    },
    {
        "id": "folate",
        "testName": "SERUM B12 + FOLATE",
        "testPrice": "100"
    },
    {
        "id": "tocopherol",
        "testName": "Vitamin E (Alpha - Tocopherol)",
        "testPrice": "180"
    },
    {
        "id": "vitaminb9",
        "testName": "Vitamin B9 ( Folate )",
        "testPrice": "100"
    },
    {
        "id": "copper",
        "testName": "Copper in serum",
        "testPrice": "100"
    },
    {
        "id": "riboflavin",
        "testName": "Vitamin B2 (Riboflavin)",
        "testPrice": "180"
    },
]

export const urinaryTest = [
    {
        "id": "urineanalysis",
        "testName": "URINE ANALYSIS",
        "testPrice": "30"
    },
    {
        "id": "urineculture",
        "testName": "Urine Culture C/S",
        "testPrice": "80"
    },
    {
        "id": "albuminratio",
        "testName": "Albumin/Creatinine ratio (A/C ratio)",
        "testPrice": "100"
    },
    {
        "id": "prostatic",
        "testName": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "testPrice": "100"
    },
    {
        "id": "creatinine",
        "testName": "Creatinine CLEARANCE TEST",
        "testPrice": "60"
    },
    {
        "id": "proteinserum",
        "testName": "Total Protein in Serum",
        "testPrice": "30"
    },
    {
        "id": "micro",
        "testName": "Micro Albumin Urea ( Microalbuminuria )",
        "testPrice": "60"
    },
    {
        "id": "prostatic",
        "testName": "PROSTATIC SPECIFIC ANTIGEN (PSA) TOTAL",
        "testPrice": "100"
    },
    {
        "id": "creatinineurine",
        "testName": "CREATININE URINE",
        "testPrice": "30"
    },
    {
        "id": "bence",
        "testName": "BENCE JONES PROTEIN",
        "testPrice": "565"
    },
]

export const kidneyTest = [
    {
        "id": "sodium",
        "testName": "NA (S) 'Sodium'",
        "testPrice": "30"
    },
    {
        "id": "creatinine",
        "testName": "Creatinine blood test",
        "testPrice": "30"
    },
    {
        "id": "urineanalysis",
        "testName": "URINE ANALYSIS",
        "testPrice": "30"
    },
    {
        "id": "uric",
        "testName": "URIC ACID SERUM",
        "testPrice": "30"
    },
    {
        "id": "corrected",
        "testName": "Corrected Calcium Test",
        "testPrice": "185"
    },
    {
        "id": "bloodurea",
        "testName": "BLOOD UREA TEST",
        "testPrice": "30"
    },
    {
        "id": "urineculture",
        "testName": "Urine Culture C/S",
        "testPrice": "80"
    },
    {
        "id": "bun",
        "testName": "BUN/Creatinine Ratio",
        "testPrice": "50"
    },
    {
        "id": "glomerular",
        "testName": "Glomerular Filtration (eGFR)",
        "testPrice": "80"
    },
    {
        "id": "albuminratio",
        "testName": "Albumin/Creatinine ratio (A/C ratio)",
        "testPrice": "100"
    },
    {
        "id": "creatinine",
        "testName": "Creatinine CLEARANCE TEST",
        "testPrice": "60"
    },
    {
        "id": "micro",
        "testName": "Micro Albumin Urea ( Microalbuminuria )",
        "testPrice": "60"
    },
    {
        "id": "bence",
        "testName": "BENCE JONES PROTEIN",
        "testPrice": "565"
    },
]

export const liverTest = [
    {
        "id": "gpt",
        "testName": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "testPrice": "30"
    },
    {
        "id": "ast",
        "testName": "AST/ALT Ratio",
        "testPrice": "40"
    },
    {
        "id": "got",
        "testName": "GOT (AST) GLUT-OXALO-ACETIC TRANSA ( Glutamate Oxaloacetate Transaminase )",
        "testPrice": "30"
    },
    {
        "id": "alp",
        "testName": "ALP(ALKALINE PHOSPHATASE)",
        "testPrice": "30"
    },
    {
        "id": "albumin",
        "testName": "ALBUMIN",
        "testPrice": "30"
    },
    {
        "id": "gamma",
        "testName": "GAMMA GT (GGT) ( Gamma Glutamyl Transferase )",
        "testPrice": "30"
    },
    {
        "id": "protein",
        "testName": "TOTAL PROTEIN IN URINE",
        "testPrice": "30"
    },
    {
        "id": "proteinserum",
        "testName": "Total Protein in Serum",
        "testPrice": "30"
    },
]

export const parathyroidTest = [
    {
        "id": "vitamind",
        "testName": "Vitamin D",
        "testPrice": "100"
    },
    {
        "id": "calciumserum",
        "testName": "SERUM CA ( Calcium )",
        "testPrice": "30"
    },
    {
        "id": "bil",
        "testName": "BIL TOTAL ( Bilirubin )",
        "testPrice": "30"
    },
    {
        "id": "phosphorus",
        "testName": "PHOSPHORUS",
        "testPrice": "30"
    },
    {
        "id": "mgmagnesium",
        "testName": "MG (S) 'magnesium'",
        "testPrice": "30"
    },
]

export const thyroidTest = [
    {
        "id": "tch",
        "testName": "TSH (THYROID STIMULATING HORMONE)",
        "testPrice": "80"
    },
    {
        "id": "totaltetraiodo",
        "testName": "TOTAL T4 ( Total Tetraiodo Thyronine )",
        "testPrice": "80"
    },
    {
        "id": "triiodothyronine",
        "testName": "FREE T3 OR : TRIIODOTHYRONINE ",
        "testPrice": "80"
    },
    {
        "id": "freetetraiodo",
        "testName": "FREE T4 ( Free Tetraiodo Thyronine )",
        "testPrice": "80"
    },
    {
        "id": "antithyroid",
        "testName": "Anti Thyroid Peroxidase Abs (TPO)",
        "testPrice": "565"
    },
]

export const cardiacTest = [
    {
        "id": "cbc",
        "testName": "CBC (COMPLET BLOOD COUNT)",
        "testPrice": "255"
    },
    {
        "id": "ferritin",
        "testName": "FERRITIN",
        "testPrice": "60"
    },
    {
        "id": "iron",
        "testName": "Iron",
        "testPrice": "30"
    },
    {
        "id": "cholesterol",
        "testName": "CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "international",
        "testName": "International Normalized Ratio INR",
        "testPrice": "50"
    },
    {
        "id": "ldl",
        "testName": "LDL CHOLESTEROL ",
        "testPrice": "30"
    },
    {
        "id": "triglycerides",
        "testName": "TRIGLYCERIDES",
        "testPrice": "30"
    },
    {
        "id": "hdl",
        "testName": "HDL CHOLESTEROL",
        "testPrice": "30"
    },
    {
        "id": "ironbinding",
        "testName": "TOTAL IRON Binding Capacity (TIBC)",
        "testPrice": "30"
    },
    {
        "id": "lipid",
        "testName": "LIPID PROFILE",
        "testPrice": "120"
    },
    {
        "id": "troponin",
        "testName": "Troponin Test",
        "testPrice": "180"
    },
    {
        "id": "eosinophilic",
        "testName": "TOTAL EOSINOPHILIC COUNT ( ABSOLUTE )",
        "testPrice": "30"
    },
    {
        "id": "wbccount",
        "testName": "WBC COUNT",
        "testPrice": ""
    },
]

export const boneTest = [
    {
        "id": "magnesium",
        "testName": "MG (S) 'magnesium'",
        "testPrice": "30"
    },
    {
        "id": "phosphorus",
        "testName": "PHOSPHORUS",
        "testPrice": "30"
    },
]

export const bloodTest = [
    {
        "id": "cbc",
        "testName": "CBC (COMPLET BLOOD COUNT)",
        "testPrice": "255"
    },
    {
        "id": "ferritin",
        "testName": "FERRITIN",
        "testPrice": "60"
    },
    {
        "id": "iron",
        "testName": "Iron",
        "testPrice": "30"
    },
    {
        "id": "ironbinding",
        "testName": "TOTAL IRON Binding Capacity (TIBC)",
        "testPrice": "30"
    },
    {
        "id": "abo",
        "testName": "ABO \ Rh typing Blood Grouping RH PHENOTYPE",
        "testPrice": "30"
    },
    {
        "id": "g6pdh",
        "testName": "G6PDH activity ( Glucose 6 Phosphate Dehydrogenase Deficiency Test )",
        "testPrice": "125"
    },
    {
        "id": "eosinophilic",
        "testName": "TOTAL EOSINOPHILIC COUNT ( ABSOLUTE )",
        "testPrice": "30"
    },
    {
        "id": "wbccount",
        "testName": "WBC COUNT",
        "testPrice": ""
    },
    {
        "id": "mch",
        "testName": "MCH (Mean Corpuscular Hemoglobin)",
        "testPrice": "135"
    },
    {
        "id": "mcv",
        "testName": "MCV(mean corpuscular volume)",
        "testPrice": "135"
    },
]

export const digestionTest = [
    {
        "id": "ubt",
        "testName": "UREA BREATH TEST (UBT)",
        "testPrice": "185 "
    },
    {
        "id": "helicobacter",
        "testName": "Helicobacter Pylori Stool Antigen (HpSA)",
        "testPrice": "100"
    },
    {
        "id": "stoolculture",
        "testName": "STOOL CULTURE",
        "testPrice": "80"
    },
    {
        "id": "hpylori",
        "testName": "H Pylori ( Helicobacter ) HP ANTIBODY",
        "testPrice": "145"
    },
    {
        "id": "allergens",
        "testName": "Allergens specific IgE Screening (Food panel)",
        "testPrice": "565"
    },
    {
        "id": "calprotectin",
        "testName": "Calprotectin Stool",
        "testPrice": "565"
    },
    {
        "id": "stoolanalysis",
        "testName": "Stool Analysis",
        "testPrice": "30"
    },
]

export const allergyTest = [
    {
        "id": "allergens",
        "testName": "Allergens specific IgE Screening (Food panel)",
        "testPrice": "565"
    },
    {
        "id": "allergensige",
        "testName": "Allergens specific IgE Screening (Inhalant panel)",
        "testPrice": "565"
    },
    {
        "id": "igg",
        "testName": "IGG Immunoglobulin",
        "testPrice": "185"
    },
    {
        "id": "igm",
        "testName": "IGM ( IMMUNOGLOBULIN )",
        "testPrice": "185"
    },
]