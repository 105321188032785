import React from 'react';

import '../../../Style/cancelation-policy.css';
import { Link } from 'react-router-dom';

function Cancelation() {
    return (
        <div>
            <div>
                <div className="modal fade" id="Cancellationpage" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div id='modal-content1'>
                            <div className="modal-body">
                                <div className='btn-space'>
                                    <button type="button" className="btn-close regv2back" data-bs-dismiss="modal" aria-label="Close">
                                        {/* <i className="icon-close"></i> */}
                                    </button>
                                </div>

                                <div className="cancelation-policy-MVv">
                                    <div className="group-1261154744-3Cx">
                                        {/* <img className="group-1261154722-y6c" src="./assets/group-1261154722-Vf2.png" alt="Cancellation"/> */}
                                        <p className="cancellation-and-refund-policy-i4C">Cancellation and refund policy</p>
                                        <div className="group-1261154755-btg">
                                            <div className="group-1261154753-wBr">
                                                <p className="home-visit-HFi">Home visit</p>
                                                <p className="refund-of-the-payment-only-when-appointment-is-canceled-before-5-hours-or-more-pFe">100% refund of the payment only when appointment is canceled before 5 hours or more</p>
                                            </div>
                                            <div className="group-1261154752-7Ek">
                                                <p className="telemedicine-TpQ">Telemedicine</p>
                                                <p className="refund-of-the-payment-only-when-appointment-is-canceled-before-5-hours-or-more-if-the-patient-misses-telemedinice-appointment-due-to-no-show-the-consultation-fees-is-nonrefundable-and-appointment-cant-be-rescheduled-oNU">
                                                    100% refund of the payment only when appointment is canceled before 5 hours or more
                                                    <br />
                                                    If the patient misses telemedinice appointment due to “No show” the consultation fees
                                                    <br />
                                                    is non-refundable and appointment cant be rescheduled.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="group-1261154754-2FE">Close</div>
                                    </div>
                                </div>

                                {/* <Link to="/">
                                    <button type='button' className='btn btn-primary' style={{ width: "100%" }}>Close</button>
                                </Link> */}
                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <div class="frame-37121-wmn">Continue</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cancelation;
