import React from 'react'
import './App.css';
import Home from './views/Home';
import About from './views/About';
import JoinAsProvider from './views/JoinAsProvider';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import CareBusiness from './views/CareBusiness';
import Corparatewellnesscomponent from './Components/Corparatewellness/Corparatewellnesscomponent';
import RequestService from './Components/Corparatewellness/RequestService';
import Requestsuccesfull from './Components/Corparatewellness/Requestsuccesfull';
import CareIndividuals from './views/CareIndividuals';
import Contactus from './Components/Contact/Contactus';
import VirtualConsultation from './Components/Home/Telemedicine/VirtualConsultation';
import { data } from '../src/ClinicData.js'
import Review from './Components/Home/Telemedicine/Review.js';
import Doctor_Specialist from './Components/JoinAsProvider/Doctor_Specialist';
import Hospital_Health_Center from './Components/JoinAsProvider/Hospital_Health_Center';
import Insuarance_Company from './Components/JoinAsProvider/Insuarance_Company';
import PersonalizedRequest from './Components/CareIndividuals/PersonalizedRequest.js';
import Doctorcard from './Components/Home/Physiotherapist/Doctorcard.js';
import Join_button from './Components/JoinAsProvider/Join_button.js';
import VaccinationList from './Components/Home/Vaccination/VaccinationList.js';
import Vaccination_step_2 from './Components/Home/Vaccination/Vaccination_step_2.js';
import Caregiver from './Components/CareGiverServiceList/Caregiver.js';
import VitaminIVs1 from './Components/VitaminVIDrips/VitaminIVs1.js';
import Caregivertask1 from './Components/CareGiverServiceList/Caregivertask1.js';
import Caregivertask2 from './Components/CareGiverServiceList/Caregivertask2.js';
import Caregivertask3 from './Components/CareGiverServiceList/Caregivertask3.js';
import Caregivertask4 from './Components/CareGiverServiceList/Caregivertask4.js';
import VitaminCenter from './Components/VitaminVIDrips/VitaminCenter.js';
import HomeVisitDoctor from './Components/Home/HomeVisitTab/HomeVisitDoctor.js';
import NurseVisit from './Components/Home/NurseVisitTab/NurseVisit.js';

import NurseVisitContinue from './Components/Home/NurseVisitTab/NurseVisitContinue.js';

import Laboratory from './Components/Home/Laboratory/Laboratory.js';
import ViewAllPackages from './Components/Home/Laboratory/ViewAllPackages/ViewAllPackages.js';
import ViewAllTests from './Components/Home/Laboratory/ViewAllTests/ViewAllTests.js';
import FullBody from './Components/Home/Laboratory/BodyFunctions/FullBody.js';
import Diabetes from './Components/Home/Laboratory/BodyFunctions/Diabetes.js';
import Cholesterol from './Components/Home/Laboratory/BodyFunctions/Cholesterol.js';
import Hair from './Components/Home/Laboratory/BodyFunctions/Hair.js';
import Skin from './Components/Home/Laboratory/BodyFunctions/Skin.js';
import Vitamins from './Components/Home/Laboratory/BodyFunctions/Vitamins.js';
import UrinarySystem from './Components/Home/Laboratory/BodyFunctions/UrinarySystem.js';
import Kidney from './Components/Home/Laboratory/BodyFunctions/Kidney.js';
import Liver from './Components/Home/Laboratory/BodyFunctions/Liver.js';
import Parathyroid from './Components/Home/Laboratory/BodyFunctions/Parathyroid.js';
import Thyroid from './Components/Home/Laboratory/BodyFunctions/Thyroid.js';
import Cardiac from './Components/Home/Laboratory/BodyFunctions/Cardiac.js';
import Bone from './Components/Home/Laboratory/BodyFunctions/Bone.js';
import BloodHealth from './Components/Home/Laboratory/BodyFunctions/BloodHealth.js';
import Digestion from './Components/Home/Laboratory/BodyFunctions/Digestion.js';
import Allergy from './Components/Home/Laboratory/BodyFunctions/Allergy.js';
import Terms_Conditions from './Components/Login_Signin_popups/Terms_Conditions.js';
import PrivacyPolicy from './Components/Login_Signin_popups/PrivacyPolicy.js';
import LabDiabetes from './Components/Home/Laboratory/LabServicesPages/LabDiabetes.js'
import Labfullbody from './Components/Home/Laboratory/Labfullbody.js';
import Selectlabs from './Components/Home/Laboratory/Selectlabs.js';
import Adddetails from './Components/Home/Laboratory/Adddetails.js'
import Payment from './Components/Home/Laboratory/Payment.js'

function App() {
  return (
    <div id="ZwaaraApp">
      <Router>
        <Routes>
          <Route exact path='/' element={<><Home /></>}></Route>
          <Route exact path='/aboutus' element={<About />}></Route>
          <Route exact path='/joinAsProvider' element={<JoinAsProvider />}></Route>
          <Route exact path='/forbussiness' element={<CareBusiness />}></Route>
          
          {/* for join-in button links */}
          <Route exact path='/joinbutton' element={<Join_button />}></Route>
          <Route exact path='/doctorspecialist' element={<Doctor_Specialist />}></Route>
          <Route exact path='/hospitalhealthcenter' element={<Hospital_Health_Center />}></Route>
          <Route exact path='/insuarancecompany' element={<Insuarance_Company /> }></Route>

          {/* <Route exact path='/forindividuals' element={<Careindividuals />}></Route> */}
          <Route exact path='/corporate-wellness' element={<Corparatewellnesscomponent />}></Route>
          <Route exact path='/request-service-form' element={<RequestService />}></Route >
          <Route exact path='/request-succesfull/wellness=form' element={<Requestsuccesfull />}></Route>
          <Route exact path='/forindividuals' element={<CareIndividuals />}></Route>
          <Route exact path='/contactus' element={<Contactus />}></Route>

          {data.map((item) => (
            <Route key={item.id} exact path={`/browser/telemedicine/deptid=${item.id}&pId=ST11&stid=ST1`} element={<VirtualConsultation />}></Route>
          ))}

          <Route exact path='/review/utid=1' element={<Review />}></Route>
          <Route exact path='/doctorspecialist' element={<Doctor_Specialist />}></Route>
          <Route exact path='/hospitalhealthcenter' element={<Hospital_Health_Center />}></Route>
          <Route exact path='/insuarancecompany' element={<Insuarance_Company />}></Route>
          <Route exact path='/personalizedrequest' element={<PersonalizedRequest />}></Route>
          <Route exact path='/vaccinationstep2' element={<Vaccination_step_2 /> }></Route>
          <Route exact path='/physio/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={<Doctorcard />}></Route>
          <Route exact path='/vaccination/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={<VaccinationList />}></Route>
          <Route exact path='/corporate-wellness' element={<Corparatewellnesscomponent />}></Route>
          <Route exact path='/Caregiver/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={< Caregiver />} />
          <Route exact path='/caregiver-task1' element={<Caregivertask1 />}></Route>
          <Route exact path='/caregiver-task2' element={<Caregivertask2 />}></Route>
          <Route exact path='/caregiver-task3' element={<Caregivertask3 />}></Route>
          <Route exact path='/caregiver-task4' element={<Caregivertask4 />}></Route>
          <Route exact path='/vitamin/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={< VitaminIVs1 />} />
          <Route exact path='/vitamin-center' element={<VitaminCenter />}></Route>
          <Route exact path='/homevisit/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={<HomeVisitDoctor />}></Route>
          <Route exact path='/nurse/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={<NurseVisit />}></Route>

          <Route exact path='/nursevisitcontinue' element={<NurseVisitContinue />}></Route>
          <Route exact path='/termsandcondition' element={<Terms_Conditions />}></Route>
          <Route exact path='/privacypolicy' element={<PrivacyPolicy />}></Route>
          <Route exact path='/laboratory' element={<Laboratory />}></Route>
          <Route exact path='/Selectlabs' element={<Selectlabs />}></Route>
          <Route exact path='/Adddetails' element={<Adddetails />}></Route>
          <Route exact path='/Labfullbody' element={<Labfullbody />}></Route>
          <Route exact path='/laboratorydiabetes' element={<LabDiabetes/>}></Route>
          <Route exact path='/Payment' element={<Payment />}></Route>

          <Route exact path='/viewpackages/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={<ViewAllPackages />}></Route>
          <Route exact path='/viewtests/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' element={<ViewAllTests />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=1&name=Full Body' element={<FullBody />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=2&name=Diabetes' element={<Diabetes />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=3&name=Cholesterol' element={<Cholesterol />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=4&name=Hair' element={<Hair />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=5&name=Skin' element={<Skin />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=6&name=Vitamins' element={<Vitamins />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=7&name=Urinary tract system' element={<UrinarySystem />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=8&name=Kidney' element={<Kidney />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=9&name=Liver' element={<Liver />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=10&name=Parathyroid' element={<Parathyroid />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=11&name=Thyroid' element={<Thyroid />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=12&name=Cardiac (Heart)' element={<Cardiac />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=13&name=Bone' element={<Bone />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=14&name=Blood health' element={<BloodHealth />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=15&name=Digestion' element={<Digestion />}></Route>
          <Route exact path='/test-body-function/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04&id=16&name=Allergy' element={<Allergy />}></Route>
        </Routes>
      </Router>

    </div>
  )
}

export default App

