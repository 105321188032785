import React, { useState } from 'react';
import '../../Style/Signin_popup.css';
import { Link } from 'react-router-dom';



function LoginPopup() {
    const intialValues = {
        mobileNumber: "",
    };
    const [mobileNumber, setMobileNumber] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formValues,] = useState(intialValues);

    const [selectedCountry, setSelectedCountry] = useState('in');
    const countries = [
        { code: 'in', name: 'India', countryCode: '+91' },
        { code: 'sa', name: 'Saudi Arabia', countryCode: '+966' },
        { code: 'eg', name: 'Egypt', countryCode: '+20' },
        { code: 'au', name: 'Australia', countryCode: '+61' },
        { code: 'cn', name: 'Canada', countryCode: '+1' },
        { code: 'fr', name: 'France', countryCode: '+33' },
        { code: 'pk', name: 'Pakistan', countryCode: '+92' },
        { code: 'sl', name: 'Sri Lanka', countryCode: '+94' }
    ];

    const validate = (values) => {
        const errors = {};
        var mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (!values.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required!";
        } else if (!mobileNumberRegex.test(values.mobileNumber)) {
            errors.mobileNumber = "This is not a valid mobile Number format!";
        }
        return errors;
    }

    const handleCountryChange = (event) => {
        const selectedCountryCode = event.target.value;
        setSelectedCountry(selectedCountryCode);
    };

    const getCountryCode = (selectedCountry) => {
        const country = countries.find((c) => c.code === selectedCountry);
        return country ? country.countryCode : '';
    };

    const handleSubmit = () => {
        const errors = validate(formValues);

        if (Object.keys(errors).length === 0) {
            console.log("Form submitted successfully!");
        } else {
            setFormErrors(errors);
        }
    };
    const handleMobileNumberChange = (event) => {
        setMobileNumber(event.target.value);
    };

    return (
        <>
            <div className='zw_popup'>
                <div className="modal fade zw_captch_pop" id="Loginmodal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='btn-space'>
                                    <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="icon-close"></i>
                                    </button>
                                </div>
                                <div className="card card-primary-light card-no-border last-child">

                                    <div className="mob-header mob-block">Verification</div>
                                    <div className="login-brd">
                                        <form>
                                            <div className="input-group input-group-vert mb20">
                                                <label className='zw_text_color poppins-regular zw_32'>Country</label>

                                                <div className="form-group zw_form_group">
                                                    <div className='zw_form_control zw_secondary poppins-regular zw_16'>
                                                        <div>
                                                            <select
                                                                id="countrycode"
                                                                name="countrycode"
                                                                className="form-control input-no-border"
                                                                value={selectedCountry}
                                                                onChange={handleCountryChange}
                                                            >
                                                                {countries.map((country) => (
                                                                    <option key={country.code} value={country.code}>
                                                                        {country.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <i className="icon-down-arrow form-icon zw_icon_drop"></i>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div className="form-group zw_form_group">
                                                        <div className='zw_country_sec zw_border_radius zw_light_bg'>
                                                            {selectedCountry && (
                                                                <img
                                                                    className="form-icon img-ccode form-img zw_country_flag"
                                                                    src={`https://sanar-assets.com/flags/${selectedCountry}_64.png`}
                                                                    alt="Country Flags"
                                                                />
                                                            )}
                                                            <span className="cuntry-plholder" >{getCountryCode(selectedCountry)}</span>
                                                        </div>
                                                        <input
                                                            type='text'
                                                            className='form-control zw_form_control zw_secondary poppins-regular zw_16'
                                                            placeholder='Number'
                                                            style={{ width: "180%", paddingLeft: "110px" }}
                                                            value={mobileNumber}
                                                            onChange={handleMobileNumberChange}
                                                        />
                                                        {formErrors.mobileNumber && <div style={{ color: 'red' }}>{formErrors.mobileNumber}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-bs-dismiss="modal" aria-label="Close" className="form-group f-size12 poppins-regular zw_16">By clicking continue you agree to our&nbsp;
                                                <Link to="/termsandcondition" className="link">Terms &amp; Conditions</Link>{''} and{''} <Link to="/privacypolicy" className="link">Privacy Policy</Link>
                                            </div>
                                            
                                            <button type="button" className="btn-primary btn-lg btn-block poppins-regular zw_bg_gradient zw_btn_18 zw_sbmtbtn_radius" style={{ width: "80%", margin: " 0 10%" }} onClick={handleSubmit}>
                                                Continue
                                            </button>
                                            <p style={{ textAlign: "center" }}>Don't have an account <Link className='signup_link' data-bs-toggle="modal" data-bs-target="#signup"><b>Sign Up</b></Link></p>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LoginPopup;