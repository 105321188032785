import React, { useContext, useEffect, useState } from 'react';
import '../../../Style/NurseVisit.css';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { nursevisitpopupdata } from './NurseVisitPopopData';
import { Context } from '../../../Context';
import { nursevisitdata } from './NurseVisitData';

function NurseVisitContinueList(props) {

    const [show, setShow] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const { selectedItems, setSelectedItems } = useContext(Context);    

    useEffect(() => {
        const slider = document.querySelector("[data-slider]");

        const track = slider.querySelector("[data-slider-track]");
        const prev = slider.querySelector("[data-slider-prev]");
        const next = slider.querySelector("[data-slider-next]");

        if (track) {
            prev.addEventListener("click", () => {
                next.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft - track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            next.addEventListener("click", () => {
                prev.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft + track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            track.addEventListener("scroll", () => {
                const trackScrollWidth = track.scrollWidth;
                const trackOuterWidth = track.clientWidth;

                prev.removeAttribute("disabled");
                next.removeAttribute("disabled");

                if (track.scrollLeft <= 0) {
                    prev.setAttribute("disabled", "");
                }

                if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
                    next.setAttribute("disabled", "");
                }
            });
        }
    })

    const openModel = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const selectedTaskData = nursevisitpopupdata.find(item => item.id === selectedTaskId);

    return (
        <>
            <div className="z-card-list-box">
                <figure className="z-doc-avtar z-doc-avtar-lg">
                    <img src={props.doctorImage} alt="Doctor" className="z-doc-img-cover" />
                </figure>

                <div className="z-doctor-details">
                    <div className="z-doctor-details-data">
                        <div>
                            <h6>{props.doctorName} </h6>
                            <span className="z-desig"> {props.doctorDesig} </span>
                        </div>
                        <div className='nv-link nv-mob-split' onClick={() => openModel()}>View Task Details</div>
                    </div>

                    <div className="z-doctor-price">
                        <span className="z-dark"> Price </span>
                        <h6 className="z-price-tag">
                            <span> {props.currency} </span> {props.price}<br></br>
                            <span className='nv-gray-dark-text nv-font-normal'>{props.includefees}</span>
                        </h6>
                    </div>
                </div>
            </div>

            <div className="z-time-carousel-slide" data-slider>
                <ul className="z-time-carousel" data-slider-track>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:00 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:15 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:30 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:45 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:30 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:45 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:30 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:45 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:30 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:45 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 03:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 03:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 03:30 pm </p>
                        </Link>
                    </li>

                </ul>

                <button name="next button" type="button" className="z-time-prev icon-left-arrow" data-slider-prev disabled></button>
                <button name="prev button" type="button" className="z-time-next icon-right-arrow" data-slider-next></button>
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
                <div className='nvc-dialog nvc-dialog-bottom'>
                    <div className='nvc-dialog-body nvc-dialog-overflow-unset'>
                        <button className='nvc-dialog-close nvc-dialog-close-rgt nvc-line-close' onClick={handleClose}>
                            <i className='icon-close'></i>
                        </button>
                        {nursevisitdata.map((item, index) => (
                            selectedItems.includes(item.id) && (
                                <div className='nvc-body-cnt' key={index}>
                                    <h4 className='nvc-tit'>Selected task</h4>
                                    <div className='nvc-gray-light-bg nvc-card-no-border'>
                                        <h6 className='nvc-box-title'>Instructions</h6>
                                        <ul className='nvc-unorder-list'>
                                            <li>{item.instruction}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p className='nvc-dark-text'>{item.nursetitle}</p>
                                        <ul className='nvc-unorder-list-admin'>
                                            <li>{item.instruction1}</li>
                                            <li>{item.instruction2}</li>
                                            <li>{item.instruction3}</li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NurseVisitContinueList
