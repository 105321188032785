import React, { useState } from 'react'
import '../../../Style/add-details.css'
import Footer from '../../Layout/Footer'
import Navbar from '../../Layout/Navbar'
import { Link } from 'react-router-dom'
import Addpatient from './Addpatient'

function Adddetails() {

    const initialItems = ['Zwaara Care',];

    const [items, setItems] = useState(initialItems);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        if (term.trim() === '') {

            setItems(initialItems);
        } else {

            const filtered = initialItems.filter(item => item.toLowerCase().includes(term));
            setItems(filtered);
        }
    };

    const [counter, setCounter] = useState(1);

    const decrement = () => {
        if (counter > 1) {
            setCounter(counter - 1);
        }
    };

    const increment = () => {
        setCounter(counter + 1);
    };
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState('Add your text notes here...');

    const handleClick = () => {
        setIsEditing(true);
        setText('');
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleBlur = () => {
        if (text.trim() === '') {
            setIsEditing(false);
            setText('Add your text notes here...');
        }
    };
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };
    return (
        <div>
            <Navbar />
            <div className="add-details-72t" style={{ marginTop: "100px" }}>
                <div className="auto-group-dwhz-hqe">
                    <div className="group-1261154093-2d2">
                        <Link to={"/Selectlabs"} style={{ textDecoration: 'none' }}>
                            <div className="group-1261154076-wEC">
                                <img src='./images/Group 1261154072.png' className='group-1261154072-sPS' alt=''></img>
                                <p className="back-bZe">Back</p>
                            </div></Link>
                        <div className="group-1261154934-8pU">
                            <div className="rectangle-39545-3gY">
                            </div>
                            <div className="rectangle-39546-m6k">
                            </div>
                            <div className="rectangle-39547-gUc">
                            </div>
                            <div className="rectangle-39548-QvQ">
                            </div>
                        </div>
                    </div>

                    <div className="frame-37120-wfS">
                        <p className="search-your-lab-tests-packages-VBA">
                            <input type="text" placeholder="Search your lab tests &amp; Packages" value={searchTerm} onChange={handleSearch} style={{ width: "500px", marginTop: '10px' }} /></p>
                        <img className="line-1-CbN" src="/images/line-1-GWG.png" alt='' />
                        <img className="vector-Lha" src="/images/vector-vFW.png" alt='' />
                    </div>
                </div>
                <div className="auto-group-fd7r-3bz">
                    <div className="auto-group-dzfn-ZaL">
                        <div className="group-1261154677-WEg" style={{ display: 'flex', flexDirection: 'column' }}>
                            {items.map((item, index) => (
                                <div key={index} className="group-1261154676-dKJ" style={{ marginBottom: '20px' }}>
                                    <img src='./images/group-1261154660-QQC.png' className='group-1261154676-dKJ' alt=''></img>
                                    <div className="group-1261154672-vJQ">
                                        <p className="zwaara-care-fmn">{item}</p>
                                        <p className="laboratory-home-visit-cBE">Laboratory -home visit</p>
                                        <p className="view-selected-details-k2Y">View selected details</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="auto-group-k7dv-4Z2">
                            <div className="auto-group-vgpe-omW">
                                <p className="add-details-ZEt">add details</p>
                                <p className="select-patient-t2G">Select Patient </p>
                            </div>

                            <button data-bs-toggle="modal" data-bs-target="#LogInPopUpModal41" style={{ width: "140px", marginTop: "-30px", marginLeft: "500px", padding: '10px', fontSize: "1.5rem" }}>
                                <Addpatient />
                                <div className="add-patient-btn">
                                    <div className="circle-Btn">
                                        <i className="icon-plus" ></i>
                                    </div>
                                    Add Patient
                                </div>
                            </button>
                            <br />
                            <div className="auto-group-ck56-pRi">
                                <p className="helth-check-pakages-9it">Helth check pakages</p>
                                <p className="sar-400-Sxt">SAR 400</p>
                                <p className="choose-one-or-more-patients-for-this-test-wue">Choose one or more patients for this test</p>
                                <div className="rectangle-39553-eJG">
                                </div>
                                <div className="rectangle-39669-AGc">
                                </div>
                                <p className="aliyan-5eU">Aliyan</p>
                                <div className="rectangle-39672-12L">
                                </div>
                                <div className="rectangle-39673-jU8">
                                </div>
                                <div className="rectangle-39674-Gix">
                                </div>
                                <p className="rahmat-QKN">Rahmat</p>
                                <p className="rahmat-KBS">Rahmat</p>
                                <p className="rahmat-SG4">Rahmat</p>

                                <div className="group-1171275053-xVJ">
                                    <div className="group-1171275049-esv" onClick={decrement} style={{ cursor: "pointer" }}>–</div>
                                    <p className="item-1-WfE">{counter}</p>
                                    <div className="group-371-Er8">
                                        <div className="ellipse-57-BmN"></div>
                                        <p className="item--6tL" onClick={increment} style={{ cursor: "pointer" }}>+</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-group-dsgg-mzU">
                        <p className="helth-check-pakages-Wx4">Helth check pakages</p>
                        <p className="if-you-have-medical-condition-allergies-or-taking-any-medication-please-mention-it-RZE">
                            If you have medical condition, Allergies or taking any medication
                            <br />
                            please mention it
                        </p>
                        <div className="rectangle-39671-69a">
                        </div>
                        <div className="group-1261154928-cNp">
                            <div className="group-1261154927-L3v">
                                <p className="text-noteoptional-Uvp">Text note(Optional)</p>
                                <div className="group-1261154924-zPN">
                                    <img src='./images/group-vrG.png' className='group-k7e' alt=''></img>
                                    <p className="add-gmz">ADD</p>
                                </div>
                            </div>
                            <div className="group-1261154938-D1E">
                                {isEditing ? (
                                    <textarea
                                        value={text}
                                        onChange={handleTextChange}
                                        onBlur={handleBlur}
                                        autoFocus style={{ border: "none", width: "100%", height: "100%", backgroundColor: "#F6F7F9", resize: "none" }}
                                    />
                                ) : (
                                    <div onClick={handleClick}>
                                        {text}
                                    </div>
                                )}
                            </div>

                            <div className="group-1261154926-t7N">
                                <p className="attach-document-R7J">Attach document</p>
                                <div className="group-1261154925-Xg8">
                                    <label htmlFor="fileInput">
                                        <img src='./images/group-1261154923-zHz.png' className='group-1261154923-tWg' alt=''></img>
                                        <p className="file-E4k">File</p>
                                    </label>
                                    <input type="file" id="fileInput" accept=".pdf, .doc, .docx" onChange={handleFileChange} style={{ display: 'none' }} />
                                </div>
                                {file && (
                                    <div>
                                        <p>Selected File: {file.name}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <p className="by-clicking-continue-you-agree-to-our-terms-conditions-9Sc">
                        <span className="by-clicking-continue-you-agree-to-our-terms-conditions-9Sc-sub-0">By clicking continue you agree to our </span>
                        <span className="by-clicking-continue-you-agree-to-our-terms-conditions-9Sc-sub-1"> Terms &amp; Conditions</span>
                    </p>
                    <Link to="/Payment" style={{ textDecoration: 'none' }}>
                        <div className="frame-37121-wmn">Continue</div>
                    </Link>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Adddetails