import React from 'react'

function Accountsetup_success() {
    return (
        <div className='zw_popup'>
            <div className="modal fade" id="successs"  role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="dialog signin-dialog">
                                <div className="dialog-body dialog-center dialog-body-sm">
                                    <div className='btn-space'>
                                        <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                            <i className="icon-close"></i>
                                        </button>
                                    </div>
                                    {/* <div className='btn-space'> */}
                                        {/* <button type="button" className="btn-close regv2back" data-bs-dismiss="modal" aria-label="Close"> */}
                                            {/* <i className="icon-close"></i> */}
                                        {/* </button>
                                    </div> */}
                                    <div className="text-center">
                                        <div className="avtar-1 avtar-1-md d-f-end">
                                            <img src="https://sanar-assets.com/images/unknown-avtar.png" className="img-cover-1" alt="" style={{height: "70px", borderRadius: "100%"}}></img>
                                        </div>
                                        <h3 className="zw_text_color poppins-semibold zw_32 mt-3">Hi Manish jain</h3>
                                        <p className="poppins-regular zw_24 zw_86909D">You are signed in to Sanar&nbsp;</p>
                                        <p className="poppins-semibold zw_24 zw_title_color">Successfully!</p>
                                        <p className='poppins-regular zw_22 zw_text_color zw_succ_p'>Your ID is not recognized as a citizen of Saudi Arabia, therefore the VAT Value will be applicable</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Accountsetup_success