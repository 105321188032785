import React, { useEffect, useRef, useState } from 'react';
import '../../Style/Login_step4.css';
import '../../Style/login_popup.css';

function Accountsetup_Bdate() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [days, setDays] = useState([]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedCalendar, setSelectedCalendar] = useState('gregorian');
    const scrollRefs = useRef({ day: null, month: null, year: null });

    const generateDays = () => {
        const daysArray = Array.from({ length: 31 }, (_, i) => i + 1);
        setDays(daysArray);
    };

    const generateMonths = () => {
        const monthsArray = Array.from({ length: 12 }, (_, i) => {
            const month = new Date(2022, i, 1);
            return month.toLocaleString('en-us', { month: 'short' });
        });
        setMonths(monthsArray);
    };

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const yearsArray = Array.from({ length: 100 }, (_, i) => currentYear - i);
        setYears(yearsArray);
    };

    useEffect(() => {
        generateDays();
        generateMonths();
        generateYears();
    }, []);

    const handleScroll = (e, type) => {
        // const scrollTop = e.target.scrollTop;
        // const index = Math.round(scrollTop / 32); // Adjust according to your item height
        // if (type === 'day') setSelectedDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth(), days[index]));
        // else if (type === 'month') setSelectedDate(prevDate => new Date(prevDate.getFullYear(), index, prevDate.getDate()));
        // else if (type === 'year') setSelectedDate(prevDate => new Date(years[index], prevDate.getMonth(), prevDate.getDate()));
    };

    const toggleCalendar = (calendarType) => {
        setSelectedCalendar(calendarType);
    };

    const renderCalendarContent = () => {
        if (selectedCalendar === 'gregorian') {
            return (
                <div className='container' style={{ padding: "20px" }}>
                    <div className="date-picker">
                        <div className="picker-group">
                            <div className="vue-scroll-picker" onScroll={(e) => handleScroll(e, 'day')} ref={(ref) => (scrollRefs.current.day = ref)}>
                                {days.map((day) => (
                                    <div key={day} className={`vue-scroll-picker-item ${selectedDate.getUTCDate() === day ? 'selected' : ''}`} onClick={() => handleDayClick(day)}>
                                        {day}
                                    </div>
                                ))}
                            </div>
                            <div className="vue-scroll-picker" onScroll={(e) => handleScroll(e, 'month')} ref={(ref) => (scrollRefs.current.month = ref)}>
                                {months.map((month, index) => (
                                    <div key={month} className={`vue-scroll-picker-item ${selectedDate.getUTCMonth() === index ? 'selected' : ''}`} onClick={() => handleMonthClick(index)}>
                                        {month}
                                    </div>
                                ))}
                            </div>
                            <div className="vue-scroll-picker" onScroll={(e) => handleScroll(e, 'year')} ref={(ref) => (scrollRefs.current.year = ref)}>
                                {years.map((year) => (
                                    <div key={year} className={`vue-scroll-picker-item ${selectedDate.getUTCFullYear() === year ? 'selected' : ''}`} onClick={() => handleYearClick(year)}>
                                        {year}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <p>Selected Date: {selectedDate.toLocaleDateString()}</p>
                    {/* <p>Selected Month: {selectedDate.toLocaleString('en-us', { month: 'long' })}</p>
                    <p>Selected Year: {selectedDate.getUTCFullYear()}</p> */}
                </div>
            );
        } else if (selectedCalendar === 'hijri') {
            return (
                <div>
                    <p>This is the Hijri calendar content.</p>
                </div>
            );
        }
    };

    const handleDayClick = (day) => {
        setSelectedDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth(), day));
    };

    const handleMonthClick = (index) => {
        setSelectedDate(prevDate => new Date(prevDate.getFullYear(), index, prevDate.getDate()));
    };

    const handleYearClick = (year) => {
        setSelectedDate(prevDate => new Date(year, prevDate.getMonth(), prevDate.getDate()));
    };


    return (
        <div>
            <div className="modal fade" id="LogInPopUpModal4" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span>Select Date of Birth</span>
                            <div className='container' style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}>
                                <button className={`btn btn-primary zw_bgwhite zw_btn_primary select ${selectedCalendar === 'gregorian' ? 'active' : ''}`} style={{ width: "45%" }} onClick={() => toggleCalendar('gregorian')}>
                                    Gregorian
                                </button>
                                <button className={`btn btn-primary zw_bgwhite zw_btn_primary ${selectedCalendar === 'hijri' ? 'active' : ''}`} style={{ width: "45%" }} onClick={() => toggleCalendar('hijri')}>
                                    Hijri
                                </button>
                            </div>
                            <div className='container' style={{ padding: "20px" }}>
                                {renderCalendarContent()}
                            </div>
                            <div className='container' style={{ display: "flex", justifyContent: "center" }}>
                                <button type='button' className='btn btn-primary' style={{ width: "50%" }} data-bs-toggle="modal" data-bs-target="#accountsetup_captcha">
                                    Select
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Accountsetup_Bdate