// import React from 'react'
// import '../../../Style/select-lab.css'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import React, { useState } from 'react'
import '../../../Style/nusres-list-RH6.css'
import '../../../Style/nusres-list.css';
import { data } from './Docterlist';
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import backBtnImg from './../../../assets/img/Group 1261154072.png';
import searchIcon from './../../../assets/img/vector-vFW.png';
import vertLine from './../../../assets/img/Line 1.png';
import logo from './../../../assets/img/logo-2.png';
import caleIcon from './../../../assets/img/caleIcon.png';
// import RadioBtn from './../../CommonComponent/RadioBtn' ;

function Selectlabs() {
    const [startDate, setstartDate] = useState(new Date());
    const [, setShowDoctorList] = useState(data);
    const [Search, setSearch] = useState('');
    let navigate = useNavigate();

    const setLowToHigh = () => {
        const sortedVaccination_DoctorList = data.sort((a, b) => a.price - b.price);
        setShowDoctorList([...sortedVaccination_DoctorList]);
    };
    const setHighToLow = () => {
        const reverseSortedVaccination_DoctorList = data.sort((a, b) => b.price - a.price);
        setShowDoctorList([...reverseSortedVaccination_DoctorList]);
    };
    return (
        <div>
            <Navbar />
            <div className="nusres-list-u3z" style={{ marginTop: "60px" }}>

                <div className="group-1261155035-4Fe">
                    <div className="group-1261154093-ApU">
                        <div className="group-1261154076-tEg" onClick={() => navigate(-1)}>
                            {/* <img className="group-1261154072-Qyi" src="./././assets/img/Group 1261154072.png" alt='' /> */}
                            <img src={backBtnImg} className='group-1261154072-Qyi' alt=''/>
                            <p className="back-TBJ">Back</p>
                        </div>
                        <div className="frame-37129-njN">
                            <div className="rectangle-39545-WfN">
                            </div>
                            <div className="rectangle-39546-eWg">
                            </div>
                            <div className="rectangle-39547-zaY">
                            </div>
                            <div className="rectangle-39548-8gk">
                            </div>
                        </div>
                    </div>

                    <p className="e-clinics-5bz">E- Clinics</p>
                    <div className="auto-group-k2fv-DTJ">
                        <div className="frame-37120-mUp">
                            <input type="text" placeholder="Search your lab tests &amp; Packages" className="search-your-lab-tests-packages-htG" onChange={(e) => setSearch(e.target.value)} style={{ width: "266px", outline: "none" }} />
                            {/* <img className="group-1261154962-pT6" src="./../../../assets/img/group-1261154962-kz8.png" alt='' /> */}
                            <img src={vertLine} className='group-1261154962-pT6'alt=''></img>
                            <img src={searchIcon} className='group-1261154962-pT6'alt=''></img>
                        </div>

                        <div className="group-1261154694-KPr">
                            {/* <div className="group-1261154692-eBE">
                                <div className="group-1261154679-Nd2">
                                    <p className="-LJx"></p>
                                    <p className="item-21-GCc">21</p>
                                </div>
                                <div className="group-1261154680-PY8">
                                    <p className="tue-9nC">Tue</p>
                                    <p className="item-22-6Be">22</p>
                                </div>
                                <div className="group-1261154681-2qz">
                                    <p className="wed-b8Q">Wed</p>
                                    <p className="item-23-vwN">23</p>
                                </div>
                                <div className="group-1261154682-f8G">
                                    <p className="thu-RNL">Thu</p>
                                    <p className="item-24-A52">24</p>
                                </div>
                                <div className="group-1261154683-HQY">
                                    <p className="fri-3ec">Fri</p>
                                    <p className="item-25-zJx">25</p>
                                </div>
                                <div className="group-1261154684-7uN">
                                    <p className="sat-t9S">Sat</p>
                                    <p className="item-26-2WY">26</p>
                                </div>
                                <div className="group-1261154685-yAt">
                                    <p className="sun-w7i">Sun</p>
                                    <p className="item-27-GQt">27</p>
                                </div>
                                <div className="group-1261154679-Nd2">
                                    <p className="-LJx"></p>
                                    <p className="item-21-GCc">21</p>
                                </div>
                                <div className="group-1261154680-PY8">
                                    <p className="tue-9nC">Tue</p>
                                    <p className="item-22-6Be">22</p>
                                </div>
                                <div className="group-1261154681-2qz">
                                    <p className="wed-b8Q">Wed</p>
                                    <p className="item-23-vwN">23</p>
                                </div>
                                <div className="group-1261154682-f8G">
                                    <p className="thu-RNL">Thu</p>
                                    <p className="item-24-A52">24</p>
                                </div>
                                <div className="group-1261154683-HQY">
                                    <p className="fri-3ec">Fri</p>
                                    <p className="item-25-zJx">25</p>
                                </div>
                                <div className="group-1261154684-7uN">
                                    <p className="sat-t9S">Sat</p>
                                    <p className="item-26-2WY">26</p>
                                </div>
                                <div className="group-1261154685-yAt">
                                    <p className="sun-w7i">Sun</p>
                                    <p className="item-27-GQt">27</p>
                                </div>
                            </div> */}
                            <div className="horizontal-calendar" style={{ marginLeft: "-610px", margin: "10px" }}>
                                <div className="date-list" id="dragCalender" style={{ backgroundClip: "rgba(175, 34, 69, 0.1000000015)" }}>
                                    <div className="date-list-scroll" style={{ backgroundClip: "rgba(175, 34, 69, 0.1000000015)" }}>
                                        <div className="date-item choosed-day"><div><p className=""></p><p className="date-item-day">TODAY</p> <p className="date-item-date">14</p> </div> </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Tue</p> <p className="date-item-date">15</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Wed</p> <p className="date-item-date">16</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Thu</p> <p className="date-item-date">17</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">18</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Sat</p> <p className="date-item-date">19</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Sun</p> <p className="date-item-date">20</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">21</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">22</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">23</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">24</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">25</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">26</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">27</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">28</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">29</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">30</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">31</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">1</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">2</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">3</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">4</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">5</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">6</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">7</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">8</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">9</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">10</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">11</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">12</p> </div>  </div>
                                        <div className="date-item"><div><p className=""></p> <p className="date-item-day">Fri</p> <p className="date-item-date">13</p> </div>  </div>



                                    </div>
                                </div>
                            </div>

                            <div className="z-date-picker" style={{ fontWeight: "700" }}>
                                <i className="fa fa-calendar z-cal" aria-hidden="true"></i>
                                <DatePicker className="custom-input" selected={startDate} onChange={(date) => setstartDate(date)} />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="auto-group-quuj-e44">
                    <div className="group-1261154989-ksn">
                        <p className="filters-4da">Filters</p>
                        <p className="price-QBe">Price</p>
                        <div className='radio_btn_container'>
                            <label className="radio_btn_label">
                                <input className="radio-button-4X6" value={""} onClick={setLowToHigh} type="radio" name={"LowtoHigh"} id="exampleRadios1" />
                                <span className='radio-button-span'>Low to High</span>
                            </label>
                        </div>
                        {/* <div className="group-1261154903-8dS">
                            <input className="radio-button-4X6" value='low' onClick={setLowToHigh} type="radio" name="exampleRadios" id="exampleRadios1" />
                            <label className="low-to-high-CdJ">
                                Low to High
                            </label>
                        </div> */}
                        <div className='radio_btn_container'>
                            <label className="radio_btn_label">
                                <input className="radio-button-4X6" value={""} onClick={setHighToLow} type="radio" name={"LowtoHigh"} id="exampleRadios1" />
                                <span className='radio-button-span'>High to Low</span>
                            </label>
                        </div>
                        {/* <div className="group-1261154655-8Wx">
                            <input className="radio-button-Tp8" value='high' onClick={setHighToLow} type="radio" name="exampleRadios" id="exampleRadios1" />
                            <label className="high-to-low-1ak">
                                High to Low
                            </label>
                            <img className="radio-button-Tp8" src="./assets/radio-button-WTW.png" alt='' />
                            <p className="high-to-low-1ak">High to low</p>
                        </div> */}
                        <div className="group-1261154656-k2Y">
                            <p className="select-gender-gwn">Select gender</p>
                            <div className="auto-group-hjik-dc8">
                                <div className='radio_btn_container'>
                                    <label className="radio_btn_label">
                                        <input className="radio-button-4X6" value={""} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                        <span className='radio-button-span'>Any</span>
                                    </label>
                                </div>
                                {/* <div className="group-1261154655-71W">
                                    <input className="radio-button-qTJ" type="radio" id="male" name="gender" value="1"></input>
                                    <label for="male" className="form-check-label">
                                        <i className="male-Ny2"></i> Any
                                    </label>
                                </div> */}
                                {/* <p className="any-NJp">Any</p> */}
                                <div className='radio_btn_container'>
                                    <label className="radio_btn_label">
                                        <input className="radio-button-4X6" value={""} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                        <span className='radio-button-span'>Male</span>
                                    </label>
                                </div>
                                {/* <div className="group-1261154655-71W">
                                    <input className="radio-button-qTJ" type="radio" id="male" name="gender" value="1"></input>
                                    <label for="male" className="form-check-label">
                                        <i className="male-Ny2"></i> Male
                                    </label>
                                </div> */}
                                {/* <div className="group-1261154655-71W">
                                    <img className="radio-button-qTJ" src="./assets/radio-button-Hzk.png" alt='' />
                                    <p className="male-Ny2">Male</p>
                                </div> */}
                                <div className='radio_btn_container'>
                                    <label className="radio_btn_label">
                                        <input className="radio-button-4X6" value={""} type="radio" name={"Selectgender"} id="exampleRadios1" />
                                        <span className='radio-button-span'>Female</span>
                                    </label>
                                </div>
                                {/* <div className="group-1261154655-71W">
                                    <input className="radio-button-qTJ" type="radio" id="male" name="gender" value="1"></input>
                                    <label for="male" className="form-check-label">
                                        <i className="male-Ny2"></i> Female
                                    </label>
                                </div> */}
                                {/* <div className="group-1261154656-i1J">
                                    <img className="radio-button-4L4" src="./assets/radio-button-Hyv.png" alt='' />
                                    <p className="female-zzQ">Female</p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="nusres-list-bdE" style={{ marginLeft: "-192px", marginTop: "405px" }}>
                        <div className="auto-group-f5yx-byE">
                            <div className="auto-group-nbnc-Es6">
                                {data.filter((item) => {
                                    return Search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(Search)
                                }).map((item) => (
                                    <div className="group-1261155003-zbN" key={item.id}>
                                        <div className="group-1261154661-8xU">
                                            <div className="logo-r7n">
                                            </div>
                                        </div>
                                        <div className="group-1261154922-aJg">
                                            <p className="experience-6-years-zdJ">
                                                <span className="experience-6-years-zdJ-sub-0" style={{ marginTop: "-30px" }}>Laborotory-Home Visit  </span>

                                            </p>
                                            <p className="dr-sarah-mitchell-FBr">{item.name}</p>
                                            <div className="group-1261154663-keQ">
                                                <p className="price-fmN">Price</p>
                                                <div className="group-1261154662-Qyr">
                                                    <p className="sar-9Re">SAR</p>
                                                    <p className="item-540-H24">{item.price}</p>
                                                </div>

                                            </div>
                                            <div>
                                                <img src={logo} className='logoOnPage'alt=''></img>
                                            </div>
                                            <div className="group-1261154991-jPr">View Selected Details</div>
                                            <div className="rectangle-39553-bgx">
                                            </div>
                                            <div className="line-3-8Rz">
                                            </div>

                                            <div className="desktop-1-s8g">
                                                <div className="group-1261154671-kyA">
                                                    <div className="group-1261154689-Vvk">
                                                        {/* <div className="group-1261154690-faL">12:30 Pm</div> */}
                                                    </div>
                                                </div>
                                                <div className="rectangle-39623-iHi">
                                                </div>
                                            </div>
                                            <div className="carousel-slide">
                                                <ul className="carousel slot" id="carousel0">
                                                    <li><p className="time-slot">03:30 pm</p></li>
                                                    <li><p className="time-slot">04:00 pm</p></li>
                                                    <li><p className="time-slot">04:30 pm</p></li>
                                                    <li><p className="time-slot">05:00 pm</p></li>
                                                    <li><p className="time-slot">05:30 pm</p></li>
                                                    <li><p className="time-slot">06:00 pm</p></li>
                                                    <li><p className="time-slot">06:30 pm</p></li>
                                                    <li><p className="time-slot">07:00 pm</p></li>
                                                    <li><p className="time-slot">07:30 pm</p></li>
                                                    <li><p className="time-slot">08:00 pm</p></li>
                                                    <li><p className="time-slot">08:30 pm</p></li>
                                                    <li><p className="time-slot">03:30 pm</p></li>
                                                    <li><p className="time-slot">03:00 pm</p></li>
                                                    <li><p className="time-slot">03:00 pm</p></li>
                                                    <li><p className="time-slot">03:00 pm</p></li>
                                                    <li><p className="time-slot">03:00 pm</p></li>
                                                </ul>
                                            </div>
                                            <div className="group-1261154687-QNp">
                                                <img className="vector-7o2" src={caleIcon} alt='' />
                                                <p className="next-day-tue-24-Tbz">Next day tue 24</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <Link to="/Adddetails">
                                    <button className="frame-37121-SnY" style={{ background: "linear-gradient(100.93deg, #af2245 0%, #602d8a 100%)", borderRadius: "0.5rem", color: "#ffffff", height: "5.3rem", width: "300px", border: "none", marginRight: "470px", fontSize: "1.4rem" }}>Continue</button>
                                </Link>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Selectlabs