export const nursevisitdata = [
    {
        "id": 1,
        "nursetitle": "Intramuscular Injection Or Subcutaneous administration",
        "nursedescription": "Intramuscular injection (IM), the nurse injects a drug directly into the muscle.",
        "nursehideshow": "More",
        "nursepricetag": " 100 ",
        "nursestatuscard": "Includes : Syringe and alcohol swabs will be brought., * solutions and drugs to be injected are not included. , The patient must present a medical prescription that includes the medication name and dosages .",
        "instruction": "Tell the Nurse about the medications you are taking and if you have any drug allergy, pregnancy, or infection",
        "instruction1": "Syringe and alcohol swabs will be brought.",
        "instruction2": "* solutions and drugs to be injected are not included.",
        "instruction3": "The patient must present a medical prescription that includes the medication name and dosages ."
    },
    {
        "id": 2,
        "nursetitle": "Injection/Home IV therapy",
        "nursedescription": "the nurse injects a drug or solution directly into the vein or through IV cannula, the nurse will confirm allergy history prior to give IV treatment.",
        "nursehideshow": "More",
        "nursepricetag": " 150 ",
        "nursestatuscard": "Includes : Syringe and IV tube will be brought. Possibility to check allergy from the prescribed medication by taking a drug allergy test. , * solutions and drugs to be injected are not included. , *Instructions: The patient must present a medical prescription that includes the medication name and dosages .",
        "instruction": "Tell the specialist about the medications you are taking and if you have any drug allergy, pregnancy, or infection. Show doctor’s prescription, if you have any.",
        "instruction1": "Syringe and IV tube will be brought. Possibility to check allergy from the prescribed medication by taking a drug allergy test.",
        "instruction2": "* solutions and drugs to be injected are not included.",
        "instruction3": "*Instructions: The patient must present a medical prescription that includes the medication name and dosages ."
    },
    {
        "id": 3,
        "nursetitle": "Nebulisation",
        "nursedescription": "The nurse will assist you through a session in which you will be able to inhale medicine as a spray through a mask . Shortness of breath and the airway will be improved with this treatment.",
        "nursehideshow": "More",
        "nursepricetag": " 150 ",
        "nursestatuscard": "Includes : Nebulizer will be brought along with a single-use face mask, tube ، solution and Medications., *Instructions: The patient must present a medical prescription that includes the medication name and dosages .",
        "instruction": "Tell the specialist about the medications you are taking and if you have any drug allergy, pregnancy, or infection",
        "instructiontitle": "Nebulisation",
        "instruction1": "Nebulizer will be brought along with a single-use face mask, tube ، solution and Medications.",
        "instruction2": "",
        "instruction3": "*Instructions: The patient must present a medical prescription that includes the medication name and dosages ."
    },
    {
        "id": 4,
        "nursetitle": "Urinary Catheter Insertion & Removal",
        "nursedescription": "The nurse performs one/all of the following procedures: insertion, replacement or removal of the urinary catheter.",
        "nursehideshow": "More",
        "nursepricetag": " 250 ",
        "nursestatuscard": "Includes : Includes: Catheter will be brought with its drainage tube and drainage collection bag, alcohol swabs, cotton, medical disinfectant, sterile gloves and lubricant gel .",
        "instruction": "Tell the specialist about the medications you are taking and if you have any drug allergy, pregnancy, or infection. Moreover, inform the specialist if you have been diagnosed with any disease in the reproductive and urinary tract systems",
        "instructiontitle": "Urinary Catheter Insertion & Removal",
        "instruction1": "",
        "instruction2": "",
        "instruction3": "Includes: Catheter will be brought with its drainage tube and drainage collection bag, alcohol swabs, cotton, medical disinfectant, sterile gloves and lubricant gel ."
    },
    {
        "id": 5,
        "nursetitle": "Surgical Dressing",
        "nursedescription": "The nurse performs surgical wound cleaning, disinfection and dressing.",
        "nursehideshow": "More",
        "nursepricetag": " 200 ",
        "nursestatuscard": "Includes : Medical adhesive tape and alcohol swabs, Vaseline gauze, sterile gloves and saline solution will be brought to disinfect and change the dressing.",
        "instruction": "Tell the Nurse if you suffer from any infectious diseases that can be transmitted through blood and whether you have been diagnosed with diabetes or not.",
        "instructiontitle": "Surgical Dressing",
        "instruction1": "Medical adhesive tape and alcohol swabs, Vaseline gauze, sterile gloves and saline solution will be brought to disinfect and change the dressing.",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 6,
        "nursetitle": "Nasogastric tube insertion",
        "nursedescription": "The nurse performs one/all of the following procedures: insertion, replacement or removal of the nasogastric tube",
        "nursehideshow": "More",
        "nursepricetag": " 250 ",
        "nursestatuscard": "Includes : A Nasogastric (Feeding) tube with an appropriate diameter will be brought along with medical disinfectant, cotton and tape.",
        "instruction": "Inform the specialist about the last time the tube was replaced, causes for intubation and if you suffer from any infectious diseases",
        "instructiontitle": "Nasogastric tube insertion",
        "instruction1": "A Nasogastric (Feeding) tube with an appropriate diameter will be brought along with medical disinfectant, cotton and tape.",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 7,
        "nursetitle": "Oxygen Therapy",
        "nursedescription": "A 15-30-minute oxygen treatment session will be provided by the nurse to treat shortness of breath (dyspnea) and low blood oxygen levels (hypoxemia)",
        "nursehideshow": "More",
        "nursepricetag": "",
        "nursestatuscard": "Includes : Oxygen Cylinder will be brought along with a single-use face mask, and tube .",
        "instruction": "",
        "instructiontitle": "",
        "instruction1": "",
        "instruction2": "",
        "instruction3": ""

    },
    {
        "id": 8,
        "nursetitle": "Check vital signs",
        "nursedescription": "Body temperature. Pulse rate. Blood pressure Random blood glucose, oxygen saturation",
        "nursehideshow": "More",
        "nursepricetag": " 100 ",
        "nursestatuscard": "Includes : Includes: Single Use Gloves , Sphygmomanometer, glucometer, thermometer, pulse oximeter",
        "instruction": "Disclosure of chronic diseases in the elderly",
        "instructiontitle": "Check vital signs",
        "instruction1": "Includes: Single Use Gloves , Sphygmomanometer, glucometer, thermometer, pulse oximeter",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 9,
        "nursetitle": "Removal of Stitches",
        "nursedescription": "The nurse will remove stitches from injuries and post-surgical wounds.",
        "nursehideshow": "More",
        "nursepricetag": " 200 ",
        "nursestatuscard": "Includes : Medical adhesive tape and alcohol swabs, vaseline gauze, and salt solution will be brought for disinfection",
        "instruction": "Tell the specialist if you suffer from any infectious diseases that can be transmitted through blood and whether you have been diagnosed with diabetes or not.",
        "instructiontitle": "Removal of Stitches",
        "instruction1": "Medical adhesive tape and alcohol swabs, vaseline gauze, and salt solution will be brought for disinfection",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 10,
        "nursetitle": "Ear or nose Piercing",
        "nursedescription": "Ear Piercing or Nose Piercing",
        "nursehideshow": "More",
        "nursepricetag": " 150 ",
        "nursestatuscard": "Includes : Includes: Earnings",
        "instruction": "",
        "instructiontitle": "Ear or nose Piercing",
        "instruction1": "Includes: Earnings",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 11,
        "nursetitle": "Wound Care",
        "nursedescription": "The nurse performs wound cleaning and dressing, including bedsores (pressure ulcers).",
        "nursehideshow": "More",
        "nursepricetag": " 225 ",
        "nursestatuscard": "Includes : Sterile single-use medical gloves, alcohol wipes, gauze and medical adhesive will be brought.",
        "instruction": "Tell the Nurse if you suffer from any infectious diseases that can be transmitted through blood and whether you have been diagnosed with diabetes or not.",
        "instructiontitle": "Wound Care",
        "instruction1": "Sterile single-use medical gloves, alcohol wipes, gauze and medical adhesive will be brought.",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 12,
        "nursetitle": "Tracheostomy care",
        "nursedescription": "The nurse provides tracheostomy care (Changing the tracheostomy tube is not included in the service.)",
        "nursehideshow": "More",
        "nursepricetag": "",
        "nursestatuscard": "Includes : Suction machine , Suction Tube , sterile water bottle ,alcohol swabs and medical disinfectant",
        "instruction": "",
        "instructiontitle": "",
        "instruction1": "",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 13,
        "nursetitle": "Burn Dressing",
        "nursedescription": "The nurse performs burn cleaning, disinfection and dressing, the service includes medications that stimulate rapid burn healing",
        "nursehideshow": "More",
        "nursepricetag": " 350 ",
        "nursestatuscard": "Includes : Medical adhesive tape and alcohol swabs, Vaseline gauze, sterile gloves and saline solution will be brought to disinfect and change the dressing, burn medications .",
        "instruction": "",
        "instructiontitle": "Burn Dressing",
        "instruction1": "Medical adhesive tape and alcohol swabs, Vaseline gauze, sterile gloves and saline solution will be brought to disinfect and change the dressing, burn medications .",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "id": 14,
        "nursetitle": "Seasonal Flu",
        "nursedescription": "Seasonal Flu vaccines are recommended by MOH, to protect against seasonal influenza",
        "nursehideshow": "More",
        "nursepricetag": "",
        "nursestatuscard": "Includes : flu Vaccine , gloves , alcohol swabs, Medical adhesive tape",
        "instruction": "",
        "instructiontitle": "",
        "instruction1": "",
        "instruction2": "",
        "instruction3": ""
    }
]
