import React from 'react'
import '../../Style/Home.css'
import $ from 'jquery'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ZwaaraServices() {

    const { t } = useTranslation();

    $(window).on("load", function () {
        $('#zmedical').hide();
        $('#zbusiness').hide();

        $('#individual').on('click', function () {
            $('#zindividual').show();
            $('#zmedical').hide();
            $('#zbusiness').hide();

            $('#individual').addClass('active');
            $('#business').removeClass('active');
            $('#medical').removeClass('active');
        });
    })

    $(window).on("load", function () {
        $('#business').on('click', function () {
            $('#zbusiness').show();
            $('#zmedical').hide();
            $('#zindividual').hide();

            $('#business').addClass('active');
            $('#individual').removeClass('active');
            $('#medical').removeClass('active');
        });
    })

    $(window).on("load", function () {
        $('#medical').on('click', function () {
            $('#zmedical').show();
            $('#zbusiness').hide();
            $('#zindividual').hide();

            $('#medical').addClass('active');
            $('#business').removeClass('active');
            $('#individual').removeClass('active');
        });
    })

    return (
        <>
            {/* <div className='container z-mob-none'>
                <div className='row z-gutter'>
                    <div className='col-7 col-md-7 col-sm-7 col-xs-12'>
                        <div id='zindividual' className='z-zwaara-bg z-faq-bg'>
                            <img src='https://sanar-assets.com/images/static/new-theme/mobileimg.png' alt='mobileimg' className='service-img' />

                            <div className='z-card-shadow'>
                                <h4> {t("ZIndividualTitle")} </h4>
                                <p> {t("ZIndividualSubTitle")} </p>

                                <div className='z-app-stores'>
                                    <a href='https://play.google.com/store/apps/details?id=com.sanarcare.app&hl=en_IN' target='_blank' rel='noreferrer'>
                                        <img src='https://sanar-assets.com/images/static/play-store.svg' alt='' className='w-100' />
                                    </a>
                                    <a href='https://apps.apple.com/us/app/sanar-%D8%B3%D9%86%D8%A7%D8%B1/id1473258160' target='_blank' rel='noreferrer'>
                                        <img src='https://sanar-assets.com/images/static/app-store.svg' alt='' className='w-100' />
                                    </a>
                                    <a href='/' className='btn btn-primary z-service-btn-primary'>
                                        <p>
                                            {t("IndividualButton")}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id='zbusiness' className='z-faq-bg z-business-prov'>
                            <img src='https://sanar-assets.com/images/static/new-theme/sanar-business-hero-section.png' alt='business-img' className='z-business-img' />

                            <div className='z-card-shadow'>
                                <h4> {t("ZBusinessTitle")} </h4>
                                <p> {t("ZBusinessSubTitle")} </p>

                                <div className='z-app-stores'>
                                    <Link to='/forbussiness' className='btn btn-outline-primary z-service-btn-primary' style={{ border: '1px solid' }}>
                                        <p>
                                            {t("BusinessBtn1")}
                                        </p>
                                    </Link>
                                    <a href='/' className='btn btn-primary z-service-btn-primary'>
                                        <p>
                                            {t("BusinessBtn2")}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id='zmedical' className='z-zwaara-bg'>
                            <img src='https://sanar-assets.com/images/static/new-theme/medical_provider.png' alt='business-img' className='z-medical-img' />

                            <div className='z-card-shadow'>
                                <h4> {t("ZMedicalTitle")} </h4>

                                <div className='z-app-stores'>
                                    <Link to='/forbussiness' className='btn btn-outline-primary z-service-btn-primary' style={{ border: '1px solid' }}>
                                        <p>
                                            {t("MedicalBtn1")}
                                        </p>
                                    </Link>
                                    <a href='/' className='btn btn-primary z-service-btn-primary'>
                                        <p>
                                            {t("MedicalBtn2")}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-5 col-md-5 col-sm-5 col-xs-12 z-service-card'>
                        <h1 className='z-service-tit'> {t("ZwaaraServiceTitle")} </h1>
                        <p> {t("ZwaaraServiceSubTitle")} </p>

                        <div id='individual' className='z-card-shadow active'>
                            <strong> {t("IndividualTitle")} </strong>
                            <p> {t("IndividualSubTitle")} </p>
                        </div>
                        <div id='business' className='z-card-shadow'>
                            <strong> {t("BusinessTitle")} </strong>
                            <p> {t("BusinessSubTitle")} </p>
                        </div>
                        <div id='medical' className='z-card-shadow'>
                            <strong> {t("MedicalTitle")} </strong>
                            <p> {t("MedicalSubTitle")} </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ZwaaraServices
