import React, { useState, useEffect } from "react";
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import '../.././Style/RequestService.css';
function RequestService() {

    const navigate = useNavigate();

    const intialValues = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        companyName: "",
        numberOfEmployees: "",
        companyCity: "",
        insuranceAvailable: "",
        notes: "",
        captcha: ""
    };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false)
    const [formData, setFormData] = useState({
        insuranceAvailable: '',

    });
    const handleChange = (e) => {
        console.log(e.target);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

        setFormData({
            ...formData,
            [name]: value,
        });
        console.log(formValues);
    }

    useEffect(() => {
        setNum(randomNumberInRange(10000, 99999));
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formErrors);
        }
    }, [formErrors, isSubmit])

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }
    const validate = (values) => {
        const errors = {};

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        var mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var RandomCaptchaNum = document.getElementById("RandomCaptchaNum").textContent;

        if (!values.firstName) {
            errors.firstName = "First Name is required!";
        }
        if (!values.lastName) {
            errors.lastName = "Last Name is required!";
        }
        if (!values.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required!";
        } else if (!mobileNumberRegex.test(values.mobileNumber)) {
            errors.mobileNumber = "This is not a valid mobile Number format!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        if (!values.companyName) {

            errors.companyName = "Company Name is required!";
        }
        if (!values.numberOfEmployees) {
            errors.numberOfEmployees = "Number Of Employees is required!";
        }
        if (!values.companyCity) {
            errors.companyCity = "Company City is required!";
        }
        if (!values.captcha) {
            errors.captcha = "Captcha is required!";
        }
        else if (RandomCaptchaNum !== values.captcha) {
            errors.captcha = "Captcha is not valid!";
        }
        return errors;
    }


    const [num, setNum] = useState(0);

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    const handleClick = () => {
        setNum(randomNumberInRange(10000, 99999));
    };

    const countriesData = [
        { name: 'Saudi Arabia', code: '+966', flag: 'https://sanar-assets.com/flags/sa_64.png' },
        { name: 'United Kingdom', code: '+44', flag: 'https://sanar-assets.com/flags/gb_64.png' },
        { name: 'India', code: '+91', flag: 'https://sanar-assets.com/flags/in_64.png' },
        { name: 'Egypt', code: '+20', flag: 'https://sanar-assets.com/flags/eg_64.png' },
        { name: 'United Arab Emirates', code: '+971', flag: 'https://sanar-assets.com/flags/ae_64.png' },
        { name: 'Itly', code: '+39', flag: 'https://sanar-assets.com/flags/ae_64.png' },
        { name: 'Afghanistan', code: '+93', flag: 'https://sanar-assets.com/flags/af_64.png' },
        { name: 'Albania', code: '+355', flag: 'https://sanar-assets.com/flags/al_64.png' },
        { name: 'Algeria', code: '+213', flag: 'https://sanar-assets.com/flags/dz_64.png' },
        { name: 'American Samoa', code: '+1684', flag: 'https://sanar-assets.com/flags/as_64.png' },
        { name: 'Andorra', code: '+376', flag: 'https://sanar-assets.com/flags/ad_64.png' },
        { name: 'Angola', code: '+244', flag: 'https://sanar-assets.com/flags/ao_64.png' },
        { name: 'Anguilla', code: '+1264', flag: 'https://sanar-assets.com/flags/ai_64.png' },
        { name: 'Antigua And Barbuda', code: '+1268', flag: 'https://sanar-assets.com/flags/ag_64.png' },
        { name: 'Argentina', code: '+54', flag: 'https://sanar-assets.com/flags/ar_64.png' },
        { name: 'Armenia', code: '+374', flag: 'https://sanar-assets.com/flags/am_64.png' },
        { name: 'Aruba', code: '+297', flag: 'https://sanar-assets.com/flags/aw_64.png' },
        { name: 'Australia', code: '+61', flag: 'https://sanar-assets.com/flags/au_64.png' },
        { name: 'Austria', code: '+43', flag: 'https://sanar-assets.com/flags/at_64.png' },
        { name: 'Azerbaijan', code: '+994', flag: 'https://sanar-assets.com/flags/az_64.png' },
        { name: 'Bahamas', code: '+1242', flag: 'https://sanar-assets.com/flags/bs_64.png' },
        { name: 'Bahrain', code: '+973', flag: 'https://sanar-assets.com/flags/bh_64.png' },
        { name: 'Bangladesh', code: '+880', flag: 'https://sanar-assets.com/flags/bd_64.png' },
        { name: 'Barbados', code: '+1286', flag: 'https://sanar-assets.com/flags/bb_64.png' },

    ];
    const [selectedCountry, setSelectedCountry] = useState(countriesData[0]);

    const handleCountryChange = (event) => {
        const selectedCode = event.target.value;
        const selectedCountryData = countriesData.find(country => country.code === selectedCode);
        setSelectedCountry(selectedCountryData);
    };
    return (
        <div>
            <Navbar />
            <div className="sa-container sa-req-ser-form">
                <Link className="corparate-link" to="/corporate-wellness">
                    <span className="sa-btn-back">
                        <i className="icon-arrow-left">

                        </i>
                        <span style={{ 'lineHeight': "4" }}>Back</span>
                    </span>
                </Link>
                <h4 className="wellnes-header sa-mob-none ">Request Service Form</h4>
                <div className="sa-mob-header">
                    <h5 className="sa-mob-block">Request Service Form</h5>
                    <div className="sa-prog-grid">
                        <p>
                            <span style={{ fontWeight: "bolder" }}>
                                Step 2
                            </span>
                            of 2
                        </p>
                        <div className="sa-prog-bar">
                            <div className="sa-prog-bar-per" style={{ 'width': "100%" }}>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="sa-wellnes-form" style={{ 'backgroundColor': "#edf8ff", 'padding': "30px", 'borderRadius': "20px" }}>
                    <div className="sa-wellnes-form-in" style={{ 'backgroundColor': "#fff", 'padding': "30px", 'borderRadius': "20px" }} >
                        <form onSubmit={handleSubmit}>
                            {Object.keys(formErrors).length === 0 && isSubmit ? navigate('/request-succesfull/wellness=form') : <p></p>}
                            <div className="sa-row" style={{ 'display': 'flex', 'flexDirection': 'row', 'paddingBottom': "20px" }}>
                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-form-group" style={{ display: 'flex', flexDirection: 'column', marginRight: "15px" }}>
                                        <label htmlFor="first_name" style={{ marginBottom: '5px' }}>First Name</label>
                                        <input
                                            id="first_name"
                                            type="text"
                                            className={`sa-form-control ${formErrors.firstName && 'sa-form-error'}`}
                                            name="firstName"
                                            value={formValues.firstName}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', 'borderRadius': "5px" }}
                                        />
                                        {formErrors.firstName && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.firstName}</span>}
                                    </div>
                                </div>

                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-form-group" style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                        <label htmlFor="last_name" style={{ 'marginBottom': '5px' }}>Last Name</label>
                                        <input
                                            id="last_name"
                                            type="text"
                                            class={`sa-form-control ${formErrors.lastName && 'sa-form-error'}`}
                                            name="lastName"
                                            value={formValues.lastName}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', 'borderRadius': "5px" }}
                                        />
                                        {formErrors.lastName && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.lastName}</span>}
                                    </div>
                                </div>
                            </div>


                            <div className="sa-row" style={{ 'display': 'flex', 'flexDirection': 'row', 'paddingBottom': "20px" }}>

                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-row">

                                        <div className="sa-form-group sa-col2 sa-col-md2 sa-col-sm2 sa-col-xs2">
                                            <div className="sa-form-group" style={{ 'display': 'flex', 'flexDirection': 'column', 'paddingRight': "15px", 'position': 'relative' }}>
                                                <label htmlFor="mobile_number" style={{ 'marginBottom': '5px' }}>Mobile Number</label>
                                                <div style={{ position: 'absolute', top: '80%', transform: 'translateY(-50%)', paddingLeft: "3px", paddingBottom: "12px" }}>
                                                    <img
                                                        src={selectedCountry.flag}
                                                        alt=''
                                                        className="sa-form-icon sa-form-img sa-img-ccode"
                                                        style={{ height: "33px", width: "35px" }}
                                                    />
                                                </div>

                                                <select
                                                    id="mobile_number"
                                                    type="text"
                                                    className="sa-form-control"
                                                    name="Mobile Number"
                                                    value={selectedCountry.code}
                                                    onChange={handleCountryChange}
                                                    style={{
                                                        paddingLeft: "30px",
                                                        height: "39.18px",
                                                        width: "206.66px",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    {countriesData.map((country) => (
                                                        <option key={country.code} value={country.code}>
                                                            {country.flag && <img src={country.flag} alt={country.name} style={{ height: "20px", marginRight: "5px" }} />}
                                                            {country.code} ({country.name})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sa-col4 sa-col-md4 sa-col-sm4 sa-col-xs4">
                                            <div className="sa-form-group" style={{ 'display': 'flex', 'flexDirection': 'column', 'paddingLeft': "3px" }}>
                                                <label for="mobile_number" style={{ 'marginBottom': '25px' }}></label>
                                                <input
                                                    id="mobile_number"
                                                    type="tel"
                                                    class={`sa-form-control ${formErrors.mobileNumber && 'sa-form-error'}`}
                                                    name="mobileNumber"
                                                    value={formValues.mobileNumber}
                                                    onChange={handleChange}
                                                    style={{ width: "280px", height: "39.18px", 'borderRadius': "5px" }}
                                                />
                                                {formErrors.mobileNumber && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.mobileNumber}</span>}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-form-group" style={{ 'display': 'flex', 'flexDirection': 'column' }}>

                                        <label htmlFor="e_mail" style={{ 'marginBottom': '5px' }}>Email</label>
                                        <input
                                            id="e_mail"
                                            type="text"
                                            class={`sa-form-control ${formErrors.email && 'sa-form-error'}`}
                                            name="email"
                                            value={formValues.email}
                                            onChange={handleChange}
                                            style={{ boxSizing: 'border-box', height: "39.18px", 'borderRadius': "5px" }}
                                        />

                                        {formErrors.email && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.email}</span>}
                                    </div>
                                </div>

                            </div>
                            <div className="sa-row" style={{ 'display': 'flex', 'flexDirection': 'row', 'paddingBottom': "20px" }}>
                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">

                                    <div className="sa-form-group" style={{ display: 'flex', flexDirection: 'column', paddingRight: "15px" }}>
                                        <label htmlFor="company_name" style={{ marginBottom: '5px' }}>Company Name</label>
                                        <input
                                            id="company_name"
                                            type="text"
                                            className={`sa-form-control ${formErrors.companyName && 'sa-form-error'}`}
                                            name="companyName"
                                            value={formValues.companyName}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', 'borderRadius': "5px" }}
                                        />
                                        {formErrors.companyName && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.companyName}</span>}
                                    </div>
                                </div>
                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-form-group" style={{ 'display': 'flex', 'flexDirection': 'column' }}>

                                        <label for="numberOfEmployees" style={{ 'marginBottom': '5px' }}>Number of Employees</label>
                                        <input
                                            id="numberOfEmployees"
                                            type="tel"
                                            class={`sa-form-control ${formErrors.numberOfEmployees && 'sa-form-error'}`}
                                            name="numberOfEmployees"
                                            value={formValues.numberOfEmployees}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', 'borderRadius': "5px" }}
                                        />
                                        {formErrors.numberOfEmployees && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.numberOfEmployees}</span>}
                                    </div>

                                </div>
                            </div>
                            <div className="sa-row" style={{ 'display': 'flex', 'flexDirection': 'row', 'paddingBottom': "20px", boxSizing: 'border-box' }}>
                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-form-group" style={{ display: 'flex', flexDirection: 'column', paddingRight: "15px" }}>
                                        <label htmlFor="company_city" style={{ marginBottom: '5px' }}>Company City</label>
                                        <input
                                            id="company_city"
                                            type="text"
                                            className={`sa-form-control ${formErrors.companyCity && 'sa-form-error'}`}
                                            name="companyCity"
                                            value={formValues.companyCity}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', 'borderRadius': "5px" }}
                                        />
                                        {formErrors.companyCity && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.companyCity}</span>}
                                    </div>
                                </div>


                                <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                    <div className="sa-form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="insurance_available" style={{ marginBottom: '5px' }}>
                                            Insurance Available
                                        </label>
                                        <select
                                            id="insurance_available"
                                            type="text"
                                            className="sa-form-control"
                                            name="insuranceAvailable"
                                            value={formData.insuranceAvailable}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', borderRadius: '5px' }}
                                        >
                                            <option value="" disabled>
                                                Please select an insurance
                                            </option>
                                            <option value="yes">MedGulf</option>
                                            <option value="no">Malath</option>
                                            <option value="partial">Al Rajhi TakaFul</option>
                                            <option value="full">Tawtniya</option>
                                            <option value="custom">BUPA</option>
                                        </select>
                                    </div>
                                </div>

                            </div>


                            <div className="sa-form-group">
                                <div style={{ 'display': 'grid', 'gridTemplateColumns': '1fr 5fr', 'columnGap': '20px', 'width': '6280px', 'height': 'auto' }}>
                                    <div className="sa-note-text" style={{ 'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center' }}>
                                        <label>
                                            Notes
                                            <span>(Optional)</span>
                                        </label>
                                        <span className="sa-note-count">
                                            <span className="sa-primary-text">0</span>/500 words
                                        </span>
                                    </div><br />
                                    <textarea id="comment" rows="4" className="sa-form-control sa-wellness-textarea" style={{ 'width': '100%', 'height': '100%', 'resize': 'none', 'borderRadius': "5px" }} maxlength="500" name="Comment"></textarea>
                                </div>
                            </div>
                            <br />


                            <div className="sa-captcha">
                                <label>Enter Captcha</label>
                                <div className="sa-row sa-gutter5">
                                    <div className="sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs6">
                                        <div className="sa-form-group">
                                            <input
                                                type="text"
                                                maxLength="5"
                                                className="sa-form-control sa-form-control-lg"
                                                id="captch"
                                                name="captcha"
                                                value={formValues.captcha}
                                                onChange={handleChange}
                                                style={{ 'borderRadius': "5px", height: "39.18px", 'width': "500px" }}
                                            />
                                            <br />
                                            {formErrors.captcha && (
                                                <span className="sa-error-message" style={{ color: "red" }}>
                                                    {formErrors.captcha}
                                                </span>
                                            )}
                                        </div>
                                    </div>


                                    <div className="sa-form-group d-flex justify-content-end">
                                        <h2 id="RandomCaptchaNum" style={{ fontSize: "40px", marginTop: "-10px" }}>{num}</h2>
                                        <button
                                            onClick={handleClick}
                                            type="button"
                                            className="sa-btn sa-btn-transparent ml-auto" style={{ "marginLeft": "320px" }}
                                        >
                                            <i className="icon-reload sa-otp-ref" style={{ fontSize: "35px" }}>

                                            </i>
                                        </button>
                                    </div>


                                </div>
                            </div>

                            <br />
                            <br />



                            <button className="fbtn" style={{ marginLeft: "400px", paddingLeft: "150px", paddingRight: "150px", backgroundColor: "#26a9e0", height: "60px", color: "white", fontSize: "20px", borderColor: "white", 'borderRadius': "15px" }}>Submit</button>

                        </form>
                    </div>
                </div>

            </div >
            <Footer />
        </div >
    )
}

export default RequestService
