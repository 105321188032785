export const sortTests = [
    {
        "id": "hormonal",
        "name": "Hormonal TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/hormonal-tests.png"
    },
    {
        "id": "medication",
        "name": "Level of  medication TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/level-medication-test.png"
    },
    {
        "id": "diabetes",
        "name": "Diabetes TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/diabetes-test.png"
    },
    {
        "id": "testother",
        "name": "Others",
        "image": "https://sanar-assets.com/images/lab-icons/tests/other.png"
    },
    {
        "id": "thyroid",
        "name": "Thyroid function TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/thyroid-function-test.png"
    },
    {
        "id": "autoimmune",
        "name": " Autoimmune diseases TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/autoimmune-diseases-test.png"
    },
    {
        "id": "electrolyte",
        "name": "Electrolyte in blood TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/electrolyte-blood-test.png"
    },
    {
        "id": "hematology",
        "name": "Hematology TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/hematology-test.png"
    },
    {
        "id": "liver",
        "name": "Liver function TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/liver-function-test.png"
    },
    {
        "id": "pancreatic",
        "name": "Pancreatic TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/pancreatic-test.png"
    },
    {
        "id": "bonedisorder",
        "name": "Bone Disorder TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/bone-disorder-test.png"
    },
    {
        "id": "vitaminsmineral",
        "name": "Vitamins & Minerals TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/vitamins-mineral-test.png"
    },
    {
        "id": "coagulation",
        "name": "Coagulation profile TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/coagulation-profile-test.png"
    },
    {
        "id": "renal",
        "name": "Renal function TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/renal-function-test.png"
    },
    {
        "id": "cardiac",
        "name": "Cardiac enzyme",
        "image": ""
    },
    {
        "id": "urinetest",
        "name": "Urine TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/urine-test.png"
    },
    {
        "id": "lipid",
        "name": "Lipid profile TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/lipid-profile-test.png"
    },    
    {
        "id": "pregnancy",
        "name": "Pregnancy Test",
        "image": ""
    },    
    {
        "id": "stool",
        "name": "Stool TEST",
        "image": "https://sanar-assets.com/images/lab-icons/tests/stool-test.png"
    },    
    {
        "id": "infectious",
        "name": "Infectious disease and inflammation Test",
        "image": "https://sanar-assets.com/images/lab-icons/tests/infectious-disease-inflamation-test.png"
    },
]