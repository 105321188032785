    import React, { useState } from 'react';
    import '../../Style/login_popup.css';

    function AccountSetup_details() {
        const [email, setEmail] = useState('');
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [formErrors, setFormErrors] = useState({});

        const validate = () => {
            const errors = {};

            if (!email.trim()) {
                errors.email = 'Email is required';
            }

            if (!username.trim()) {
                errors.username = 'Username is required';
            }

            if (!password.trim()) {
                errors.password = 'Password is required';
            }

            if (!confirmPassword.trim()) {
                errors.confirmPassword = 'Confirm Password is required';
            }

            return errors;
        };

        const handleSubmit = (event) => {
            event.preventDefault();

            setFormErrors({});

            const errors = validate();

            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
            } else if (password !== confirmPassword) {
                setFormErrors({ confirmPassword: "Passwords do not match" });
            } else {
                const formData = {
                    email: email,
                    password: password
                };

                fetch('https://zwarra.biztechnosys.com/api/register/step2?email=kishan40gmail.com&password=kishan123', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log(formData);
                        setEmail('');
                        setUsername('');
                        setPassword('');
                        setConfirmPassword('');
                    })
                    .catch(error => {
                        console.error('There was a problem with the request:', error);
                    });
            }
        };
        return (
            <div className='zw_popup'>
                <div className="modal fade" id="accountsetup_details" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">

                                <div className='btn-space'>
                                    <button className="sps-dialog-close regv2back" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="icon-close"></i>
                                    </button>
                                </div>
                                <div>
                                    <h6 className='poppins-semibold zw_text_color zw_32 mb-4' style={{ textAlign: "center" }}>Account Setup</h6>
                                </div>
                                <div className="container">
                                    <p className='poppins-regular zw_24 zw_9B9B9B mb-0'>
                                        Step 1 completed of 4
                                    </p>
                                    <div className="prog-grid1">
                                        <div className="prog-bar1" style={{ backgroundColor: "#DDE0E6" }}>
                                            <div className="prog-bar-per" style={{ width: "25%" }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container' style={{ backgroundColor: "#f7e9ec", padding: "20px", marginTop: "20px" }}>

                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group zw_form_group">
                                            <input type='text' className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='Email' style={{ width: "100%" }} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                                        </div>
                                        <div className="form-group zw_form_group">
                                            <input type='text' className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='Username' style={{ width: "100%" }} value={username} onChange={(e) => setUsername(e.target.value)}></input>
                                        </div>
                                        <div className="form-group zw_form_group">
                                            <input type='text' className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='Password' style={{ width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)}></input>
                                        </div>
                                        <div className="form-group zw_form_group">
                                            <input type='text' className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='Confirm Password' style={{ width: "100%" }} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></input>
                                        </div>
                                        <button type='submit' className='btn btn-primary btn-lg btn-block poppins-regular zw_bg_gradient zw_btn_18 zw_border_none' style={{ width: "80%", margin: " 0 10%" }} data-bs-toggle="modal" data-bs-target="#accountsetup_gender">
                                            Next
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    export default AccountSetup_details;
