import React from 'react'
import '../../Style/CareIndividuals.css'

function BiService() {
    return (
        <>
            <div className="z-service-bg z-service-ins-bg">
                <div className="container pos-rel">
                    <div className="z-mob-block sa-link-ins-bg">
                        <div className="col-6 col-md-6 col-sm-6 col-xs-12 z-link-insur">
                            <p className="z-add-ins sa-sprite sa-ins-icon">We accept Bupa, Tawuniya, MEDGULF, Malath and AlRajhi Takaful insurance for telemedicine
                                <span className="sa-link">To link your insurance login or sign up</span>
                            </p>
                        </div>
                    </div>
                    <div className="z-insur-ofr">
                        <h5 className="z-mob-block">Virtual Medical Services</h5>
                    </div>
                    <ul className="z-telemedicine-list z-mob-block">
                        <li>
                            <img src="https://sanar-assets.com/images/home-services/v5/telemedicine_new.svg" alt="services" title="Virtual Consultations" />
                            <div className="z-telemedicine-desc ms-2">
                                Virtual Consultations
                                <p>Book an instant virtual consultation with doctors in 25+ different specialties</p>
                            </div>
                        </li>
                    </ul>
                    <div className="sa-services">
                        <h1 className="z-banner">We are here to meet the needs of you and your family</h1>
                        <p className="z-banner-desc">An integrated hospital at your fingertips. Our services will be delivered at your home, in an easy, convenient and fast way.</p>
                        <div className="sa-pos-rel">
                            <div className="z-services-location" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <h5 className="z-mob-block">Home visit services</h5>
                                <span><i className="fa fa-map-marker px-2"></i> <small>Riyadh</small> <i className="fa fa-angle-down ms-2"></i></span>
                            </div>
                            <ul className="z-services-list">
                                <li className='virtual-consultations'>
                                    <img src="https://sanar-assets.com/images/home-services/v5/telemedicine_new.svg" alt="services" title="Virtual Consultations" />
                                    Virtual Consultations
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/lab.svg" alt="services" title="Laboratory" />
                                    Laboratory
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/home-visit-doctor.svg" alt="services" title="Home visit Doctor" />
                                    Home visit Doctor
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/nurse.svg" alt="services" title="Nurse visit" />
                                    Nurse visit
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/vitamin-drips.svg" alt="services" title="Vitamin IV Drips" />
                                    Vitamin IV Drips
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/caregiver.svg" alt="services" title="Caregiver" />
                                    Caregiver
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/physio.svg" alt="services" title="Physiotherapist" />
                                    Physiotherapist
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/vaccination.svg" alt="services" title="Vaccination" />
                                    Vaccination
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/radiology.svg" alt="services" title="Radiology" />
                                    Radiology
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/seasonal-flu-vaccination.svg" alt="services" title="Radiology" />
                                    Seasonal Flu <br></br>
                                    Vaccination
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="https://sanar-assets.com/images/home-services/v5/covid-pcr.svg" alt="services" title="Covid-19 PCR" />
                                    Covid-19 PCR
                                </li>
                                <li>
                                    <img src="https://sanar-assets.com/images/home-services/v5/wellness.svg" alt="services" title="Corporate Wellness" />
                                    Corporate Wellness
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="z-mob-none sa-health-ins sa-mob-none sa-row sa-link-ins-bg">
                        <div className="z-mob-none sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs12 sa-d-flex">
                            <img src="https://sanar-assets.com/images/log.png" alt="sanar licence" className="sa-avtar-sm z-mob-none" />
                            <p>Sanar is a licensed company by the Saudi Ministry of Health with License No.1400006807</p>
                        </div>
                        <div className="z-mob-none sa-col6 sa-col-md6 sa-col-sm6 sa-col-xs12 sa-link-insur">
                            <p className="sa-add-ins sa-sprite sa-ins-icon">We accept Bupa, Tawuniya, MEDGULF, Malath and AlRajhi Takaful insurance for telemedicine
                                <span>
                                    <strong className="sa-link">Link Your Insurance</strong>
                                    <i className="icon-arrow-right sa-cursor sa-mob-none">
                                    </i>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="row cols-md-4 mt-2">
                        <div className="bs-card">
                            <div className="wb-card b-card">
                                <div className="wb-treatment-card">
                                    <i className="wb-sprite-new wb-journey"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="wb-card-title"><b>Easy treatment journey</b></h5>
                                    <p className="wb-card-text"><small>Quick access to the doctors with different specialization. Text chat or video call, around the clock, with high privacy and confidentiality. Home visits and clinical examination without waiting in clinics for long hours</small></p>
                                </div>
                            </div>
                        </div>
                        <div className="bs-card">
                            <div className="wb-card b-card">
                                <div className="wb-treatment-card">
                                    <i className="wb-sprite-new ci-ele-cardio"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="wb-card-title"><b>Counseling and integrated care</b></h5>
                                    <p className="wb-card-text"><small>A suitable diagnosis of your condition, issuance of prescriptions and e medical reports, and dispensing medicines while you are at home.</small></p>
                                </div>
                            </div>
                        </div>
                        <div className="bs-card">
                            <div className="wb-card b-card">
                                <div className="wb-treatment-card">
                                    <i className="wb-sprite-new ci-doc-bag"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="wb-card-title"><b>Integrated treatment programmes</b></h5>
                                    <p className="wb-card-text"><small>Treatment plans include consultations, clinical and laboratory examinations periodically , with follow-up of your condition with a specialist doctor without the hassle of going to hospitals.</small></p>
                                </div>
                            </div>
                        </div>
                        <div className="bs-card">
                            <div className="wb-card b-card">
                                <div className="wb-treatment-card">
                                    <i className="wb-sprite-new wb-money-fill"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="wb-card-title"><b>Clear costs and great care</b></h5>
                                    <p className="wb-card-text"><small>Medical services at an affordable price with the possibility of quick linking with various insurance companies</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BiService
