import React from 'react'
import '../.././Style/Contact.css'
import Navbar from '../Layout/Navbar'
import '../.././Style/Navbar.css'
import Footer from '../Layout/Footer'
function Contactus() {
    return (
        <div>
            <Navbar />
            <span className="btn-back mob-block">
                <i className="icon-arrow-left"></i>
            </span>
            <h4 className="tit mob-header mob-block">Contact Us</h4>
            <div className="container" >
                <div className="cont-us top-space" >
                    <div class>
                        <div className="row gutter20" >
                            <div className="col-md-7 col-sm-7 col-xs-12" >
                                <div className="sa-cont-brdr sa-card-shadow" >
                                    <h2 className="header-body mob-none" style={{ 'margin': "20px" }}>Contact Us</h2>
                                    <p className="tit pb-10" style={{ 'marginLeft': "20px" }}>Do you have any questions? Just write us a message!</p>
                                    <div>
                                        <div className="sa-row sa-gutter10" style={{ 'display': "flex" }}>
                                            <div className="sa-col-6 sa-col-md-6 sa-col-sm-12 sa-col-xs-12" style={{ 'flex': "1" }}>
                                                <div className="sa-form-group" style={{ 'marginLeft': "20px" }}>
                                                    <label for="fullname">Full Name</label>
                                                    <input id="full_Name" type="text" placeholder="Full Name" className="sa-form-control" name="Full Name"></input>
                                                </div>
                                            </div>
                                            <div className="sa-col-6 sa-col-md-6 sa-col-sm-12 sa-col-xs-12" style={{ 'flex': "1" }}>
                                                <div className="sa-form-group" style={{ 'marginLeft': "20px" }}>
                                                    <label for="fname">E-mail ID</label>
                                                    <input id="email" type="text" placeholder="E-mail ID" className="sa-form-control" name="E-mail ID"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sa-ccode" style={{ 'margin': "20px" }}>
                                            <label>Phone Number</label>
                                            <div className="sa-row sa-gutter10" style={{ 'display': "flex" }}>
                                                <div className="sa-col-5 sa-col-md-5 sa-col-sm-5 sa-col-xs-5" style={{ 'flex': "1" }}>
                                                    <div className="sa-form-group" >
                                                        <img src="https://sanar-assets.com/flags/sa_64.png" alt='' className="sa-form-icon sa-form-img sa-img-ccode" style={{ 'marginTop': "-15px" }}></img>
                                                        <input type="text" id="phonecode" name="phonecode" readonly className="sa-form-control sa-ccode sa-inp-flag" ></input>
                                                        <i class="icon-down-arrow sa-form-icon" ></i>
                                                    </div>
                                                </div>

                                                <div class="sa-col7 sa-col-md-7 sa-col-sm-7 sa-col-xs-7" style={{ 'flex': "1" }}>
                                                    <div class="sa-form-group">
                                                        <input id="mobile number" type="tel" placeholder="Mobile Number" class="sa-form-control sa-ccode" name="Mobile Number"></input>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="sa-form-group " style={{ 'margin': "20px" }} >
                                            <label for="subject" >Subject</label>
                                            <input id="write_sub" type="text" placeholder="Write the subject" class="sa-form-control" name="Write the subject" style={{ 'width': "620px", 'height': "40px" }} />

                                        </div>

                                        <div class="sa-form-group" style={{ 'margin': "20px" }}>
                                            <label>Write your comments</label>
                                            <textarea id="comment" row="10" placeholder="Write your comments" class="sa-form-control" name="Comment" style={{ 'width': "620px", 'height': "100px" }}></textarea>
                                        </div>
                                        <button class="sa-btn-primary sa-w100 sa-btn-lg" style={{ 'margin': "20px", 'width': "620px", 'height': "40px" }} >Send</button>
                                    </div>
                                </div>
                            </div>
                            <div class="sa-col5 sa-col-md-5 sa-col-sm-5 sa-col-xs-12 sa-pl-40 sa-contact-link" style={{ 'marginTop': "100px", 'flex': "1", 'width': "538px", 'height': "358px" }}>

                                <div class="sa-card" style={{ 'width': "330px", 'height': "550px" }}>
                                    <div class="sa-icon-bg">
                                        <i class="icon-call-center-agent sa-c-care sa-icon-sub-bg">

                                        </i>

                                    </div>
                                    <h6 class="sa-header-body" style={{ 'marginLeft': "20px" }}>ZwaaraAddress</h6>
                                    <p class="sa-mb-10 sa-header-body" style={{ 'marginLeft': "10px" }} >
                                        <i class="icon-location21 sa-mr-10" style={{ 'margin': "10px" }}>

                                        </i>
                                        Riyadh
                                    </p>
                                    <h6 class="sa-header-body" style={{ 'margin': "20px" }}>Contact Number</h6>
                                    <span class="sa-mr-20 sa-cont-num sa-d-i-dir-ltr">
                                        <i class="icon-call sa-header-body" style={{ 'marginLeft': "20px" }}>

                                        </i>
                                        <a class="sa-header-body" target="" href="https://api.whatsapp.com/send?phone=966554420580&text=">+966 554420580</a>
                                    </span>
                                    <span class="sa-cont-num sa-d-i-dir-ltr">
                                        <i class="icon-call sa-header-body" style={{ 'marginLeft': "20px" }}>

                                        </i>
                                        <a class="sa-header-body" target="" href="https://api.whatsapp.com/send?phone=966554420580&text=">+966 554420580</a>
                                    </span>
                                    <h6 class="sa-mt-10 sa-header-body" style={{ 'margin': "20px" }}>General enquiries </h6>
                                    <a target="" rel="noopener" href="mailto:'customercare@sanar.sa'" style={{ 'margin': "20px" }}>customercare@sanar.sa</a>
                                    <h6 class="sa-mt10 sa-header-body" style={{ 'margin': "20px" }}>Hospitals and doctors inquiries</h6>
                                    <a target="" rel="noopener" href="mailto:'support@sanar.sa'" style={{ 'margin': "20px" }}>support@sanar.sa</a>
                                    <h6 class="sa-mt10 sa-header-body" style={{ 'margin': "20px" }}>For services</h6>
                                    <a href="mailto:services@sanar.sa.com?Subject=Hello%20again" target="_top" style={{ 'margin': "20px" }}>services@sanar.sa</a>
                                    <h6 class="sa-mt10 sa-header-body" style={{ 'margin': "20px" }}>Technical support</h6>
                                    <a href="mailto:services@sanar.sa.com?Subject=Hello%20again" target="_top" style={{ 'margin': "20px" }}>Technicalsupport@sanar.sa</a>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contactus
