import React, { useContext, useState } from 'react'
import '../../../../Style/Laboratory.css'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { bonePackages } from '../ViewAllPackages/ViewAllPackageList'
import { boneTest } from '../ViewAllTests/ViewAllTestsList'
import { Context } from '../ContextProvider'
import { Modal } from 'react-bootstrap'

function Bone() {
    const [search, setSearch] = useState('');
    const [showMorePackage, setShowMorePackage] = useState(false);
    const [showMoreTest, setShowMoreTest] = useState(false);
    const navigate = useNavigate();

    const packageData = showMorePackage ? bonePackages : bonePackages.slice(0, 3);
    const testData = showMoreTest ? boneTest : boneTest.slice(0, 2);


    //Package
    const [packageShow, setPackageShow] = useState(false);
    const { selectedPackage, setSelectedPackage } = useContext(Context);
    const [packagePageName, setPackagePageName] = useState('');
    const [packageNum, setPackageNum] = useState(0);

    const handlePackageShow = (e, id) => {
        setPackageShow(true);
        setPackagePageName(id);
        setPackageNum(0);
        selectedPackage.forEach(element => {
            if (element.id === id) {
                setPackageNum(element.count);
            }
        });
    }

    const handlePackageClose = () => {
        setPackageShow(false);
    }

    const handlePackageSelection = (e) => {
        e.count = packageNum;
        if (selectedPackage.find((elem) => elem.id === e.id) === undefined) {
            selectedPackage.push(e);
            setSelectedPackage(selectedPackage);
        }
        else {
            const updatePackageObject = selectedPackage.map((packages) => {
                if (packages.id === e.id) {
                    packages.count = packageNum;
                }
                return packages;
            })
            setSelectedPackage(updatePackageObject);
        }
    }

    const incPackageNum = () => {
        if (packageNum <= 50) {
            setPackageNum(Number(packageNum) + 1);
        }
    };

    const decPackageNum = () => {
        if (packageNum > 0) {
            setPackageNum(packageNum - 1);
        }
    };

    const setPackageItemSelectedCount = (e, isInc) => {
        const updatePackageObject = selectedPackage.map((packages) => {
            if (packages.id === e.id) {
                if (isInc) {
                    if (e.count <= 50) {
                        packages.count += 1;
                    }
                }
                else {
                    if (e.count > 0) {
                        if (packages.id === e.id) {
                            packages.count -= 1;
                        }
                    }
                }
            }
            return packages;
        })
        setSelectedPackage(updatePackageObject);
    }

    const incPackageNumSide = (e) => {
        setPackageItemSelectedCount(e, true);
    };

    const decPackageNumSide = (e) => {
        setPackageItemSelectedCount(e, false);
    };

    const handlePackageNum = (e, id) => {
        setPackageNum(e.target.value);
        setPackagePageName(id);
    };


    //Test
    const [testShow, setTestShow] = useState(false);
    const { selectedTest, setSelectedTest } = useContext(Context);
    const [testPageName, setTestPageName] = useState('');
    const [testNum, setTestNum] = useState(0);

    const handleTestShow = (e, id) => {
        setTestShow(true);
        setTestPageName(id);
        setTestNum(0);
        selectedTest.forEach(element => {
            if (element.id === id) {
                setTestNum(element.count);
            }
        });
    }

    const handleTestClose = () => {
        setTestShow(false);
    }

    const handleTestSelection = (e) => {
        e.count = testNum;
        if (selectedTest.find((elem) => elem.id === e.id) === undefined) {
            selectedTest.push(e);
            setSelectedTest(selectedTest);
        }
        else {
            const updateTestObject = selectedTest.map((test) => {
                if (test.id === e.id) {
                    test.count = testNum;
                }
                return test;
            })
            setSelectedTest(updateTestObject);
        }
    }

    const incTestNum = () => {
        if (testNum <= 50) {
            setTestNum(Number(testNum) + 1);
        }
    };

    const decTestNum = () => {
        if (testNum > 0) {
            setTestNum(testNum - 1);
        }
    };

    const setTestItemSelectedCount = (e, isInc) => {
        const updateTestObject = selectedTest.map((test) => {
            if (test.id === e.id) {
                if (isInc) {
                    if (e.count <= 50) {
                        test.count += 1;
                    }
                }
                else {
                    if (e.count > 0) {
                        if (test.id === e.id) {
                            test.count -= 1;
                        }
                    }
                }
            }
            return test;
        })
        setSelectedTest(updateTestObject);
    }

    const incTestNumSide = (e) => {
        setTestItemSelectedCount(e, true);
    };

    const decTestNumSide = (e) => {
        setTestItemSelectedCount(e, false);
    };

    const handleTestNum = (e, id) => {
        setTestNum(e.target.value);
        setTestPageName(id);
    };

    const handleClick = () => {
        navigate('/browse/4/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04');
    }

    return (
        <>
            <Navbar />

            <div className='container'>
                <Link to='/laboratory/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04'>
                    <span className='body-btn-back'>
                        <i className='icon-arrow-left'></i>
                    </span>
                </Link>
                <div className='z-body-progress'>
                    <p>
                        <span> Step 1 </span> <span className='body-prog-step' style={{ color: '#000', marginLeft: '50px' }}> of 4</span>
                    </p>
                    <div className="progress z-body-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
                        <div className="progress-bar body-prog-bar-per" style={{ width: '25%' }}></div>
                    </div>
                </div>

                <div className='row z-test-function'>
                    <div className='col-8 col-md-8 col-sm-8 col-xs-12'>
                        <div className='z-body-test-type'>
                            <Link to='/laboratory/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04'>
                                <span>
                                    <i className='icon-left-arrow'></i>
                                </span>
                            </Link>
                            <h4> Bone </h4>
                            <i className="icon-search"></i>
                            <input onChange={(e) => setSearch(e.target.value)} autoComplete="off" name="Search Package or Test" type="text" placeholder="Search" className="form-control body-form-control" />
                        </div>

                        <div>
                            <div className='body-packages-title'>
                                <h3> Packages </h3>
                            </div>
                            <div className='lab-body-scroll'>
                                <ul className='body-pack-card-list'>
                                    {packageData.filter((item) => {
                                        return search.toLowerCase() === '' ? item : item.packageTitle.toLowerCase().includes(search)
                                    }).map((item, index) => (
                                        <li key={index} onClick={(e) => handlePackageShow(e, item.id)}>
                                            <h4> {item.packageTitle} </h4>
                                            <div className='lab-body-test-bg'>
                                                <button>
                                                    <span> Starting </span> {item.packagePrice} SAR
                                                </button>
                                                <figure className='lab-body-img'>
                                                    <img src={item.packageImage} alt='img' />
                                                </figure>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {bonePackages.length <= 3 ? null :
                            <p className='body-more-result' onClick={() => setShowMorePackage(!showMorePackage)}>
                                {showMorePackage ? "Show less package results" : "Show more package results"}
                                <i className='icon-down-arrow'></i>
                            </p>
                        }

                        <div>
                            <div className='body-packages-title'>
                                <h3> Individual Tests </h3>
                            </div>
                            <div className='lab-body-scroll'>
                                <ul className='body-test-card-list'>
                                    {testData.filter((item) => {
                                        return search.toLowerCase() === '' ? item : item.testName.toLowerCase().includes(search)
                                    }).map((item, index) => (
                                        <li key={index} onClick={(e) => handleTestShow(e, item.id)}>
                                            <div className='body-between'>
                                                <h4> {item.testName} </h4>
                                            </div>
                                            <div className='lab-body-test-bg'>
                                                <button>
                                                    <span> Starting </span> {item.testPrice} SAR
                                                </button>
                                                <i className='icon-test-pack'></i>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {boneTest.length <= 2 ? null :
                            <p className='body-more-result' onClick={() => setShowMoreTest(!showMoreTest)}>
                                {showMoreTest ? "Show less Test results" : "Show more Test results"}
                                <i className='icon-down-arrow'></i>
                            </p>
                        }
                    </div>

                    <div className='col-4 col-md-4 col-sm-4 col-xs-12'>
                        <h4> Selected Items </h4>
                        <div className='z-selected-data'>
                            <div className='z-selected-item'>
                                {selectedPackage.map((item, index) => (
                                    <div className='z-selected-cards' key={index}>
                                        <div className='z-d-f-sbetween'>
                                            <span className='z-lab-card'>
                                                <i className='icon-package2'></i>
                                                <strong id={`selected_${item.id}`}> {item.packageTitle} </strong>
                                                <small className='z-tst-incl'> {item.totalTest} test incl </small>
                                            </span>
                                            <div className='z-count'>
                                                <button className='z-symbol' onClick={() => decPackageNumSide(item)}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <strong className='z-slct-count' id={item.id} onChange={(e) => handlePackageNum(e, item.id)}> {item.count} </strong>
                                                <button className='z-symbol' onClick={() => incPackageNumSide(item)}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {selectedTest.map((item, index) => (
                                    <div className='z-selected-cards' key={index}>
                                        <div className='z-d-f-sbetween'>
                                            <span className='z-lab-card'>
                                                <i className='icon-test-pack'></i>
                                                <strong id={`selected_${item.id}`}> {item.testName} </strong>
                                            </span>
                                            <div className='z-count'>
                                                <button className='z-symbol' onClick={() => decTestNumSide(item)}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <strong className='z-slct-count' id={item.id} onChange={(e) => handleTestNum(e, item.id)}> {item.count} </strong>
                                                <button className='z-symbol' onClick={() => incTestNumSide(item)}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {selectedPackage.length === 0 && selectedTest.length === 0 ?
                                <div className='z-item-btns' style={{ display: 'flex' }} >
                                    <button style={{ backgroundColor: "#c2c9e1", cursor: "not-allowed" }} disabled> Continue </button>
                                </div>
                                :
                                <div className='z-item-btns' onClick={() => handleClick()} style={{ display: 'flex', color: "#FFF" }}>
                                    <button style={{ backgroundColor: "#26A9E0" }}> Continue </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {/* Package Modal */}

            {bonePackages.map((item, index) => (
                packagePageName.includes(item.id) && (
                    <Modal show={packageShow} onHide={handlePackageClose} key={index} style={{ '--bs-modal-width': '600px' }}>
                        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <div>
                                <Modal.Header>
                                    <h4 className='title' style={{ marginBottom: "-15px" }}>
                                        {item.packageTitle}
                                    </h4>
                                    <button className="z-lab-btn" onClick={handlePackageClose} style={{ marginLeft: '570px', marginTop: '-52px' }}>
                                        <i className="icon-close"></i>
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='z-d-f-sbetween'>
                                        <div className='z-count z-lab-count'>
                                            <button className='z-symbol' onClick={() => decPackageNum(item.id)}>
                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                            <strong className='z-slct-count' onChange={(e) => handlePackageNum(e, item.id)}> {packageNum} </strong>
                                            <button className='z-symbol' onClick={() => incPackageNum(item.id)}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div className='z-select'>
                                            <Link onClick={handlePackageClose}>
                                                <button onClick={() => handlePackageSelection(item)}>
                                                    Select
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <p className='package-popup-desc'>
                                        {item.packageDesc}
                                    </p>

                                    <div className='package-info'>
                                        <h6> Instructions </h6>
                                        <ul className='instruction-list'>
                                            <li>
                                                <span className='package-instraction'>
                                                    {item.instruction1}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <h6 className='package-test-title'>
                                        <strong>
                                            <span> {item.totalTest} </span>
                                            Test Included in this Package
                                        </strong>
                                    </h6>

                                    <ul className='package-test-list'>
                                        {item.test1 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test1}
                                            </li>
                                        }
                                        {item.test2 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test2}
                                            </li>
                                        }
                                        {item.test3 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test3}
                                            </li>
                                        }
                                        {item.test4 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test4}
                                            </li>
                                        }
                                        {item.test5 === "" ? null :
                                            <li className='package-test-list-item'>
                                                <i className='icon-test-pack'></i>
                                                {item.test5}
                                            </li>
                                        }
                                    </ul>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>
                )
            ))}

            {/* Test Modal */}

            {boneTest.map((item, index) => (
                testPageName.includes(item.id) && (
                    <Modal key={index} show={testShow} onHide={handleTestClose} style={{ '--bs-modal-width': '600px' }} centered>
                        <div>
                            <div>
                                <Modal.Header>
                                    <h1 className="modal-title fs-5">{item.testName}</h1>
                                    <button className="z-lab-btn" onClick={handleTestClose} style={{ marginLeft: '570px', marginTop: '-44px' }}>
                                        <i className="icon-close"></i>
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='z-d-f-sbetween'>
                                        <div className='z-count z-lab-count'>
                                            <button className='z-symbol' onClick={() => decTestNum(item.id)}>
                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                            <strong className='z-slct-count' onChange={(e) => handleTestNum(e, item.id)}> {testNum} </strong>
                                            <button className='z-symbol' onClick={() => incTestNum(item.id)}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div className='z-select'>
                                            <Link onClick={handleTestClose}>
                                                <button onClick={() => handleTestSelection(item)}>
                                                    Select
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='z-test-card'>
                                        <ul>
                                            <li>
                                                <strong> Sample </strong>
                                                <div className='test-card-item'>
                                                    <figure>
                                                        <img src='https://sanar-assets.com/images/lab-icons/v5/blood.png' alt='Sample' className='z-img-cover' />
                                                    </figure>
                                                    <span> Blood </span>
                                                </div>
                                            </li>
                                            <li>
                                                <strong> Instructions </strong>
                                                <ul>
                                                    <li>
                                                        This test does not require fasting.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>
                )
            ))}
        </>
    )
}

export default Bone
