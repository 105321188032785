import React from 'react'
import '../../Style/Join_button.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer'
import { NavLink } from 'react-router-dom';

function Join_button() {

    return (
        <>
            <Navbar />
            <div className="container glob-prgh top-space">
                <div className="card-shadow service-prov" style={{top: "77px",
    marginBottom: "115px"}}>
                    <span className="btn-back mob-block">
                        <i className="icon-arrow-left"></i>
                    </span>
                    <h4 className="tit mob-header mob-block">Sanar Request form</h4>
                    <div className="text-center">
                        <h3 className="h3">Provide my services via Sanar Request form</h3>
                        <p> We are pleased to cooperate with us in providing and facilitating access to your services Via Sanar platform</p>
                    </div>
                    <ul className="track-order">
                        <li className="packed">
                            <span className="track-dot fill-dot"></span>
                        </li>
                        <li className="isa-delivered">
                            <span className="track-dot"></span>
                            <span className="track-dot"></span>
                        </li>
                        <li className="isa-delivered">
                            <span className="track-dot"></span>
                            <span className="track-dot"></span>
                        </li>
                    </ul>
                    <h3>Provide your services as</h3>
                    <ul className="slct-card">
                        <li className='nav-item'>
                            <NavLink aria-current="page" className='nav-link' to="/doctorspecialist">
                                <i className="sprite-new doc-spe"></i>Doctor / specialist
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className='nav-link' to="/hospitalhealthcenter">
                                <i className="sprite-new hos-cen"></i>Hospital or health center
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className='nav-link' to="/insuarancecompany">

                                <i className="sprite-new in-company"></i>Insurance company
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Join_button