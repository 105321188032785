import React, { useContext, useEffect, useState } from 'react'
import '../../../../Style/Laboratory.css'
import Navbar from '../../../Layout/Navbar'
import Footer from '../../../Layout/Footer'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { sortTests } from './TestsSortList'
import { tests } from './ViewAllTestsList'
import { Context } from '../ContextProvider'
import { Modal } from 'react-bootstrap'

function ViewAllTests() {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const slider = document.querySelector(".z-lab-carousel");
        let isDown = false;
        let startX;
        let scrollLeft;

        slider?.addEventListener("mousedown", e => {
            isDown = true;
            slider.classList.add("active");

            const pageX = e.pageX;
            startX = pageX - slider.offsetLeft;

            scrollLeft = slider.scrollLeft;
        });
        slider?.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider?.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider?.addEventListener("mousemove", e => {
            if (!isDown) return;

            e.preventDefault(); //stop any weird stuff
            const x = e.pageX - slider.offsetLeft;

            const deviation = x - startX;
            slider.scrollLeft = scrollLeft - deviation;
        });

        const carouselSlider = document.querySelector("[data-slider]");

        const track = carouselSlider.querySelector("[data-slider-track]");
        const prev = carouselSlider.querySelector("[data-slider-prev]");
        const next = carouselSlider.querySelector("[data-slider-next]");

        if (track) {
            prev?.addEventListener("click", () => {
                next.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft - track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            next?.addEventListener("click", () => {
                prev.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft + track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            track?.addEventListener("scroll", () => {
                const trackScrollWidth = track.scrollWidth;
                const trackOuterWidth = track.clientWidth;

                prev.removeAttribute("disabled");
                next.removeAttribute("disabled");

                if (track.scrollLeft <= 0) {
                    prev.setAttribute("disabled", "");
                }

                if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
                    next.setAttribute("disabled", "");
                }
            });
        };
    });

    const [num, setNum] = useState(0);

    const incNum = () => {
        if (num <= 50) {
            setNum(Number(num) + 1);
        }
    };

    const decNum = () => {
        if (num > 0) {
            setNum(num - 1);
        }
    };

    const setTestItemSelectedCount = (e, isInc) => {
        const updateObject = selectedTest.map((test) => {
            if (test.id === e.id) {
                if (isInc) {
                    if (e.count <= 50) {
                        test.count += 1;
                    }
                }
                else {
                    if (e.count > 0) {
                        if (test.id === e.id) {
                            test.count -= 1;
                        }
                    }
                }
            }
            return test;
        });
        setSelectedTest(updateObject);
    }

    const incNumSide = (e) => {
        setTestItemSelectedCount(e, true);
    }

    const decNumSide = (e) => {
        setTestItemSelectedCount(e, false);
    }

    const handleNum = (e, id) => {
        setNum(e.target.value);
        setTestPageName(id);
    };

    const { selectedTest, setSelectedTest } = useContext(Context);
    const [testShow, setTestShow] = useState(false);
    const [testPageName, setTestPageName] = useState('');

    const handleTestShow = (e, id) => {
        e.preventDefault();
        setTestShow(true);
        setTestPageName(id);
        setNum(0);
        selectedTest.forEach(element => {
            if (element.id === id) {
                setNum(element.count);
            }
        });
    }

    const handleTestClose = () => {
        setTestShow(false);
    }

    const handleTestSelection = (e) => {
        e.count = num;
        if (selectedTest.find((elem) => elem.id === e.id) === undefined) {
            selectedTest.push(e);
            setSelectedTest(selectedTest);
        }
        else {
            const updateObject = selectedTest.map((test) => {
                if (test.id === e.id) {
                    test.count = num;
                }
            })
            setSelectedTest(updateObject);
        }
    };

    const handleClick = () => {
        navigate('/browse/4/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04');
    }

    return (
        <>
            <Navbar />

            <div className='z-container'>
                <NavLink to='/laboratory/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04'>
                    <span className='lab-pack-btn-back'>
                        <i className='icon-arrow-left'></i>
                    </span>
                </NavLink>

                <div className='z-package-progress'>
                    <p>
                        <span> Step 1 </span> <span className='package-prog-step' style={{ color: '#000', marginLeft: '50px' }}> of 4</span>
                    </p>
                    <div className="progress z-package-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
                        <div className="progress-bar lab-package-bar-per" style={{ width: '25%' }}></div>
                    </div>
                </div>

                <div className='row z-package-gutter20'>
                    <div className='col-8 col-md-8 col-sm-8 col-xs-12'>
                        <div className='z-test-type'>
                            <NavLink to='/laboratory/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04'>
                                <span className='select-btn-back'>
                                    <i className='icon-left-arrow'></i>
                                </span>
                            </NavLink>
                            <h4 className='z-line-clamp'> Select Test Type </h4>
                            <i className='icon-search z-test-form-icon'></i>
                            <input onChange={(e) => setSearch(e.target.value)} name="search" type="text" placeholder="Search" className="form-control" required="" autoComplete="off"></input>
                        </div>

                        <div className='z-lab-scrl'>
                            <div className='z-lab-viw-slider' data-slider>
                                <ul className='z-lab-carousel' data-slider-track>
                                    <li className='z-lab-filter' id='all'>
                                        <div className='z-lab-item active'> All </div>
                                    </li>
                                    {sortTests.map((item, index) => (
                                        <li key={index} id={item.id}>
                                            <div className='z-lab-item'>
                                                {item.image === "" ? null : <img src={item.image} alt='img' />}
                                                {item.name}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <button type='button' name='prev button' className='z-carousel-prev icon-left-arrow' data-slider-prev></button>
                                <button type='button' name='next button' className='z-carousel-next icon-right-arrow' data-slider-next></button>
                            </div>
                        </div>

                        <div>
                            <div className='z-package-title'>
                                <h3>
                                    Individual Tests
                                </h3>
                            </div>

                            <div className='z-lab-package-scroll'>
                                <ul className='z-lab-tech-bg z-package-list'>
                                    {tests.filter((item) => {
                                        return search.toLowerCase() === '' ? item : item.testName.toLowerCase().includes(search)
                                    }).map((item, index) => (
                                        <li key={index}>
                                            <div className='z-lab-test-title' onClick={(e) => handleTestShow(e, item.id)}>
                                                <h4> {item.testName} </h4>
                                                <button> Select </button>
                                            </div>
                                            <div className='z-lab-test-bg'>
                                                <button>
                                                    <span> Starting </span> {item.testPrice} SAR
                                                </button>
                                                <i className='icon-test-pack'></i>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-4 col-md-4 col-sm-4 col-xs-12 z-lab-pack-slct'>
                        <h4> Selected Items </h4>
                        <div className='z-selected-data'>
                            <div className='z-selected-item'>
                                {selectedTest.map((item, index) => (
                                    <div className='z-selected-cards' key={index}>
                                        <div className='z-d-f-sbetween'>
                                            <span className='z-lab-card'>
                                                <i className='icon-test-pack'></i>
                                                <strong id={`selected_${item.id}`}> {item.testName} </strong>
                                            </span>
                                            <div className='z-count'>
                                                <button className='z-symbol' onClick={() => decNumSide(item)}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <strong className='z-slct-count' id={item.id} onChange={(e) => handleNum(e, item.id)}> {item.count} </strong>
                                                <button className='z-symbol' onClick={() => incNumSide(item)}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {selectedTest.length === 0 ?
                                <div className='z-item-btns' style={{ display: 'flex' }} >
                                    <button style={{ backgroundColor: "#c2c9e1", cursor: "not-allowed" }} disabled> Continue </button>
                                </div>
                                :
                                <div className='z-item-btns' onClick={() => handleClick()} style={{ display: 'flex', color: "#FFF" }}>
                                    <button style={{ backgroundColor: "#26A9E0" }}> Continue </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {tests.map((item, index) => (
                testPageName.includes(item.id) && (
                    <Modal show={testShow} onHide={handleTestClose} style={{ '--bs-modal-width': '600px' }}>
                        <div>
                            <div>
                                <Modal.Header>
                                    <h1 className="modal-title fs-5">{item.testName}</h1>
                                    <button className="z-lab-btn" onClick={handleTestClose} style={{ marginLeft: '570px', marginTop: '-44px' }}>
                                        <i className="icon-close"></i>
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='z-d-f-sbetween'>
                                        <div className='z-count z-lab-count'>
                                            <button className='z-symbol' onClick={() => decNum(item.id)}>
                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                            <strong className='z-slct-count' onChange={(e) => handleNum(e, item.id)}> {num} </strong>
                                            <button className='z-symbol' onClick={() => incNum(item.id)}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div className='z-select'>
                                            <Link onClick={handleTestClose}>
                                                <button onClick={() => handleTestSelection(item)}>
                                                    Select
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='z-test-card'>
                                        <ul>
                                            <li>
                                                <strong> Sample </strong>
                                                <div className='test-card-item'>
                                                    <figure>
                                                        <img src='https://sanar-assets.com/images/lab-icons/v5/blood.png' alt='Sample' className='z-img-cover' />
                                                    </figure>
                                                    <span> Blood </span>
                                                </div>
                                            </li>
                                            <li>
                                                <strong> Instructions </strong>
                                                <ul>
                                                    <li>
                                                        This test does not require fasting.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>
                )
            ))}
        </>
    )
}

export default ViewAllTests
