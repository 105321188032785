import React, { useState } from "react";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import '../../../Style/NurseVisit.css';
import 'react-datepicker/dist/react-datepicker.css';
import NurseVisitContinueList from '../NurseVisitTab/NurseVisitContinueList.js';
import { nursevisitcontinuedata } from '../NurseVisitTab/NurseVisitContinueData.js';
import Modal from 'react-bootstrap/Modal';

function NurseVisitContinue() {
  const [startDate, setstartDate] = useState(new Date());
  const [search, setSearch] = useState('');
  const [, setProducts] = useState(nursevisitcontinuedata);
  const [sortBy, setSortBy] = useState(null);
  const [show, setShow] = useState(false);

  const openDatePicker = () => {
    document.querySelector('.hvd-custom-input').click();
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    setSortBy(value);

    if (value === 'high') {
      setProducts(nursevisitcontinuedata.sort((a, b) => b.price - a.price));
    }
    else if (value === 'low') {
      setProducts(nursevisitcontinuedata.sort((a, b) => a.price - b.price));
    }
  }


  const openModel = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="z-browser">
          <div className="z-browser-header">

              <Link to='/nurse/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04' className="z-btn-back">
                <i className="icon-arrow-left"></i>
                <span className="z-mob-none"> Back </span>
              </Link>

              <div className="z-srch-field z-mob-none">
                <i className="icon-search z-form-icon z-form-icon-left"></i>
                <input onChange={(e) => setSearch(e.target.value)} autoComplete="off" name="Search Doctor" type="text" placeholder="Search doctor or hospitaly" className="form-control z-form-control-sm" />
              </div>

              <div className="horizontal-calendar">
                <div className="z-date-list" style={{ scrollBehavior: 'auto' }}>
                  <div className="z-date-list-scroll">
                    <div className="z-date-item z-choosed-day">
                      <div>
                        <p className="z-today"> Today </p>
                        <p className="z-date-item-date"> 8 </p>
                      </div>
                    </div>
                    <div className="z-date-item">
                      <div>
                        <p className="z-date-item-day"> Thu </p>
                        <p className="z-date-item-date"> 9 </p>
                      </div>
                    </div>
                    <div className="z-date-item">
                      <div>
                        <p className="z-date-item-day"> Fri </p>
                        <p className="z-date-item-date"> 10 </p>
                      </div>
                    </div>
                    <div className="z-date-item">
                      <div>
                        <p className="z-date-item-day"> Sat </p>
                        <p className="z-date-item-date"> 11 </p>
                      </div>
                    </div>
                    <div className="z-date-item">
                      <div>
                        <p className="z-date-item-day"> Sun </p>
                        <p className="z-date-item-date"> 12 </p>
                      </div>
                    </div>
                    <div className="z-date-item">
                      <div>
                        <p className="z-date-item-day"> Mon </p>
                        <p className="z-date-item-date"> 13 </p>
                      </div>
                    </div>
                    <div className="z-date-item">
                      <div>
                        <p className="z-date-item-day"> Tue </p>
                        <p className="z-date-item-date"> 14 </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="z-date-picker">
                <DatePicker className="hvd-custom-input hvd-react-datepicker" selected={startDate} onChange={(date) => setstartDate(date)} />
                <i className="fa fa-calendar z-cal" aria-hidden="true" onClick={openDatePicker}
                  style={{ cursor: 'pointer' }}></i>
              </div>

            
            <div className="container">
              <div className="z-doc-prog">
                <div className='z-doc-progress'>
                  <p>
                    <span> Step 2 </span> <span className='z-prog-step' style={{ color: 'black', marginLeft: '50px' }}> of 4 </span>
                  </p>
                  <div className="progress z-doc-prog-bar" role="progressbar" aria-label="Example 5px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '5px' }}>
                    <div className="progress-bar z-prog-bar-per" style={{ width: '50%' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container z-result">
            <div className="row">
              <div className="col-4 col-md-4 col-sm-4 col-xs-12">
                <div className="z-filter-data z-mob-none">
                  <div className="z-filter">
                    <h4 className="z-filter-title"> Filters </h4>

                    <div className="z-filter-list z-mob-none">
                      <h5 className="z-filter-title z-primary-title"> Price </h5>
                      <div className="form-check">
                        <input className="form-check-input" value='low' type="radio" checked={sortBy === 'low'} onChange={handleSortChange} name="exampleRadios" id="exampleRadios1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          Low to High
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" value='high' type="radio" checked={sortBy === 'high'} onChange={handleSortChange} name="exampleRadios" id="exampleRadios1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          High to Low
                        </label>
                      </div>
                    </div>

                    <div className="z-filter-list z-Department">
                      <h5 className="z-filter-title z-primary-title"> Select Gender </h5>
                      <div className="form-check">
                        <input className="form-check-input" value='low' type="radio" name="exampleRadios" id="exampleRadios1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          Any
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" value='high' type="radio" name="exampleRadios" id="exampleRadios1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          Male
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" value='high' type="radio" name="exampleRadios" id="exampleRadios1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-8 col-md-8 col-sm-8 col-xs-12">
                <h4 className="z-filter-title z-mob-none"> Select Center </h4>
                <div className="hvd-input-group hvd-location-label" onClick={() => openModel()}>
                  <button className="hvd-btn hvd-ellipsis">
                    Selected Location
                  </button>
                  |
                  <i className="icon-location2"></i>
                  <input type="text" placeholder="location" className="hvd-form-control hvd-ellipsis hvd-cursor" readOnly></input>
                  <i className="icon-down-arrow hvd-form-icon"></i>
                </div>
                <ul className="z-doctor-card-list">
                  {nursevisitcontinuedata.filter((item) => {
                    return search.toLowerCase() === '' ? item : item.doctorName.toLowerCase().includes(search)
                  }).map((item) => (
                    <li key={item.id}>
                      <NurseVisitContinueList
                        doctorImage={item.doctorImage}
                        doctorName={item.doctorName}
                        doctorDesig={item.doctorDesig}
                        currency={item.currency}
                        price={item.price}
                        includefees={item.includefees}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal show={show} onHide={handleClose} animation={false}>
        <div className="sps-dialog sps-dialog-full">
          <div className="sps-dialog-body sps-dialog-overflow-unset sps-map">
            <button className="sps-dialog-close" onClick={handleClose}>
              <i className="icon-close"></i>
            </button>
            <div className="sps-loc-map">
              <div id="spsmapbox" className="sps-mapboxgl-map" >
                <div style={{ height: "500px", width: "100%" }}>
                  {/* <GoogleMapReact
                                            defaultCenter={{ lat: 20.9471, lng: 72.8990 }}
                                            defaultZoom={15}
                                        /> */}
                </div>
                <header className="sps-loc-header">
                  <button type="submit" className="sps-btn sps-loc-crnt-btn icon-current-location"></button>
                  <div className="sps-loc-field">
                    <i className="sps-sprite sps-search"></i>
                    <input placeholder="Search" className="sps-form-control search-location pac-target-input" type="text" id="search" name="search" autoComplete="off"></input>
                    <i className="icon-close2 sps-form-icon"></i>
                  </div>
                </header>
              </div>
            </div>
            <footer className="sps-loc-footer">
              <div className="sps-row">
                <div className="sps-col12 sps-d-f-sbetween">
                  <h5 className="sps-sprite sps-loc-map-ico sps-loc-type">
                    <b>Visit location</b>
                  </h5>
                </div>
                <div className="sps-col12 sps-mt10">
                  <div className="sps-lcn-card">
                    <div className="sps-d-f-sbetween">
                      <h5>
                        <i className="icon-location2 sps-mr5 sps-success-text"></i>
                        <b>Madinah</b>
                      </h5>
                      <small className="sps-save-lcn-text">Save Location</small>
                    </div>
                    <p>Nike Store,  Sultana street, Madinah, Medina, Saudi Arabia</p>
                  </div>
                </div>
                <div className="sps-col12 sps-ftr-res-btn">
                  <button type="submit" className="btn btn-outline-primary sps-btn-view">View saved address</button>

                  <button id='locbtn' data-bs-dismiss="modal" className="btn btn-primary sps-btn-confirm" type="submit">
                    Confirm location
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NurseVisitContinue;
