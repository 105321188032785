import React, { useEffect } from 'react';
import '../../../Style/HomeVisitDoctor.css';
import { Link } from 'react-router-dom';

function HomeVisitDoctorList(props) {
    useEffect(() => {
        const slider = document.querySelector("[data-slider]");

        const track = slider.querySelector("[data-slider-track]");
        const prev = slider.querySelector("[data-slider-prev]");
        const next = slider.querySelector("[data-slider-next]");

        if (track) {
            prev.addEventListener("click", () => {
                next.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft - track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            next.addEventListener("click", () => {
                prev.removeAttribute("disabled");

                track.scrollTo({
                    left: track.scrollLeft + track.firstElementChild.offsetWidth,
                    behavior: "smooth"
                });
            });

            track.addEventListener("scroll", () => {
                const trackScrollWidth = track.scrollWidth;
                const trackOuterWidth = track.clientWidth;

                prev.removeAttribute("disabled");
                next.removeAttribute("disabled");

                if (track.scrollLeft <= 0) {
                    prev.setAttribute("disabled", "");
                }

                if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
                    next.setAttribute("disabled", "");
                }
            });
        }
    })
    return (
        <>
            <div className="z-card-list-box">
                <figure className="z-doc-avtar z-doc-avtar-lg">
                    <img src={props.doctorImage} alt="Doctor" className="z-doc-img-cover" />
                </figure>

                <div className="z-doctor-details">
                    <div className="z-doctor-details-data">
                        <div>
                            <h6> Dr. {props.doctorName} </h6>
                            <span className="z-desig"> {props.doctorDesig} </span>
                        </div>
                        <div className="z-split">
                            <p>
                                <i className="icon-doctor-bag2"></i>
                                <span className="z-dark"> {props.doctorLoc} </span>
                            </p>
                            <p>
                                <span className="z-docvisit"> {props.docVisit} </span>
                            </p>
                        </div>
                    </div>

                    <div className="z-doctor-price">
                        <span className="z-dark"> Price </span>
                        <h6 className="z-price-tag">
                            <span> {props.currency} </span> {props.price}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="z-time-carousel-slide" data-slider>
                <ul className="z-time-carousel" data-slider-track>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:00 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:15 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:30 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 11:45 am </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:30 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 12:45 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:30 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 01:45 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:30 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 02:45 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 03:00 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 03:15 pm </p>
                        </Link>
                    </li>
                    <li style={{ width: '1500px' }}>
                        <Link to='/review/utid=1'>
                            <p className="z-time-slot"> 03:30 pm </p>
                        </Link>
                    </li>

                </ul>

                <button name="next button" type="button" className="z-time-prev icon-left-arrow" data-slider-prev disabled></button>
                <button name="prev button" type="button" className="z-time-next icon-right-arrow" data-slider-next></button>
            </div>
        </>
    )
}

export default HomeVisitDoctorList
