import React from 'react'
import { Link } from 'react-router-dom'

function BiHealth() {
    return (
        <div className="container bi-services-hlt bi-hlt-top120">
            <div className="row bi-hlt-gutter20">
                <div className="bi-hlt-col6 bi-hlt-col-md6 bi-hlt-col-sm6 bi-hlt-col-xs12 bi-hlt-d-flex">
                    <div className="bi-hlt-card-shadow bi-hlt-serv-in">
                        <h3 className="h3"><b>Book an appointment from <br></br> our available services</b></h3>
                        <p>Get immediate care for you and your family</p>
                    </div>
                </div>
                <div className="bi-hlt-col6 bi-hlt-col-md6 bi-hlt-col-sm6 bi-hlt-col-xs12 bi-hlt-d-flex">
                    <div className="bi-hlt-card-shadow bi-hlt-serv-in bi-hlt-request-bg">
                        <h3 className="h3"><b>Request personalized <br></br>health services</b></h3>
                        <p>We will contact you to meet your specific needs</p>
                        <Link className="prf-link" to="/personalizedrequest">
                            <button className="bi-hlt-btn bi-hlt-btn-lg bi-hlt-btn-serv bi-hlt-btn-hov">
                                <i className="icon-heart-rate bi-hlt-pr10"></i>
                                <b>Request Now</b>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BiHealth
