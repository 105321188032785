export const allpackage = [
    {
        "id": "healthcheack",
        "packageTitle": "Health Check Package",
        "packagePrice": "450",
        "packageImage": "https://static.sanar.sa/mis/5d274fa3cc1bb068c1c7a6736bbaa451.png",
        "packageDesc": "A group of tests that should be performed on a regular basis.These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "29",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "ALP(ALKALINE PHOSPHATASE)",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "fatigueworkup",
        "packageTitle": "Fatigue Workup",
        "packagePrice": "350",
        "packageImage": "https://static.sanar.sa/mis/a2b1fae9c8f26a717d92ca51b5791b5e.png",
        "packageDesc": "The Diagnosis depends on evaluating the patient's physical health status to treat fatigue and tiredness, and the treatment depends on knowing the underlying cause by examining the blood sample and then treat the cause.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "22",
        "test1": "ALP(ALKALINE PHOSPHATASE)",
        "test2": "NA (S) 'Sodium'",
        "test3": "Corrected Calcium Test",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "weightloss",
        "packageTitle": "Weight loss Medical Treatment ( Saxenda , Ozempic , Mounjaro )",
        "packagePrice": "345",
        "packageImage": "https://static.sanar.sa/mis/fc499420a25ff17be517665da49280eb.png",
        "packageDesc": "A set of laboratory tests designed to confirm the safe use of weight control injections and avoiding side effects (injections are used under medical supervision).",
        "instruction1": "This test does not require fasting.",
        "totalTest": "14",
        "test1": "URIC ACID SERUM",
        "test2": "ALP(ALKALINE PHOSPHATASE)",
        "test3": "GLUCOSE ( RBS )",
        "test4": "GAMMA GT (GGT) ( Gamma Glutamyl Transferase )",
        "test5": "VITAMIN B12 (CYANOCOBOLAMINE)",
    },
    {
        "id": "hairfall",
        "packageTitle": "Hair Fall Workup",
        "packagePrice": "360",
        "packageImage": "https://static.sanar.sa/mis/647eddb6716e325491b124bdf955d7d1.png",
        "packageDesc": "Hair fall analysis is done by checking the thyroid stimulating hormone and hemoglobin level in the blood and other tests as well.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "13",
        "test1": "FERRITIN",
        "test2": "ZINC (serum)",
        "test3": "TSH (THYROID STIMULATING HORMONE)",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "roaccutane",
        "packageTitle": "Roaccutane Tests",
        "packagePrice": "325",
        "packageImage": "https://static.sanar.sa/mis/ee4bc22886fea9d26d3c27d409f8ff07.png",
        "packageDesc": "As Roaccutane can affect the blood count, liver function and serum lipids (cholesterol and triglycerides), it is necessary to check these (baseline) before starting Roaccutane",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "14",
        "test1": "CHOLESTEROL",
        "test2": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "test3": "TRIGLYCERIDES",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "fitness",
        "packageTitle": "Fitness Profile",
        "packagePrice": "750",
        "packageImage": "https://static.sanar.sa/mis/3d655f3a156547316b83ec4124e12e41.png",
        "packageDesc": "a set of 27 laboratory tests, including hormone analyses, to determine whether there are any possible health problems for athletes",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "36",
        "test1": "GLUCOSE ( FBS )",
        "test2": "NA (S) 'Sodium'",
        "test3": "TESTOSTERONE TOTAL",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "vitamins",
        "packageTitle": "Vitamins Package",
        "packagePrice": "750",
        "packageImage": "https://static.sanar.sa/mis/01b982035d9ed419ffde1faf668297ea.png",
        "packageDesc": "Vitamins are crucial for our body and are required to perform various functions. These functions include bone support, wound healing, and strengthening of the immune system.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "9",
        "test1": "Vitamin C (Ascorbic Acid )",
        "test2": "Vitamin B1 (Thiamin)",
        "test3": "Vitamin B9 ( Folate )",
        "test4": "Vitamin B2 (Riboflavin)",
        "test5": "VITAMIN B12 (CYANOCOBOLAMINE)",
    },
    {
        "id": "electrolytes",
        "packageTitle": "Electrolytes & Menerals",
        "packagePrice": "300",
        "packageImage": "https://sanar-assets.com/mis/0884d751bc3352e6196f75821a9a0ced.jpg",
        "packageDesc": "Electrolytes and minerals are an essential element of the body's nutrition, therefore it's crucial to make sure they're present in the adequate amounts",
        "instruction1": "This test does not require fasting.",
        "totalTest": "9",
        "test1": "SERUM CA ( Calcium )",
        "test2": "NA (S) 'Sodium'",
        "test3": "PHOSPHORUS",
        "test4": "Potassium blood test",
        "test5": "ZINC (serum)",
    },
    {
        "id": "pregnancy",
        "packageTitle": "Pregnancy Test Serum (BHCG)",
        "packagePrice": "220",
        "packageImage": "https://static.sanar.sa/mis/b6b9a20549998393406fc7a25948a25e.png",
        "packageDesc": "By applying this test, it is possible to detect the presence of pregnancy in the early stage. The test depends mainly on measuring the percentage of human pregnancy hormone in a more accurate way, and the result is shown in numbers, therefore, it indicates the level of pregnancy hormone in the blood.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "BETA HCG ( QUALITATIVE ) ( Human Chorionic Gonadotropin )",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "sexual",
        "packageTitle": "Sexual Transmitted Disease",
        "packagePrice": "600",
        "packageImage": "https://static.sanar.sa/mis/914502d71f64083f509169b4a2a5034b.png",
        "packageDesc": "This test helps to determine the plan to diagnose any infectious disease. Because if there are no signs or symptoms, you may not be aware that you have any bacterial, viral or parasitic infections.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "8",
        "test1": "Chlamydia IGM",
        "test2": "HERPES SIMPLEX 2 IGM",
        "test3": "HCV Abs (HEPATITIS C) ( HBC AB ) ( HCV )",
        "test4": "HIV Antibody and HIV Antigen (p24)",
        "test5": "Urine Culture C/S",
    },
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "ALP(ALKALINE PHOSPHATASE)",
        "test4": "STOOL OCCULT BLOOD",
        "test5": "PLATELET COUNT",
    },
    {
        "id": "thyroid",
        "packageTitle": "Thyroid Function Test",
        "packagePrice": "320",
        "packageImage": "https://static.sanar.sa/mis/d921c21b796c6d6c72862b834e474e13.png",
        "packageDesc": "Thyroid function test are a group of blood tests that are used to measure the efficiency and performance of your thyroid gland.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "3",
        "test1": "FREE T4 ( Free Tetraiodo Thyronine )",
        "test2": "FREE T3 OR : TRIIODOTHYRONINE",
        "test3": "TSH (THYROID STIMULATING HORMONE)",
        "test4": "",
        "test5": "",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "ALP(ALKALINE PHOSPHATASE)",
        "test4": "Anti Mullerian Hormone - AMH",
        "test5": "STOOL OCCULT BLOOD",
    },
    {
        "id": "urinary",
        "packageTitle": "Urinary Tract Infection Test",
        "packagePrice": "180",
        "packageImage": "https://sanar-assets.com/mis/7406123e0c124a9cb876c5e7598f5da2.jpg",
        "packageDesc": "It is important to observe the early signs of urinary tract infection for rapid diagnosis and treatment, especially for elderly people. Therefore, you have to keep an eye on the changes that occur in your loved one's daily routine.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "2",
        "test1": "Urine Culture C/S",
        "test2": "URINE ANALYSIS",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "maletumor",
        "packageTitle": "Male Tumor Markers",
        "packagePrice": "550",
        "packageImage": "https://sanar-assets.com/mis/da49546e677e21ac4857f8f4dd2a1ea4.jpg",
        "packageDesc": "Early detection of tumors plays an important role in the management of the disease, include screening of Prostatic cancer, testicular cancer, colon and pancreatic cancer, liver cancer",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "CarcinoEmbryonic Antigen (CEA19.9) CA 19.9 ( Tumor Marker )",
        "test2": "BETA HCG ( QUALITATIVE ) ( Human Chorionic Gonadotropin )",
        "test3": "Alpha Feto Protien (AFP)",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) TOTAL",
        "test5": "carcinoembryonic antigen CEA",
    },
    {
        "id": "lipid",
        "packageTitle": "Lipid Profile Follow Up",
        "packagePrice": "200",
        "packageImage": "https://sanar-assets.com/mis/afe58ae9192275eb87702909958ea014.jpg",
        "packageDesc": "Lipid profile is a group of blood tests used to measure the levels of (cholesterol - Triglycerides - HDL-LDL). The service includes a liver enzyme test, as the liver is responsible for fat metabolism.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "6",
        "test1": "CHOLESTEROL",
        "test2": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "test3": "TRIGLYCERIDES",
        "test4": "HDL CHOLESTEROL",
        "test5": "LDL CHOLESTEROL",
    },
    {
        "id": "cardiac",
        "packageTitle": "Cardiac Profile",
        "packagePrice": "320",
        "packageImage": "https://sanar-assets.com/mis/5511124e1041d6a30812437e7093fdf6.jpg",
        "packageDesc": "A set of laboratory tests used to monitor heart health and avoid illness.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "Troponin Test",
        "test2": "LDH ( Lactate Dehydrogenase )",
        "test3": "CKMB / CPK (MB)",
        "test4": "GOT (AST) GLUT-OXALO-ACETIC TRANSA ( Glutamate Oxaloacetate Transaminase )",
        "test5": "Creatine Kinase (CK) Total",
    },
    {
        "id": "wellbaby",
        "packageTitle": "Well Baby",
        "packagePrice": "250",
        "packageImage": "https://sanar-assets.com/mis/4663ebac124f5e44b616faf84acc00b9.jpg",
        "packageDesc": "Well baby packages designed carefully in order to take care of your child's health. The child health package is a group of test that include (thyroid functions , vitamin D , ferritin level and other laboratory test) .",
        "instruction1": "This test does not require fasting.",
        "totalTest": "14",
        "test1": "GLUCOSE ( RBS )",
        "test2": "PLATELET COUNT",
        "test3": "HEMOGLOBIN ( HB )",
        "test4": "FERRITIN",
        "test5": "Vitamin D",
    },
    {
        "id": "fertility",
        "packageTitle": "Fertility Female ",
        "packagePrice": "460",
        "packageImage": "https://sanar-assets.com/mis/8105388faa0e9e46ae1d8d5a09714ce4.jpg",
        "packageDesc": "A set of laboratory tests designed to determine levels of the most important hormones associated with female fertility and delayed childbearing",
        "instruction1": "This test does not require fasting.",
        "totalTest": "8",
        "test1": "Progesterone blood test",
        "test2": "TESTOSTERONE TOTAL",
        "test3": "LH (LUTEINISING HORMONE)",
        "test4": "ESTROGEN (E2: ESTRADIOL)",
        "test5": "FSH (FOLLICLE STIULATING HORMONE)",
    },
    {
        "id": "femaletumor",
        "packageTitle": "Female Tumor Markers",
        "packagePrice": "550",
        "packageImage": "https://sanar-assets.com/mis/882f2b97a86105eb3fb25cbc8973212f.jpg",
        "packageDesc": "Early detection of tumors plays an important role in the management of the disease, include screening of ovarian cancer, breast cancer, colon and pancreatic cancer, liver cancer",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "CarcinoEmbryonic Antigen (CEA19.9) CA 19.9 ( Tumor Marker )",
        "test2": "CarcinoEmbryonic Antigen (CEA15-3) CA 15-3 ( Tumor Marker )",
        "test3": "Alpha Feto Protien (AFP)",
        "test4": "CarcinoEmbryonic Antigen (CEA125) CA 125 ( Tumor Marker ) ( OVARIAN TUMOR MARK )",
        "test5": "carcinoembryonic antigen CEA",
    },
    {
        "id": "periodictest",
        "packageTitle": "Diabetes - Periodic test",
        "packagePrice": "360",
        "packageImage": "https://sanar-assets.com/mis/2aeea572939886563dcb66b33d592b13.jpg",
        "packageDesc": "Diabetics periodic tests for screening and follow up to prevent diabetes complications. It includes periodic tests for a diabetic patient that must be repeated every 3 months.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "16",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "LH (LUTEINISING HORMONE)VITAMIN B12 (CYANOCOBOLAMINE)",
        "test4": "CHOLESTEROL",
        "test5": "URINE ANALYSIS",
    },
    {
        "id": "fertilitymale",
        "packageTitle": "Fertility Male",
        "packagePrice": "300",
        "packageImage": "https://sanar-assets.com/mis/241c241247861677d4580496627fe81b.jpg",
        "packageDesc": "A set of laboratory tests designed to determine levels of the most important hormones associated with male fertility and delayed childbearing",
        "instruction1": "This test does not require fasting.",
        "totalTest": "7",
        "test1": "TESTOSTERONE TOTAL",
        "test2": "Free Testosterone",
        "test3": "LH (LUTEINISING HORMONE)",
        "test4": "ESTROGEN (E2: ESTRADIOL)",
        "test5": "FSH (FOLLICLE STIULATING HORMONE)",
    },
    {
        "id": "bariatric",
        "packageTitle": "Bariatric Surgery Follow up",
        "packagePrice": "400",
        "packageImage": "https://sanar-assets.com/mis/c64562460ce7ab2d6dd5c5e2c840b076.jpg",
        "packageDesc": "Post-bariatric surgery check-up to determine vitamins, sugar, hemoglobin, and platelet deficiencies.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "16",
        "test1": "URIC ACID SERUM",
        "test2": "CHOLESTEROL",
        "test3": "GAMMA GT (GGT) ( Gamma Glutamyl Transferase )",
        "test4": "VITAMIN B12 (CYANOCOBOLAMINE)",
        "test5": "ALBUMIN",
    },
    {
        "id": "diabetesann",
        "packageTitle": "Diabetes - Annually",
        "packagePrice": "450",
        "packageImage": "https://sanar-assets.com/mis/56720f16696e4b95140a0779ddf88d89.jpg",
        "packageDesc": "The annual tests for the diabetic patient are among the most important tests that are required annually to avoid any health complications, and to check on the patient's health regularly",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "26",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "PLATELET COUNT",
        "test4": "HEMOGLOBIN ( HB )",
        "test5": "VITAMIN B12 (CYANOCOBOLAMINE)",
    },
    {
        "id": "foodprint",
        "packageTitle": "Food Print",
        "packagePrice": "1150",
        "packageImage": "https://sanar-assets.com/mis/6d80862e70b33aef6d79b501f29b93be.png",
        "packageDesc": "Blood test that can measure your immune system's response to particular foods by measuring the allergy-related antibody known as immunoglobulin E (IgE)",
        "instruction1": "This test does not require fasting.",
        "totalTest": "0",
        "test1": "",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "parathyroid",
        "packageTitle": "Parathyroid Hormone",
        "packagePrice": "250",
        "packageImage": "https://sanar-assets.com/mis/09340bf58131244178a81a59744228ff.jpg",
        "packageDesc": "Parathyroid hormone test are a group of blood tests that are used to measure the efficiency and performance of your parathyroid thyroid gland.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "Parathyroid Hormone Test (PTH)",
        "test2": "SERUM CA ( Calcium )",
        "test3": "PHOSPHORUS",
        "test4": "MG (S) ' magnesium '",
        "test5": "Vitamin D",
    },
    {
        "id": "ureatest",
        "packageTitle": "Urea Breath Test  (H-PYLORI)",
        "packagePrice": "270",
        "packageImage": "https://sanar-assets.com/mis/a5069708d0dbdcb22da1e8d4447033ae.jpg",
        "packageDesc": "It is a simple and safe test used to detect and check infection with H-pylori bacteria by measuring some substances in the patient's breath",
        "instruction1": "You should fast for 4 hours before the test",
        "totalTest": "1",
        "test1": "UREA BREATH TEST (UBT)",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "osteoporosis",
        "packageTitle": "Osteoporosis Screening",
        "packagePrice": "250",
        "packageImage": "https://sanar-assets.com/mis/9e16476e09a4494a5fdb1c37e9eaee6c.jpg",
        "packageDesc": "A set of laboratory tests aiming to diagnose and treat osteoporosis by identifying the reasons of bone and joint pain",
        "instruction1": "This test does not require fasting.",
        "totalTest": "4",
        "test1": "Parathyroid Hormone Test (PTH)",
        "test2": "SERUM CA ( Calcium )",
        "test3": "PHOSPHORUS",
        "test4": "Vitamin D",
        "test5": "",
    },
    {
        "id": "coagulation",
        "packageTitle": "Coagulation Profile",
        "packagePrice": "200",
        "packageImage": "https://sanar-assets.com/mis/7f49e36bea3bedbe53e6f1eba1a82d30.jpg",
        "packageDesc": "Coagulation Profile includes the analysis of both platelets and coagulation proteins. An evaluation of the performance of this system is necessary in conditions such as bleeding or hyper coagulability before surgery, and in order to examine the effect of anticoagulants on the body as well.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "3",
        "test1": "international normalized ratio INR",
        "test2": "PTT (PARTIAL THROMBOPLASTINE TIME) ( APTT )",
        "test3": "PT (PROTHROMBIN TIME)",
        "test4": "",
        "test5": "",
    },
    {
        "id": "investigations",
        "packageTitle": "Investigations (First Trimester)",
        "packagePrice": "350",
        "packageImage": "https://sanar-assets.com/mis/ed8403ad4a679c4d62a5ec7d921b9736.jpg",
        "packageDesc": "The first package of tests that are performed after confirming pregnancy to preserve the health of the mother and the fetus for a safe pregnancy and to prevent exposure to complications of pregnancy.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "14",
        "test1": "URIC ACID SERUM",
        "test2": "PLATELET COUNT",
        "test3": "HEMOGLOBIN ( HB )",
        "test4": "Urine Culture C/S",
        "test5": "URINE ANALYSIS",
    },
    {
        "id": "bloodgroup",
        "packageTitle": "Blood Group",
        "packagePrice": "150",
        "packageImage": "https://sanar-assets.com/mis/146ea0be904a5abd5854f06f358eb32d.jpg",
        "packageDesc": "Blood group test is a simple blood test that accurately determines a person's blood group, Also it’s a necessary test before donating blood and in pregnant women to identify the presence of a blood group that differs from that of the fetus.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "ABO / Rh typing Blood Grouping RH PHENOTYPE",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "gym",
        "packageTitle": "GYM (swimming pool)",
        "packagePrice": "420",
        "packageImage": "https://sanar-assets.com/mis/d6654de69cb4ea32c11b56829d268ce2.jpg",
        "packageDesc": "GYM (swimming pool) Test, This test is required when joining a gym, and it includes a group of tests that prove you are free of infectious diseases and is usually a mandatory requirement for gym membership approval.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "HCV Abs (HEPATITIS C) ( HBC AB ) ( HCV )",
        "test2": "Urine Culture C/S",
        "test3": "ABO / Rh typing Blood Grouping RH PHENOTYPE",
        "test4": "RPR / VDRL ( Venereal Disease Research Laboratory )( Rapid Plasman Reagent )Test For Syphilis",
        "test5": "HBs Ag (HEPATITIS B MARKER)",
    },
    {
        "id": "foodpanel",
        "packageTitle": "Food Panel",
        "packagePrice": "600",
        "packageImage": "https://sanar-assets.com/mis/d5a68d977f7b4969be2197687442593a.png",
        "packageDesc": "Blood test used to detect specific Immunoglobulin E (IgE) antibodies in the blood against various food allergens. IgE antibodies are produced by the immune system in response to allergens and play a crucial role in triggering allergic reactions. measures immune responses to Milk, Eggs, Peanuts, Tree nuts (e.g., almonds, walnuts, cashews), Soy, Wheat, Fish, Shellfish (e.g., shrimp, crab, lobster).",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "Allergens specific IgE Screening (Food panel)",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "inhalant",
        "packageTitle": "Inhalant Panel",
        "packagePrice": "",
        "packageImage": "https://static.sanar.sa/mis/c51f05fbc2ea418fe9a9ad989815bdbe.jpg",
        "packageDesc": "Blood tests that measures the levels of different IgE antibodies in a person's blood. Allergen-specific IgE tests are sometimes used to diagnose and better manage food allergies.includes a variety of common allergens that are inhaled, such as:Pollens, Dust mites, Animal dander, Mold spores, Cockroach allergens, and other indoor and outdoor allergens",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "Allergens specific IgE Screening (Inhalant panel)",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    }
]


export const bodyPackage = [
    {
        "id": "healthcheack",
        "packageTitle": "Health Check Packages",
        "packagePrice": "450",
        "packageImage": "https://static.sanar.sa/mis/5d274fa3cc1bb068c1c7a6736bbaa451.png",
        "packageDesc": "A group of tests that should be performed on a regular basis.These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "29",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "ALP(ALKALINE PHOSPHATASE)",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "fatigueworkup",
        "packageTitle": "Fatigue Workup",
        "packagePrice": "350",
        "packageImage": "https://static.sanar.sa/mis/a2b1fae9c8f26a717d92ca51b5791b5e.png",
        "packageDesc": "The Diagnosis depends on evaluating the patient's physical health status to treat fatigue and tiredness, and the treatment depends on knowing the underlying cause by examining the blood sample and then treat the cause.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "22",
        "test1": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "test2": "Creatinine blood test",
        "test3": "GOT (AST) GLUT-OXALO-ACETIC TRANSA ( Glutamate Oxaloacetate Transaminase )",
        "test4": "PLATELET COUNT",
        "test5": "Corrected Calcium Test",
    },
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    },
    {
        "id": "fitness",
        "packageTitle": "Fitness Profile",
        "packagePrice": "750",
        "packageImage": "https://static.sanar.sa/mis/3d655f3a156547316b83ec4124e12e41.png",
        "packageDesc": "A set of 27 laboratory tests, including hormone analyses, to determine whether there are any possible health problems for athletes",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "36",
        "test1": "LDL CHOLESTEROL",
        "test2": "Folic Acid",
        "test3": "TRIGLYCERIDES",
        "test4": "PLATELET COUNT",
        "test5": "ESTROGEN (E2: ESTRADIOL)",
    },
]

export const diabetesPackage = [
    {
        "id": "periodictest",
        "packageTitle": "Diabetes - Periodic test",
        "packagePrice": "360",
        "packageImage": "https://sanar-assets.com/mis/2aeea572939886563dcb66b33d592b13.jpg",
        "packageDesc": "Diabetics periodic tests for screening and follow up to prevent diabetes complications. It includes periodic tests for a diabetic patient that must be repeated every 3 months.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "16",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "LH (LUTEINISING HORMONE)VITAMIN B12 (CYANOCOBOLAMINE)",
        "test4": "CHOLESTEROL",
        "test5": "URINE ANALYSIS",
    },
    {
        "id": "diabetesann",
        "packageTitle": "Diabetes - Annually",
        "packagePrice": "450",
        "packageImage": "https://sanar-assets.com/mis/56720f16696e4b95140a0779ddf88d89.jpg",
        "packageDesc": "The annual tests for the diabetic patient are among the most important tests that are required annually to avoid any health complications, and to check on the patient's health regularly",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "26",
        "test1": "GLUCOSE ( FBS )",
        "test2": "URIC ACID SERUM",
        "test3": "PLATELET COUNT",
        "test4": "HEMOGLOBIN ( HB )",
        "test5": "VITAMIN B12 (CYANOCOBOLAMINE)",
    }
]

export const cholesterolPackages = [
    {
        "id": "lipid",
        "packageTitle": "Lipid Profile Follow Up",
        "packagePrice": "200",
        "packageImage": "https://sanar-assets.com/mis/afe58ae9192275eb87702909958ea014.jpg",
        "packageDesc": "Lipid profile is a group of blood tests used to measure the levels of (cholesterol - Triglycerides - HDL-LDL). The service includes a liver enzyme test, as the liver is responsible for fat metabolism.",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "6",
        "test1": "CHOLESTEROL",
        "test2": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "test3": "TRIGLYCERIDES",
        "test4": "HDL CHOLESTEROL",
        "test5": "LDL CHOLESTEROL",
    },
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    }
]

export const hairPackages = [
    {
        "id": "hairfall",
        "packageTitle": "Hair Fall Workup",
        "packagePrice": "360",
        "packageImage": "https://static.sanar.sa/mis/647eddb6716e325491b124bdf955d7d1.png",
        "packageDesc": "Hair fall analysis is done by checking the thyroid stimulating hormone and hemoglobin level in the blood and other tests as well.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "13",
        "test1": "FERRITIN",
        "test2": "ZINC (serum)",
        "test3": "TSH (THYROID STIMULATING HORMONE)",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
]

export const skinPackages = [
    {
        "id": "roaccutane",
        "packageTitle": "Roaccutane Tests",
        "packagePrice": "325",
        "packageImage": "https://static.sanar.sa/mis/ee4bc22886fea9d26d3c27d409f8ff07.png",
        "packageDesc": "As Roaccutane can affect the blood count, liver function and serum lipids (cholesterol and triglycerides), it is necessary to check these (baseline) before starting Roaccutane",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "14",
        "test1": "CHOLESTEROL",
        "test2": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "test3": "TRIGLYCERIDES",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
    {
        "id": "hairfall",
        "packageTitle": "Hair Fall Workup",
        "packagePrice": "360",
        "packageImage": "https://static.sanar.sa/mis/647eddb6716e325491b124bdf955d7d1.png",
        "packageDesc": "Hair fall analysis is done by checking the thyroid stimulating hormone and hemoglobin level in the blood and other tests as well.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "13",
        "test1": "FERRITIN",
        "test2": "ZINC (serum)",
        "test3": "TSH (THYROID STIMULATING HORMONE)",
        "test4": "PLATELET COUNT",
        "test5": "HEMOGLOBIN ( HB )",
    },
]

export const vitaminPackages = [
    {
        "id": "vitamins",
        "packageTitle": "Vitamins Package",
        "packagePrice": "750",
        "packageImage": "https://static.sanar.sa/mis/01b982035d9ed419ffde1faf668297ea.png",
        "packageDesc": "Vitamins are crucial for our body and are required to perform various functions. These functions include bone support, wound healing, and strengthening of the immune system.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "9",
        "test1": "Vitamin C (Ascorbic Acid )",
        "test2": "Vitamin B1 (Thiamin)",
        "test3": "Vitamin B9 ( Folate )",
        "test4": "Vitamin B2 (Riboflavin)",
        "test5": "VITAMIN B12 (CYANOCOBOLAMINE)",
    },
]

export const urinaryPackages = [
    {
        "id": "urinary",
        "packageTitle": "Urinary Tract Infection Test",
        "packagePrice": "180",
        "packageImage": "https://sanar-assets.com/mis/7406123e0c124a9cb876c5e7598f5da2.jpg",
        "packageDesc": "It is important to observe the early signs of urinary tract infection for rapid diagnosis and treatment, especially for elderly people. Therefore, you have to keep an eye on the changes that occur in your loved one's daily routine.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "2",
        "test1": "Urine Culture C/S",
        "test2": "URINE ANALYSIS",
        "test3": "",
        "test4": "",
        "test5": "",
    },
]

export const kidneyPackages = [
    {
        "id": "fatigueworkup",
        "packageTitle": "Fatigue Workup",
        "packagePrice": "350",
        "packageImage": "https://static.sanar.sa/mis/a2b1fae9c8f26a717d92ca51b5791b5e.png",
        "packageDesc": "The Diagnosis depends on evaluating the patient's physical health status to treat fatigue and tiredness, and the treatment depends on knowing the underlying cause by examining the blood sample and then treat the cause.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "22",
        "test1": "GPT (ALT) GLUTPYRUVIC TANSA ( Serum Glutamate Pyruvate Transaminase )",
        "test2": "Creatinine blood test",
        "test3": "GOT (AST) GLUT-OXALO-ACETIC TRANSA ( Glutamate Oxaloacetate Transaminase )",
        "test4": "PLATELET COUNT",
        "test5": "Corrected Calcium Test",
    },
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    },
]

export const liverPackages = [
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    },
]

export const parathyroidPackages = [
    {
        "id": "parathyroid",
        "packageTitle": "Parathyroid Hormone",
        "packagePrice": "250",
        "packageImage": "https://sanar-assets.com/mis/09340bf58131244178a81a59744228ff.jpg",
        "packageDesc": "Parathyroid hormone test are a group of blood tests that are used to measure the efficiency and performance of your parathyroid thyroid gland.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "Parathyroid Hormone Test (PTH)",
        "test2": "SERUM CA ( Calcium )",
        "test3": "PHOSPHORUS",
        "test4": "MG (S) ' magnesium '",
        "test5": "Vitamin D",
    },
]

export const thyroidPackages = [
    {
        "id": "thyroid",
        "packageTitle": "Thyroid Function Test",
        "packagePrice": "320",
        "packageImage": "https://static.sanar.sa/mis/d921c21b796c6d6c72862b834e474e13.png",
        "packageDesc": "Thyroid function test are a group of blood tests that are used to measure the efficiency and performance of your thyroid gland.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "3",
        "test1": "FREE T4 ( Free Tetraiodo Thyronine )",
        "test2": "FREE T3 OR : TRIIODOTHYRONINE",
        "test3": "TSH (THYROID STIMULATING HORMONE)",
        "test4": "",
        "test5": "",
    },
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    },
]

export const cardiacPackages = [
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    },
    {
        "id": "cardiac",
        "packageTitle": "Cardiac Profile",
        "packagePrice": "320",
        "packageImage": "https://sanar-assets.com/mis/5511124e1041d6a30812437e7093fdf6.jpg",
        "packageDesc": "A set of laboratory tests used to monitor heart health and avoid illness.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "Troponin Test",
        "test2": "LDH ( Lactate Dehydrogenase )",
        "test3": "CKMB / CPK (MB)",
        "test4": "GOT (AST) GLUT-OXALO-ACETIC TRANSA ( Glutamate Oxaloacetate Transaminase )",
        "test5": "Creatine Kinase (CK) Total",
    },
]

export const bonePackages = [
    {
        "id": "parathyroid",
        "packageTitle": "Parathyroid Hormone",
        "packagePrice": "250",
        "packageImage": "https://sanar-assets.com/mis/09340bf58131244178a81a59744228ff.jpg",
        "packageDesc": "Parathyroid hormone test are a group of blood tests that are used to measure the efficiency and performance of your parathyroid thyroid gland.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "5",
        "test1": "Parathyroid Hormone Test (PTH)",
        "test2": "SERUM CA ( Calcium )",
        "test3": "PHOSPHORUS",
        "test4": "MG (S) ' magnesium '",
        "test5": "Vitamin D",
    },
    {
        "id": "packagesformen",
        "packageTitle": "Health Check Packages for Men",
        "packagePrice": "1200",
        "packageImage": "https://static.sanar.sa/mis/ceb9bc047825ce6dd421062e2f4cd0bb.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 55 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "PROSTATIC SPECIFIC ANTIGEN (PSA) FREE",
        "test5": "TRIGLYCERIDES",
    },
    {
        "id": "packagesforwomen",
        "packageTitle": "Health Check Packages for women",
        "packagePrice": "1250",
        "packageImage": "https://static.sanar.sa/mis/13b7e2490e9ad0f082d93d9625a8a6bf.png",
        "packageDesc": "A group of tests that should be performed on a regular basis. These laboratory tests allow for the assessment of general health as well as the diagnosis and prevention of disease risk factors. Include ( 54 lab test )",
        "instruction1": "Before the test, you should fast for 10-12 hours. Nothing should be eaten or drunk (other than water).",
        "totalTest": "54",
        "test1": "Total Protein in Serum",
        "test2": "LDL CHOLESTEROL",
        "test3": "STOOL OCCULT BLOOD",
        "test4": "TRIGLYCERIDES",
        "test5": "CHOLESTEROL",
    },
]

export const bloodPackages = [
    {
        "id": "coagulation",
        "packageTitle": "Coagulation Profile",
        "packagePrice": "200",
        "packageImage": "https://sanar-assets.com/mis/7f49e36bea3bedbe53e6f1eba1a82d30.jpg",
        "packageDesc": "Coagulation Profile includes the analysis of both platelets and coagulation proteins. An evaluation of the performance of this system is necessary in conditions such as bleeding or hyper coagulability before surgery, and in order to examine the effect of anticoagulants on the body as well.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "3",
        "test1": "international normalized ratio INR",
        "test2": "PTT (PARTIAL THROMBOPLASTINE TIME) ( APTT )",
        "test3": "PT (PROTHROMBIN TIME)",
        "test4": "",
        "test5": "",
    },
    {
        "id": "bloodgroup",
        "packageTitle": "Blood Group",
        "packagePrice": "150",
        "packageImage": "https://sanar-assets.com/mis/146ea0be904a5abd5854f06f358eb32d.jpg",
        "packageDesc": "Blood group test is a simple blood test that accurately determines a person's blood group, Also it’s a necessary test before donating blood and in pregnant women to identify the presence of a blood group that differs from that of the fetus.",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "ABO / Rh typing Blood Grouping RH PHENOTYPE",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
]

export const digestionPackages = [
    {
        "id": "ureabreath",
        "packageTitle": "Urea Breath Test (H-PYLORI)",
        "packagePrice": "270",
        "packageImage": "https://sanar-assets.com/mis/a5069708d0dbdcb22da1e8d4447033ae.jpg",
        "packageDesc": "It is a simple and safe test used to detect and check infection with H-pylori bacteria by measuring some substances in the patient's breath",
        "instruction1": "You should fast for 4 hours before the test",
        "instruction2": "Note: Taking antibiotics can interfere with the accuracy of the testing",
        "totalTest": "1",
        "test1": "UREA BREATH TEST (UBT)",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "foodprint",
        "packageTitle": "Food Print",
        "packagePrice": "1150",
        "packageImage": "https://sanar-assets.com/mis/6d80862e70b33aef6d79b501f29b93be.png",
        "packageDesc": "Blood test that can measure your immune system's response to particular foods by measuring the allergy-related antibody known as immunoglobulin E (IgE)",
        "instruction1": "This test does not require fasting.",
        "totalTest": "0",
        "test1": "",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
]

export const allergyPackages = [
    {
        "id": "foodpanel",
        "packageTitle": "Food Panel",
        "packagePrice": "600",
        "packageImage": "https://sanar-assets.com/mis/d5a68d977f7b4969be2197687442593a.png",
        "packageDesc": "Blood test used to detect specific Immunoglobulin E (IgE) antibodies in the blood against various food allergens. IgE antibodies are produced by the immune system in response to allergens and play a crucial role in triggering allergic reactions. measures immune responses to Milk, Eggs, Peanuts, Tree nuts (e.g., almonds, walnuts, cashews), Soy, Wheat, Fish, Shellfish (e.g., shrimp, crab, lobster).",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "Allergens specific IgE Screening (Food panel)",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "foodprint",
        "packageTitle": "Food Print",
        "packagePrice": "1150",
        "packageImage": "https://sanar-assets.com/mis/6d80862e70b33aef6d79b501f29b93be.png",
        "packageDesc": "Blood test that can measure your immune system's response to particular foods by measuring the allergy-related antibody known as immunoglobulin E (IgE)",
        "instruction1": "This test does not require fasting.",
        "totalTest": "0",
        "test1": "",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    },
    {
        "id": "inhalant",
        "packageTitle": "Inhalant Panel",
        "packagePrice": "",
        "packageImage": "https://static.sanar.sa/mis/c51f05fbc2ea418fe9a9ad989815bdbe.jpg",
        "packageDesc": "Blood tests that measures the levels of different IgE antibodies in a person's blood. Allergen-specific IgE tests are sometimes used to diagnose and better manage food allergies.includes a variety of common allergens that are inhaled, such as:Pollens, Dust mites, Animal dander, Mold spores, Cockroach allergens, and other indoor and outdoor allergens",
        "instruction1": "This test does not require fasting.",
        "totalTest": "1",
        "test1": "Allergens specific IgE Screening (Inhalant panel)",
        "test2": "",
        "test3": "",
        "test4": "",
        "test5": "",
    }
]