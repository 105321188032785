import React, { useState } from 'react';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import { Link } from 'react-router-dom';
import '../.././Style/Corporatewlness.css';

function ExpansionTile(props) {
  const [isExpanded, setIsExpanded] = useState(false);


  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [showText, setShowText] = useState(false);

  const toggleText = () => {
    setShowText(!showText);
  };


  return (
    <li className="sa-cursor sa-card active-border" style={{ borderRadius: "10px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <i className={isExpanded ? "icon-up-arrow" : "icon-down-arrow"} onClick={toggleExpansion}></i>
        <label htmlFor={props.id} style={{ flex: "1", fontWeight: "bold" }}>{props.title}</label>
        <div style={{ flex: "3" }}></div>
        <input
          type="checkbox"
          id={props.id}
          name={props.id}
          style={{ alignSelf: "flex-end" }}
          onChange={props.handleCheckboxChange}
          className="custom-checkbox"
        />
      </div>

      <div>
        <span className="sa-gray-lgt-clr">
          {props.description}
          {' '}
          {showText && <span style={{ color: "#9b9b9b" }}>{props.moreText}</span>}
          {' '}
          {props.moreText && (
            <span className="sa-link sa-ml5" style={{ color: "#26a9e0", textDecoration: "underline" }}>
              <span onClick={toggleText} style={{ cursor: 'pointer' }}>
                {showText ? "Hide details" : "More"}
              </span>

            </span>
          )}
        </span>
      </div>
      {isExpanded && (
        <div>
          <div className="sa-crp-incl">
            <span className="sa-primary-text">Includes:</span> {props.includes}
          </div>
        </div>
      )}
    </li>
  );
}

function Corparatewellnesscomponent() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    const checkboxes = document.querySelectorAll("input[type='checkbox']");
    const anyCheckboxChecked = Array.from(checkboxes).some((checkbox) => checkbox.checked);
    setIsChecked(anyCheckboxChecked);
  };

  return (
    <div>
      <Navbar />
      <div className="sa-container sa-content sa-corpt-wellness">
        <Link className="Home-link" to="/">
          <span className="sa-btn-back">
            <i className="icon-arrow-left"></i>



            <span >Back</span>
          </span>
        </Link>


        <br />
        <h4 className="wellnes-header sa-mob-none">Corporate Wellness</h4>
        <div className="sa-mob-header">
          <h5 className="sa-mob-block">Corporate Wellness</h5>
          <div className="sa-prog-grid">
            <p>
              <span>Step 1</span> of 2
            </p>
            <div className="sa-prog-bar">
              <div className="sa-prog-bar-per" style={{ width: "50%" }}></div>
            </div>
          </div>
        </div>
        <div className="sa-row">
          <div className="sa-col8 sa-col-md8 sa-col-sm7 sa-col-xs12" style={{ width: "786.67", height: "426.01" }}>

            <h6 className="my-heading">      We provide the best corporate{' '}
              <span className="sa-primary-text">health check-up packages</span> specially designed{' '}
              <span className="sa-primary-text">for employees</span> working for different sectors     </h6>
            <span className="sa-ser-offer">Service We Offer</span>
            <ul className="sa-card-list sa-wellness" >
              <ExpansionTile
                id="sup"
                title="In-house Clinic"
                description="Our in-house clinic offers a comprehensive range of healthcare services to meet"
                includes="Provide doctors and nurses, laboratory services, Individual medical records, supplying all required medicine and pharmaceutical equipment, referral services, non-urgent medical transfers, seasonal and essential vaccinations and health and nutrition awareness."
                handleCheckboxChange={handleCheckboxChange}
                moreText=" the needs of individuals within your organization"
              />
              <ExpansionTile
                id="sup2"
                title="Pre-Employment Checkup"
                description="Pre employment checkup service provided at the company office or new employee "
                includes="Medical checkup, Chest X-ray, Blood test and Doctor consultation."
                handleCheckboxChange={handleCheckboxChange}
                moreText="location"
              />
              <ExpansionTile
                id="sup3"
                title="Employee Checkup"
                description="Includes a physical exam, laboratory tests, and a consultation with a doctor at"
                includes="Physical exam, Blood test, Drug test and Doctor consultation"
                handleCheckboxChange={handleCheckboxChange}
                moreText=" company site."
              />
            </ul>
          </div>
          <div className="sa-col4 sa-col-md4 sa-col-sm5 sa-col-xs12">
            <div className="sa-selected-data sa-wellness" style={{ padding: "20px", borderRadius: "5px" }}>
              <div className="sa-item-btns">
                <Link className="request-service-link" to="/request-service-form"> <button className="sa-btn-primary sa-btn-block" style={{ borderRadius: "5px", width: "343px", background: isChecked ? "#26a9e0" : "#c2c9e1", }} disabled={!isChecked}>Request service</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Corparatewellnesscomponent;
