import React, { useState } from 'react'
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import '../../Style/Vitamincenter.css'
import taibah from '../../assets/img/taibah.jpeg';
import female from '../../assets/img/female.png';
import { Link } from 'react-router-dom';
import TasksModal from './TasksModal';
function CustomInput({ value, onClick }) {
    return (
        <div className='input-group'>
            <div className='input-group-append'>
                <span className='input-group-text'>
            
                </span>

            </div>
            <input type="text" className='for-control' value={value} onClick={onClick} readOnly />

        </div>
    )
}


function VitaminCenter() {
    const [selectedDate, setDate] = useState(null)
    const [selectedGender, setGender] = useState('any');

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };
    return (
        <div >
            <Navbar />
            <div className="browse lab-browse" style={{ marginTop: "170px" }}>
                <div className="browse-hdr">
                    <div className="srch-field mob-none"></div>
                    <div className="container">
                        <div className="dp__main dp__theme_light date-picker">
                            <span tabindex="-1"></span>


                            <div className="datepicker" >
                                <label >
                                    <Datepicker selected={selectedDate} onChange={date => setDate(date)} customInput={<CustomInput />} />
                                </label>
                            </div>

                            <span tabindex="-1"></span>
                        </div>
                        <Link className="care-giver" to="/vitamin/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04">
                            <span className="btn-back">
                                <i className="icon-arrow-left">

                                </i>
                                <span style={{ fontWeight: "bold" }}>Back</span>
                            </span>
                        </Link>
                        <div className="lab-srch mob-block">
                            <span className="srch-text">
                                "Search "
                                <i className="icon-search"></i>
                            </span>
                            <span className="lab-pos">
                                <i className="icon-arrow-left"></i>
                                <i className="icon-search form-icon"></i>
                            </span>
                        </div>
                        <div className="srch-field mob-none">
                            <i className="icon-search form-icon form-icon-left"></i>
                            <input type="text" className="form-control form-control-sm" autocomplete="off" required placeholder="Search center" id="search" name="search" style={{ width: "524.18px" }}></input>

                        </div>
                        <div className="horizontal-calendar" style={{ marginLeft: "-610px", margin: "10px" }}>
                            <div className="date-list" id="dragCalender" >
                                <div className="date-list-scroll">
                                    <div className="date-item choosed-day"><div><p className="today">TODAY</p><p className="date-item-day">Today</p> <p className="date-item-date">14</p> </div> </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Tue</p> <p className="date-item-date">15</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Wed</p> <p className="date-item-date">16</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Thu</p> <p className="date-item-date">17</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">18</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Sat</p> <p className="date-item-date">19</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Sun</p> <p className="date-item-date">20</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">21</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">22</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">23</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">24</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">25</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">26</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">27</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">28</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">29</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">30</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">31</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">1</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">2</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">3</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">4</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">5</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">6</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">7</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">8</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">9</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">10</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">11</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">12</p> </div>  </div>
                                    <div className="date-item"><div><p className="today">TODAY</p> <p className="date-item-day">Fri</p> <p className="date-item-date">13</p> </div>  </div>



                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container">
                        <div className="prog-grid">
                            <p>
                                <span>Step 2</span>
                                of 4
                            </p>
                            <div className="prog-bar">
                                <div className="prog-bar-per" style={{ "width": "50%" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container result">
                    <div className="row">
                        <div className="col4 col-md4 col-sm4 col-xs12">
                            <div className="filter-data mob-none">
                                <div className="filter" style={{ borderRadius: "10px" }}>
                                    <h4 className="tit" style={{ 'padding': "20px" }}>Filters</h4>
                                    <div className="filter-list mob-none">
                                        <h5 className="primary-dark-text tit" style={{ 'margin': "10px", padding: "10px" }}>Price</h5>
                                        <label for="low">
                                            <input type="radio" id="low" name="price" value="0" style={{ position: "static", marginLeft: "20px", marginRight: "10px" }}></input>

                                            Low to high
                                        </label>
                                        <label for="high">
                                            <input type="radio" id="high" name="price" value="1" style={{ position: "static", marginRight: "10px", marginLeft: "20px", marginBottom: "30px" }}></input>

                                            High to low
                                        </label>

                                    </div>
                                </div>

                                <div className="filter" style={{ borderRadius: "10px" }}>
                                    <div className="filter-list mob-none" >
                                        <h5 className="primary-dark-text mb15 mob-none" style={{ 'margin': "10px", marginTop: "10px", padding: "10px" }}>Select Gender</h5>
                                        <label for="any" >
                                            <input type="radio" id="any" name="gender" value="any" checked={selectedGender === 'any'} onChange={handleGenderChange} style={{ position: "static", marginLeft: "20px", marginRight: "10px" }} ></input>

                                            <i className="icon-other mob-block"></i>
                                            Any
                                        </label>
                                        <label for="male" class>
                                            <input type="radio" id="male" name="gender" value="male" checked={selectedGender === 'male'} onChange={handleGenderChange} style={{ position: "static", marginLeft: "20px", marginRight: "10px" }}></input>

                                            <i className="icon-male-lab mob-block"></i>
                                            Male
                                        </label>
                                        <label for="female" class>
                                            <input type="radio" id="female" name="gender" value="female" checked={selectedGender === 'female'} onChange={handleGenderChange} style={{ position: "static", marginLeft: "20px", marginRight: "10px", marginBottom: "20px" }}></input>


                                            <i className="icon-female-lab mob-block"></i>
                                            Female
                                        </label>






                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col8 col-md-8 col-sm-8 col-xs-12">
                            {selectedGender === 'male' && (
                                <ul>
                                    <h4 className="tit mob-header">Select Center</h4>
                                    <div className="result-error" style={{ marginLeft: "130px" }}>
                                        <img src={female} alt="" className="w30" ></img>
                                        <h4 style={{ marginLeft: "50px" }}>No nurse's available for Today !</h4>
                                        <button type="submit" className="btn-primary mt20" style={{ margin: "50px", marginLeft: "130px", padding: "15px", paddingLeft: "30px", paddingRight: "30px", borderRadius: "10px" }}>Check next availability</button>
                                    </div>
                                </ul>
                            )}
                            {(selectedGender === 'female' || selectedGender === 'any') && (
                                <ul className="card-list">
                                    <h4 className="tit mob-header">Select Center</h4>
                                    <li className='border-line'>
                                        <div className="card-list-box" >
                                            <figure className="avtar avtar-lg cursor" style={{ marginLeft: "0px" }}>
                                                <img alt="" src={taibah} className="img-cover" style={{ margin: "10px", padding: "0" }} />
                                            </figure>
                                            <div className="doct-det" style={{ marginLeft: "-40px", marginTop: "-30px" }}>
                                                <div className="doct-det-data">
                                                    <h5 style={{ marginTop: "35px" }}>Taibah Care </h5>
                                                    <span>Vitamin IV Drips - Home Visit</span>
                                                    <div className="link mob-split" data-bs-toggle="modal" data-bs-target="#TasksModal" >View Task Details</div>

                                                </div>
                                                <div className="doct-price mob-split-lab" style={{ marginTop: "20px" }}>
                                                    <span className="dark-text font-normal mob-none" >Price</span>
                                                    <h6 className="price-tag swap-dir" style={{ margin: "0", padding: "0" }}>
                                                        <span className="d-i-dir-ltr">SAR</span>
                                                        1700
                                                    </h6>
                                                    <span className="gray-dark-text font-normal" style={{ margin: "0", padding: "0" }}>Included visit fee</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="carousel-slide">
                                            <ul className="carousel slot" id="carousel0" style={{ "scroll-behavior": "auto", marginLeft: "10px" }}>
                                                <li><p className="time-slot">03:30 pm</p></li>
                                                <li><p className="time-slot">04:00 pm</p></li>
                                                <li><p className="time-slot">04:30 pm</p></li>
                                                <li><p className="time-slot">05:00 pm</p></li>
                                                <li><p className="time-slot">05:30 pm</p></li>
                                                <li><p className="time-slot">06:00 pm</p></li>
                                                <li><p className="time-slot">06:30 pm</p></li>
                                                <li><p className="time-slot">07:00 pm</p></li>
                                                <li><p className="time-slot">07:30 pm</p></li>
                                                <li><p className="time-slot">08:00 pm</p></li>
                                                <li><p className="time-slot">08:30 pm</p></li>
                                                <li><p className="time-slot">03:30 pm</p></li>
                                                <li><p className="time-slot">03:00 pm</p></li>
                                                <li><p className="time-slot">03:00 pm</p></li>
                                                <li><p className="time-slot">03:00 pm</p></li>
                                                <li><p className="time-slot">03:00 pm</p></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <TasksModal />
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default VitaminCenter
