export const doctorData = [
    {
        "id": 1,
        "doctorImage": "https://static.sanar.sa/mis/d98dab30db157dff2981d2def5d380b1.JPG",
        "doctorName": "Nora Brazanji",
        "doctorDesig": "General Practitioner",
        "doctorExp": "9 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "50"
    },
    {
        "id": 2,
        "doctorImage": "https://static.sanar.sa/mis/418d392e7a8c37918cb51ec7fd8c4966.jpeg",
        "doctorName": "Majed Alanazi",
        "doctorDesig": "General Practitioner",
        "doctorExp": "7 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "30"
    },
    {
        "id": 3,
        "doctorImage": "https://static.sanar.sa/mis/d335e5569b692a828f1fdeef83be6e23.JPG",
        "doctorName": "Ayman Alhwaykem",
        "doctorDesig": "General Practitioner",
        "doctorExp": "10 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "40"
    },
    {
        "id": 4,
        "doctorImage": "https://static.sanar.sa/mis/cb21049d1107bfb7ad2afcedfb6115a3.png",
        "doctorName": "Saad Alyosefi",
        "doctorDesig": "General Practitioner",
        "doctorExp": "3 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "70"
    },
    {
        "id": 5,
        "doctorImage": "https://static.sanar.sa/mis/18ff9068986c2d9bfb9784abb1aae2ae.jpeg",
        "doctorName": "Ghdeer Alalawi",
        "doctorDesig": "General Practitioner",
        "doctorExp": "5 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "45"
    },
    {
        "id": 6,
        "doctorImage": "https://static.sanar.sa/mis/785d990ff56fc824b077e21f24f660d8.jpg",
        "doctorName": "Baraah Anwar",
        "doctorDesig": "General Practitioner",
        "doctorExp": "4 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "30"
    },
    {
        "id": 7,
        "doctorImage": "https://static.sanar.sa/mis/ebefc8be138d8661eba8b9a5f2545357.jpg",
        "doctorName": "Adel Albeladi",
        "doctorDesig": "General Practitioner",
        "doctorExp": "6 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "20"
    },
    {
        "id": 8,
        "doctorImage": "https://static.sanar.sa/mis/78dad16b53adab1911e29db136c5fcdf.png",
        "doctorName": "Laila Alotaibi",
        "doctorDesig": "General Practitioner",
        "doctorExp": "3 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "55"
    },
    {
        "id": 9,
        "doctorImage": "https://static.sanar.sa/mis/e524fe2bc1c415b85812382c076e5f2f.PNG",
        "doctorName": "Abdulrahman Qutub",
        "doctorDesig": "General Practitioner",
        "doctorExp": "15 years",
        "teleMedicine": "15 min",
        "currency": "SAR",
        "price": "59"
    }
]