import React from 'react'
import '../../Style/Doctor_Specialist.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import { useNavigate} from "react-router-dom";

function Hospital_Health_Center() {
    let navigate = useNavigate();
  return (
    <>
    <Navbar />
    <div className="container glob-prgh top-space">
        <div>
            <div className="card-shadow service-prov" style={{top: "77px",
    marginBottom: "115px"}}>
                <span className="btn-back mob-block">
                    <i className="icon-arrow-left"></i>
                </span>
                <h4 className="tit mob-header mob-block">Provide my sanar</h4>
                <div className="text-center">
                    <h1 className="h3">Provide my services via Sanar Request form</h1>
                        <p> We are pleased to cooperate with us in providing and facilitating access to your services Via Sanar platform</p>
                </div>
                <ul className="track-order">
                    <li className="accepted">
                        <span className="track-dot icon-checked"></span>
                    </li>
                    <li className="packed">
                        <span className="track-dot fill-dot"></span>
                    </li>
                    <li>
                        <span className="track-dot"></span>
                    </li>
                </ul>
                <form novalidate="">
                    <div className="row gutter5">
                        <div className="col-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label>Hospital / center Name</label>
                                <input type="text" placeholder="Hospital / center Name" className="form-control" id="hospital" name="Hospital / center Name"></input>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label>Commercial registration number</label>
                                <input type="text" placeholder="Commercial registration number" className="form-control" id="regNum" name="Commercial registration number"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row gutter5">
                        <div className="col-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Country</label>
                                <input className="form-control cursor" placeholder="Select Country" readonly="" id="country" name="Select Country"></input>
                                <i className="icon-down-arrow form-icon-cc"></i>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>City</label>
                                <input className="form-control cursor" placeholder="Select City" id="city" name="Select City"></input>                                    
                                <i className="icon-down-arrow form-icon-cc"></i>
                            </div>
                        </div>
                    </div>
                    <div className="ccode">
                        <label>Phone number</label>
                        <div className="row gutter10">
                            <div className="col-3 col-md-3 col-sm-3 col-xs-5">
                                <div className="form-group">
                                    <img className="form-icon-jb form-img-jb" src="https://sanar-assets.com/flags/sa_64.png" alt=''></img>
                                        {/* <input type="text" id="countrycode" name="countrycode" className="form-control inp-flag" readonly=""></input> */}
                                        <select className="form-control inp-flag" readOnly="" id="countryCode" name="countryCode" placeholder="Phone Number">
                                            <option value="+91">+91</option>
                                            <option value="+..">+..</option>
                                            <option value="+..">+..</option>
                                            <option value="+..">+..</option>
                                        </select>
                                        <i className="icon-down-arrow form-icon-jb"></i>
                                </div>
                            </div>
                            <div className="col-9 col-md-9 col-sm-9 col-xs-7">
                                <div className="form-group">
                                    <input maxlength="15" type="tel" placeholder="Mobile Number" className="form-control" id="mnumber" name="Mobile Number"></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gutter10">
                        <div className="col-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>E-mail ID</label>
                                <input type="email" className="form-control" placeholder="E-mail ID" id="email" name="E-mail ID"></input>
                            </div>
                        </div>
                    </div>
                    {/* <div className="pag-btn">
                        <span className="cursor">
                            <button type="button" className="btn-primary">
                                <i className="icon-arrow-left"></i>
                            </button>
                            <span className="primary-text ml10">Previous</span>
                        </span>
                        <span className="cursor">
                            <span className="primary-text mr10">Next</span>
                            <button type="button" className="btn-primary">
                                <i className="icon-arrow-right"></i>
                            </button>
                        </span>
                    </div> */}
                    <div className="d-flex justify-content-around">
                        <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>
                            <i className="icon-arrow-left"></i>
                            <span className="ml-2">Previous</span>
                        </button>
                        <button type="button" className="btn btn-primary">
                            <span className="mr-2">Next</span>
                            <i className="icon-arrow-right"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default Hospital_Health_Center