import React from 'react'
import '../.././Style/Herosection.css'

function Container() {
    return (
        <div>
            <div className="sa-container sa-our-vision sa-top120">
                <div className="sa-row sa-gutter20" >

                    <div className="col-md-6 col-sm-6 col-xs-12 sa-d-f-a-stretch" >
                        <div className="sa-card sa-card-xlg sa-card-shadow" style={{ 'padding': "30px" }}>
                            <div className="sa-common-title sa-gap30" style={{ 'display': "flex" }}>
                                <i className="icon-eye sa-icon-bg"></i>
                                <h5 className="h5">Our Vision</h5>
                            </div>
                            <p className="sa-f-size18 sa-icon-space85" style={{ 'paddingLeft': "50px", 'lineHeight': "2.5" }}>
                                To be the best provider of integrated and reliable medical services; Providing solutions that raise the quality of healthy life for the individual and society.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 sa-d-f-a-stretch" style={{ 'paddingLeft': "50px" }}>
                        <div className="sa-card sa-card-xlg sa-card-shadow" style={{ 'padding': "30px" }}>
                            <div className="sa-common-title sa-gap30" style={{ 'display': "flex" }}>
                                <i className="icon-target sa-icon-bg"></i>
                                <h5 className="h5">Our Mission</h5>
                            </div>
                            <p className="sa-f-size18 sa-icon-space85" style={{ 'paddingLeft': "50px", 'lineHeight': "2.5" }}>
                                Providing high quality home medical care services in a wide geographical distribution and an effective medical digital solutions for medical consultations and a unified EMR.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Container
