import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../Style/login_popup.css';

function Signup() {
    const initialValues = {
        fullName: "",
        lastName: "",
        mobileNumber: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [selectedCountry, setSelectedCountry] = useState('pk');
    const countries = [
        { code: 'in', name: 'India', countryCode: '+91' },
        { code: 'sa', name: 'Saudi Arabia', countryCode: '+966' },
        { code: 'eg', name: 'Egypt', countryCode: '+20' },
        { code: 'au', name: 'Australia', countryCode: '+61' },
        { code: 'cn', name: 'Canada', countryCode: '+1' },
        { code: 'fr', name: 'France', countryCode: '+33' },
        { code: 'pk', name: 'Pakistan', countryCode: '+92' },
        { code: 'sl', name: 'Sri Lanka', countryCode: '+94' }
    ];

    const validate = (values) => {
        const errors = {};
        var mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (!values.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required!";
        } else if (!mobileNumberRegex.test(values.mobileNumber)) {
            errors.mobileNumber = "This is not a valid mobile number format!";
        }

        if (!values.fullName.trim()) {
            errors.fullName = "Full Name is required!";
        }

        if (!values.lastName.trim()) {
            errors.lastName = "Last Name is required!";
        }

        return errors;
    }

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = validate(formValues);

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        }
        else {
            const formData = {
                fullName: formValues.fullName,
                lastName: formValues.lastName,
                mobileNumber: formValues.mobileNumber,
                countryCode: selectedCountry
            };

            fetch('https://zwarra.biztechnosys.com/api/register/step1?name=mayank&lastname=sheladiya&phone=9879879871', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(formData);
                })
                .catch(error => {
                    console.error('There was a problem with the request:', error);
                });
        }
    };
    return (
        <div className='zw_popup'>
            <div className="modal fade" id="signup" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="card card-primary-light card-no-border last-child">
                                <div className="mob-header mob-block">Verification</div>
                                <div className="login-brd">
                                    <form>
                                        <div className="input-group input-group-vert mb20">
                                            <div className="form-group zw_form_group">
                                                <input type="text" className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='Full Name' name="fullName" value={formValues.fullName} onChange={handleInputChange}></input>
                                                {formErrors.fullName && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.fullName}</span>}
                                            </div>
                                            <div className="form-group zw_form_group">
                                                <input type="input" className='form-control zw_form_control zw_secondary poppins-regular zw_16' placeholder='Last Name' name="lastName" value={formValues.lastName} onChange={handleInputChange}></input>
                                                {formErrors.lastName && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.lastName}</span>}
                                            </div>
                                            <div className="form-group ">
                                                <div className='row zw_form_control m-0 '>
                                                    <div className='col-md-3 zw_light_bg zw_border_radius'>
                                                        <div style={{ display: "flex" }} className=' zw_secondary poppins-regular zw_16'>
                                                            <select className="form-control zw_country_select" value={selectedCountry} onChange={handleCountryChange}>
                                                                {countries.map(country => (
                                                                    <option key={country.code} value={country.code}>
                                                                        {country.countryCode}
                                                                        <img
                                                                            className="form-icon img-ccode form-img zw_country_flag"
                                                                            src={`https://sanar-assets.com/flags/${country.code}_64.png`}
                                                                            alt={`Flag of ${country.name}`}
                                                                        />
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <input type="tel" className={` zw_secondary poppins-regular zw_16 form-control zw_border_none err-border`} id="mobile_number" name="mobileNumber" placeholder='Phone Number' value={formValues.mobileNumber} onChange={handleInputChange}></input>

                                                    </div>
                                                    {formErrors.mobileNumber && <span className="sa-error-message" style={{ 'color': "red" }}>{formErrors.mobileNumber}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div data-bs-dismiss="modal" aria-label="Close" className="form-group f-size12 poppins-regular zw_16">By clicking continue you agree to our&nbsp;
                                            <Link to="/termsandcondition" className="link">Terms &amp; Conditions</Link>{''} and{''} <Link to="/privacypolicy" className="link">Privacy Policy</Link>
                                        </div>
                                        <button type="button" className="btn-primary btn-lg poppins-regular zw_bg_gradient zw_btn_18 zw_sbmtbtn_radius" onClick={handleSubmit} data-bs-toggle="modal" data-bs-target="#accountsetup_details">
                                            Continue
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Export the component
export default Signup;
	