
import React from 'react';

import '../../Style/Tasksmodal.css';

function TasksModal({ selectedVaccinations }) {
    return (
        <div>
            <div className="modal fade" id="TasksModal" data-bs-backdrop='static' role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button className="z-sign-btn" data-bs-dismiss="modal" aria-label="Close">
                                <i className="icon-close"></i>
                            </button>
                            <div className="body-cnt">
                                <h4 className="tit">Selected task</h4>

                                {/* {selectedVaccinations && selectedVaccinations.length > 0 ? (
                                    <>
                                        <div className="gray-light-bg card-no-border">
                                            <h5>Instructions</h5>
                                            <ul className="unorder-list">
                                                <li>
                                                    The procedure takes about 40 to 60 minutes, and the injection is done at home or workplace.
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>Selected Vaccinations:</p>
                                            <ul className="unorder-list">
                                                {selectedVaccinations.map((vaccination) => (

                                                    <li key={vaccination.id}>{vaccination.label}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <p>No vaccinations selected.</p>
                                )} */}
                                {selectedVaccinations && selectedVaccinations.length > 0 ? (
                                    <>
                                        <div className="gray-light-bg card-no-border">
                                            <h5>Instructions</h5>
                                            <ul className="unorder-list">
                                                <li>
                                                    The procedure takes about 40 to 60 minutes, and the injection is done at home or workplace.
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>Selected Vaccinations:</p>
                                            <ul className="unorder-list">
                                                {selectedVaccinations.map((vaccination) => (
                                                    <li key={vaccination.id}>{vaccination.label}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <p>No vaccinations selected.</p>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TasksModal;
