import React from 'react'
import '../../Style/header_part.css';
import '../../Style/Service_prvder.css'

function Header_part() {
    return (
        <div>
            {/* First part */}
            <div className='join-prvder mon-none'>
                <div className='container-sm'>
                    <div className='prvder-tit'>
                        <h1 className='h1'>Participate in facilitating access to medical services in modern and flexible ways with zwaara</h1>
                        <div className='join-trst'>
                            <div className='d-flex'>
                                <figure>
                                    <img src="https://static.sanar.sa/mis/49773b4732e9522bf0252e6aa63edce6.jpg" alt=''></img>
                                </figure>
                                <figure>
                                    <img src="https://static.sanar.sa/mis/e00eae6832c701fdd63165e02329d92d.jpg" alt=''></img>
                                </figure>
                                <figure>
                                    <img src="https://static.sanar.sa/mis/169b2810014d392dc2c8aa57c99924ed.PNG" alt=''></img>
                                </figure>
                                <figure>
                                    <img src="https://static.sanar.sa/mis/f570c6f279bbd424a24e6dadd5b04ba8.PNG" alt=''></img>
                                </figure>
                                <figure>
                                    <img src="https://static.sanar.sa/profilepic/641550d9fc95bac56e177deb6a76664d.jpeg" alt=''></img>
                                </figure>
                                <figure>
                                    <img src="https://static.sanar.sa/mis/e8c3c1c1a9a007d948f0eab423c94f26.jpeg" alt=''></img>
                                </figure>
                            </div>
                            <p>Trusted by over healthcare providers in the country</p>
                        </div>
                    </div>
                </div>
            </div>

            <span className="sa-btn-back sa-mob-block">
                <i className="icon-arrow-left"></i>
            </span>
            <h4 className="sa-tit sa-mob-header sa-mob-block">Join as provider</h4>


            {/* second part */}
            <div className="join-serv top120">
                <h2 className="h2">How to join as a service provider</h2>
                <ul className="pjoin-list">
                    <li className="common-title">
                        <i className="icon-report3 icon-bg icon-bg-lg">
                            <small>1</small></i>
                        <h5 className="pt10 sa-pb10 h5">fill form and Submit your data</h5>
                        <i className="icon-arrow-right-thick"></i>
                    </li>
                    <li className="common-title">
                        <i className="icon-call-center-agent icon-bg icon-bg-lg">
                            <small>2</small>
                        </i>
                        <h5 className="pt10 pb10 h5">Sanar team will Contact you</h5>
                        <i className="icon-arrow-right-thick"></i>
                    </li>
                    <li className="common-title">
                        <i className="icon-correction icon-bg icon-bg-lg">
                            <small>3</small>
                        </i>
                        <h5 className="pt10 sa-pb10 h5">Start receiving orders more widely</h5>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header_part