export const sortpackage = [
    {
        "id": "women",
        "name": "Women's Health"
    },
    {
        "id": "men",
        "name": "Men's Health"
    },
    {
        "id": "chronic",
        "name": "Chronic Diseases"
    },
    {
        "id": "allergies",
        "name": "Allergies and digestive problems"
    },
    {
        "id": "bone",
        "name": "Bone health and rheumatism"
    },
    {
        "id": "blood",
        "name": "Blood Health"
    },
    {
        "id": "diet",
        "name": "Diet"
    },
    {
        "id": "infectious",
        "name": "Infectious diseases"
    }
]