import React from 'react'
import '../../Style/CareBussiness.css'


function BussinessBanner() {
  return (
   
        <div className="bb-business-bg">
            <div className="bb-container bb-pos-rel">
                <div className="bb-row bb-guuter20">
                    <div className="col-md-8">
                        <div className="bb-busnes">
                            <h1 className="bb-tit bb-mob-none h1"><b>Sanar Business</b></h1>
                            <h2 className="h2">Your partner to enhance your employee's health and well-being</h2>
                            <p className="bb-description">
                                Medical solutions for companies that aim to provide medical services to their employee to maintain and improve their health by providing wide coverage to allow them obtain medical services anywhere and at any time.
                            </p>
                        </div>
                        <button className="btn btn-primary bb-mt30 bb-btnw100">Contact us now</button>
                        <div className="bb-ministry">
                            <img src="https://sanar-assets.com/images/log.png" alt="zwaraa licence" className="bb-avtar-sm"></img>
                            A platform approved by the Saudi Ministry of health
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src="https://sanar-assets.com/images/static/new-theme/sanar-business-hero-section.png" alt="" className="bb-business-section bb-mob-none"></img>
                    </div>
                </div>
            </div>
        </div>
 
  )
}

export default BussinessBanner
