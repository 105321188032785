import React from 'react'
// import { Link } from 'react-router-dom'
import '../../../Style/Addpatient.css'
import '../../../Style/Ourfeaturetestspopup.css'
function Addpatient() {
    return (
        <div>
            <div className='zw_popup' >
                <div className="modal fade zw_Healthpackage" id="LogInPopUpModal41" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='btn-space'>
                                    <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="icon-close"></i>
                                    </button>
                                </div>
                                <div className="full-body-Zqe">
                                    <div className="group-1261154722-hXA">
                                        {/* <img className="group-1261154609-qNU" src="/images/group-1261154609.png" alt='' /> */}
                                        <p className="add-patient-yzU">Add Patient</p>
                                        <div className="group-1261154720-Vhv">
                                            <div className="auto-group-ygza-pVJ">
                                                <div className="auto-group-vu2u-N12">
                                                    <div className="group-1261154715-Upk">
                                                        <div className="group-1261154698-DXS">
                                                            <p className="first-name-AxU">First Name</p>
                                                            <div className="auto-group-vbfz-uv4">First Name</div>
                                                        </div>
                                                    </div>
                                                    <div className="group-1261154716-BMn">
                                                        <p className="last-name-8np">Last Name</p>
                                                        <div className="group-1261154699-qhE">
                                                            <p className="last-name-yYY">Last Name</p>
                                                            <div className="rectangle-39578-im2">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="auto-group-zghs-RvL">
                                                    <div className="group-1261154700-MJC">
                                                        <p className="nationality-hN4">Nationality</p>
                                                        <div className="auto-group-vwdn-ovt">
                                                            <p className="selected-nationality-69J">Selected Nationality</p>
                                                            <img className="vector-LJY" src="/images/vector-ndA.png" alt='' />
                                                        </div>
                                                    </div>
                                                    <div className="group-1261154713-2x4">
                                                        <p className="passport-id-number-P1v">Passport/ID number</p>
                                                        <div className="group-1261154701-gmi">
                                                            <p className="passport-id-number-EYL">Passport/ID number</p>
                                                            <div className="rectangle-39580-n44">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="auto-group-etlp-VUG">
                                                <div className="group-1261154702-2yz">
                                                    <p className="gender-Bbz">Gender</p>
                                                    <div className="auto-group-b9de-vZa">
                                                        <img className="vector-ry2" src="/images/vector-ndA.png" alt='' />
                                                    </div>
                                                </div>
                                                <div className="group-1261154703-z3e">
                                                    <p className="dob-8vY">DOB</p>
                                                    <div className="rectangle-39579-UUc">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="group-1261154704-1De">
                                                <p className="blood-group-Zkx">Blood Group</p>
                                                <div className="auto-group-z1de-hsA">
                                                    <img className="vector-qyN" src="/images/vector-ndA.png" alt='' />
                                                </div>
                                            </div>
                                            <div className="auto-group-819n-ZPa">
                                                <div className="group-1261154945-6PW">
                                                    <p className="mobile-number-optional-FGQ">Mobile Number (Optional)</p>
                                                    <div className="group-1261154944-nXE">
                                                        <div className="group-1261154710-LYk">
                                                            <img className="rectangle-39628-3i4" src="/images/rectangle-39628.png" alt='' />
                                                            <p className="item-966-NVS">+966</p>
                                                            <img className="vector-6gL" src="/images/vector-ndA.png" alt='' />
                                                        </div>
                                                        <div className="group-1261154711-DW4">Enter Mobile Number</div>
                                                    </div>
                                                </div>
                                                <div className="group-1261154709-tMJ">
                                                    <div className="group-1261154706-3EC">
                                                        <p className="relationship-zv8">Relationship</p>
                                                        <div className="auto-group-ij8u-8mS">
                                                            <p className="relationship-rxL">Relationship</p>
                                                            <img className="vector-PxG" src="/images/vector-ndA.png" alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="poppins-regular zw_16 zw_000">
                                                    By clicking add patient you ,  agree to our <a className='zw_title_color' href='#'>Terms &amp; Condition</a> and <a className='zw_title_color' href='#'>Privacy
                                                        policy</a> 
                                                    {/* <span className="poppins-regular zw_14 zw_000">add patient</span>
                                                    <span className="by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-2"> you</span>
                                                    <span className="by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-3">,</span>
                                                    <span className="by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-4"> agree to our </span>
                                                    <span className="by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-5">Terms &amp; Condition</span>
                                                    <span className="by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-6"> and</span>
                                                    <span className="by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-7">
                                                        Privacy
                                                        <br />
                                                        policy
                                                    </span> */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
        </div>
    )
}
 
export default Addpatient
 