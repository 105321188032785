import React from 'react'
import '../../Style/Virtual.css'
import { useTranslation } from 'react-i18next'

function HomeBodyCard() {
    const { t } = useTranslation();
    return (
        <>

            <div className='container'>
                <div className="mb-3 z-mob-none" >
                    <div className="row g-0">
                        <div className='col-md-12 mt-4 mb-4'> <h2 className='poppins-semibold zwaara_title'>About Us</h2></div>

                        <div className="col-md-5 ">
                            <img src="images/about-left.svg" className="img-fluid z-card-img rounded-start" alt="..." />
                        </div>
                        <div className="col-md-7">
                            <div className="card-body">
                                <div className='z-virtual'>
                                    <div className='zw_title_color mb-3'><h1 className="card-title poppins-semibold  zw_16">Who We Are</h1></div>
                                    <p className="card-text poppins-regular zw_black">Wilt u de voordelen van zorg op maat ervaren? Ontdek hoe Daily Homecare u kan helpen om
                                        comfortabel en met zorg te leven. Meld u klsiksij juejk jkunweui vandaag nog aan en laat ons uw partner zijn
                                        op uw zorgreis. Wilt u de voordelen van zorg op maat ervaren? Ontdek hoe Daily Homecare u kan helpen om
                                        comfortabel en met zorg te leven. Meld u klsiksij juejk jkunweui vandaag nog aan en laat ons uw partner zijn
                                        op uw zorgreis.</p>

                                    <button className='zw_btn_primary zw_bg mt-4'>
                                        <span className='poppins-medium zw_btn_18 '>
                                            Learn More
                                        </span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeBodyCard