import { Link } from 'react-router-dom';
import React from 'react';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import background from '../../assets/img/background.png';
import '../../Style/Requestsuccesfull.css';

function Requestsuccesfull() {
    return (
        <div>
            <Navbar />
            <div className="sa-caregiver-payment">
                <img src={background} alt="" style={{ 'width': "100%", 'height': "334px", borderBottomRightRadius: "40px" }} />
            </div>

            <div class="sa-caregiver-pay">

                <div className="sa-caregiver-payment-in ">
                    <div className="sa-success-circle">
                        <i className="icon-checked2"></i>
                    </div>
                    <h4 >Thank you</h4>
                    <p >Your request has been submitted
                        <span class="sa-success-text sa-bold sa-d-block">successfully!</span>
                    </p>

                    <span className="sa-gray-text sa-wellness-con" >We will reach out to you shortly</span>
                </div>

                <div className="sa-mob-footer" >
                    <button className="sa-btn-primary sa-btn-lg sa-btn-block" > <Link className="Home-link" to="/" style={{ color: '#fff' }} >Home</Link></button>
                </div>

            </div>
            <Footer />

        </div>
    );
}

export default Requestsuccesfull;
