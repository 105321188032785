import React, { useState, useContext } from 'react'
import '../../../Style/Vaccination.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
// import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { data } from './Center_Vaccina.js';
// import ContextSelected from './ContextSelected.js';
import { VaccinationContext } from './ContextSelected.js';

function VaccinationList() {
  const [isItemSelected, setIsItemSelected] = useState('#26A9E0');
  const buttonColor = isItemSelected ? '#c2c9e1' : '#26A9E0';
  // const [selectedVaccinations, setSelectedVaccinations] = useState([]);
  const { selectedVaccinations, setSelectedVaccinations } = useContext(VaccinationContext);
  const handleVaccinationSelection = (e) => {
    const selectedVaccinationId = e.target.value;
    const selectedVaccinationLabel = e.target.parentElement.querySelector('label').textContent.trim();
    const selectedParagraph = e.target.parentElement.querySelector('p')?.textContent.trim();
    const anyItemSelected = selectedVaccinations.length < 0;
    setIsItemSelected(anyItemSelected);

    const isAlreadySelected = selectedVaccinations.some(
      (vaccination) => vaccination.id === selectedVaccinationId
    );

    if (!isAlreadySelected) {
      setSelectedVaccinations([
        ...selectedVaccinations,
        { id: selectedVaccinationId, li: selectedParagraph, label: selectedVaccinationLabel },
      ]);
    } else {
      const updatedSelectedVaccinations = selectedVaccinations.filter(
        (vaccination) => vaccination.id !== selectedVaccinationId
      );
      setSelectedVaccinations(updatedSelectedVaccinations);
    }
  };

  let navigate = useNavigate();

  // for search functionality
  const [search, setsearch] = useState('');

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setsearch(searchTerm);
  };

  const handleClick = () => {
    navigate('/vaccinationstep2');
  }
  return (
    <>
      <Navbar />
      <div className="container content lab-det top-space60 btm-space" style={{ marginTop: "70px" }}>
        <div className="loc-label">
          <span className="btn-back">
            <i className="icon-arrow-left" onClick={() => navigate(-1)}></i>
            <span className="mob-none">Back</span>
          </span>
          <div>
            <div className="input-group location-label">
              <button className="btn ellipsis">Selected Location</button>
              <i className="icon-location2"></i>
              <input type="text" placeholder="location" className="form-control ellipsis cursor"></input>
              <i className="icon-down-arrow form-icon"></i>
            </div>
          </div>
        </div>
        <div className="prog-grid">
          <p>
            <span>Step 1</span> of 4</p>
          <div className="prog-bar">
            <div className="prog-bar-per" style={{ width: "25%" }}>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8 col-md-8 col-sm-7 col-xs-12" style={{ marginBottom: "20px" }}>
            <div className="lab-srch mob-block top-fixed-search">
              <span className="srch-text">Search
                <i className="icon-search"></i>
              </span>
              <span className="lab-pos">
                <i className="icon-arrow-left"></i>
                <i className="icon-search form-icon"></i>
              </span>
            </div>
            <div className="nurse">
              <div className="row">
                <div className="col-6 col-md-6 col-lg-6">
                  <h4 className="tit mob-header">Vaccination List </h4>
                </div>
                <div className="col-6 col-md-6 col-sm-12 col-lg-6">
                  <div className="form-group search-box mob-none">
                    <i className="icon-search form-icon"></i>
                    <input id="search" name="search" onChange={handleSearch} type="text" placeholder="Search" className="form-control" required=""></input>
                  </div>
                </div>
              </div>
              <ul className=" card-list nurse-checkbox">
                {data.filter((item) => {
                  return search.toLowerCase() === '' ? item : item.title.toLowerCase().includes(search)
                }).map((item) => (
                  <li key={item.id}>
                    <div className="card-list-box">
                      <figure className="avtar avtar-lg">
                        <img src={item.image} className="img_cover" alt="img"></img>
                      </figure>
                      <div className="sa-doct-det">
                        <div className="f-label">
                          <label htmlFor={item.id}>{item.title}</label>
                          <input type="checkbox" id={item.id} value={item.id} name="children" onClick={handleVaccinationSelection}></input>
                        </div>
                        <p>{item.p1}</p>
                      </div>
                    </div>
                    <div className="status-card">{item.p2}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-4 col-md-4 col-sm-5 col-xs-12">
            <h4 className="tit mob-none">Selected Items</h4>
            <div className="selected-data">
              <div className="item-selected">
                <h4 className="mob-header mob-block">Selected services</h4>
                <ul>
                  {selectedVaccinations.map((vaccination, index) => (
                    <li key={index}>
                      <div className="f--label">
                        <label htmlFor={`selected_${vaccination.id}`}>{vaccination.label}</label>
                        <input
                          type="checkbox"
                          id={`selected_${vaccination.id}`}
                          checked={true}
                        />
                      </div>
                      <ul id={`selected_${vaccination.p2}`}>
                        <li>
                          {vaccination.p2}
                        </li>
                      </ul>
                      {/* {vaccination.p} */}
                    </li>
                  ))}
                </ul>
                <span className="btn-back mob-block">
                  <i className="icon-arrow-left"></i>
                  <div className="prog-grid">
                    <p><span>Step 1</span> of 4</p>
                    <div className="prog-bar">
                      <div className="prog-bar-per" style={{ width: "25%" }}>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="item-btns">
                {selectedVaccinations.length === 0 ?
                  <div className="z-item-btns" style={{ backgroundColor: buttonColor, textAlign: "center", borderRadius: "5px"}}>
                    <button className="btn" disabled> Continue </button>
                  </div>
                  :
                  <div className="z-item-btns" onClick={() => handleClick()} style={{ display: 'flex', color: "#FFF" }}>
                    <button className="btn btn-primary btn-block"> Continue </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default VaccinationList