import React, { useState } from 'react'
import '../../../Style/Doctorcard.css'
import DoctorcardList from '../Physiotherapist/DoctorcardList.json';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import TimeCarousel from '../Vaccination/TimeCarousel';

function Doctorcard() {
    const [startDate, setstartDate] = useState(new Date());
    const [Search, setSearch] = useState('');
    const [, setShowDoctorList] = useState(DoctorcardList);

    const setLowToHigh = () => {
        const sortedDoctorcardList = DoctorcardList.sort((a, b) => a.price - b.price);
        setShowDoctorList([...sortedDoctorcardList]);
    };
    const setHighToLow = () => {
        const reverseSortedDoctorcardList = DoctorcardList.sort((a, b) => b.price - a.price);
        setShowDoctorList([...reverseSortedDoctorcardList]);
    };

    let navigate = useNavigate();
    const availablePhysiotherapists = DoctorcardList.filter((Doctor) =>
        Search.toLowerCase() === '' ? Doctor : Doctor.name.toLowerCase().includes(Search)
    );
    return (
        <>
            <Navbar />
            <div className='browse lab-browse'>
                <div className='browse-hdr'>
                    <div className="container">
                        <span className="z-btn-back" style={{ display: "flex" }}>
                            <i className="icon-arrow-left" onClick={() => navigate(-1)}></i>
                            <span className="mob-none">Back</span>
                        </span>

                        <div className="z-srch-field z-mob-none">
                            <i className="icon-search z-form-icon z-form-icon-left"></i>
                            <input onChange={(e) => setSearch(e.target.value)} autoComplete="off" name="Search Doctor" type="text" placeholder="Search physiotherapist" className="form-control z-form-control-sm" />
                        </div>

                        <div className="horizontal-calendar">
                            <div className="z-date-list" style={{ scrollBehavior: 'auto' }}>
                                <div className="z-date-list-scroll">
                                    <div className="z-date-item z-choosed-day">
                                        <div>
                                            <p className="z-today"> Today </p>
                                            <p className="z-date-item-date"> 8 </p>
                                        </div>
                                    </div>
                                    <div className="z-date-item">
                                        <div>
                                            <p className="z-date-item-day"> Thu </p>
                                            <p className="z-date-item-date"> 9 </p>
                                        </div>
                                    </div>
                                    <div className="z-date-item">
                                        <div>
                                            <p className="z-date-item-day"> Fri </p>
                                            <p className="z-date-item-date"> 10 </p>
                                        </div>
                                    </div>
                                    <div className="z-date-item">
                                        <div>
                                            <p className="z-date-item-day"> Sat </p>
                                            <p className="z-date-item-date"> 11 </p>
                                        </div>
                                    </div>
                                    <div className="z-date-item">
                                        <div>
                                            <p className="z-date-item-day"> Sun </p>
                                            <p className="z-date-item-date"> 12 </p>
                                        </div>
                                    </div>
                                    <div className="z-date-item">
                                        <div>
                                            <p className="z-date-item-day"> Mon </p>
                                            <p className="z-date-item-date"> 13 </p>
                                        </div>
                                    </div>
                                    <div className="z-date-item">
                                        <div>
                                            <p className="z-date-item-day"> Tue </p>
                                            <p className="z-date-item-date"> 14 </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="z-date-picker">
                            <i className="fa fa-calendar z-cal" aria-hidden="true"></i>
                            <DatePicker className="custom-input" selected={startDate} onChange={(date) => setstartDate(date)} />
                        </div>

                    </div>
                    <div className="container">
                        <div className="prog-grid">
                            <p>
                                <span>Step 1</span> of 3
                            </p>
                            <div className="prog-bar">
                                <div className="prog-bar-per" style={{ width: "33.33%" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container result'>
                    <div className='row'>
                        <div className="col-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="filter-data mob-none">
                                <div className="filter">
                                    <h4 className="tit">Filters</h4>
                                    <div className="filter-list mob-none">
                                        <h5 className="primary-dark-text tit">Price</h5>
                                        <div className="form-check">
                                            <input className="form-check-input" value='low' type="radio" onClick={setLowToHigh} name="exampleRadios" id="exampleRadios1" />
                                            <label className="form-check-label">
                                                Low to High
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" value='high' type="radio" onClick={setHighToLow} name="exampleRadios" id="exampleRadios1" />
                                            <label className="form-check-label">
                                                High to Low
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 col-md-8 col-sm-8 col-xs-12">
                            <h4 className="tit mob-header">Physiotherapist</h4>
                            <div>
                                <div className="input-group location-label">
                                    <button className="btn ellipsis">Selected Location</button>
                                    <i className="icon-location2"></i>
                                    <input type="text" placeholder="location" className="form-control ellipsis cursor"></input>
                                    <i className="icon-down-arrow form-icon"></i>
                                </div>
                            </div>
                            {availablePhysiotherapists.length > 0 ? (
                                <ul className="card-list">
                                {DoctorcardList.filter((Doctor) => {
                                    return Search.toLowerCase() === '' ? Doctor : Doctor.name.toLowerCase().includes(Search)
                                }).map((Doctor) => (
                                    <li key={Doctor.id}>
                                        <div className="card-list-box">
                                            <figure className="avtar avtar-lg cursor">
                                                <img src={Doctor.image} alt={Doctor.name} />
                                            </figure>
                                            <div className="doct-det">
                                                <div className="doct-det-data">
                                                    <h6>{Doctor.name}</h6>
                                                    <span>{Doctor.services}</span>
                                                    <div className="mob-split">
                                                        <p>
                                                            <i className="icon-global"></i>Spoken :
                                                            {Doctor.languages}
                                                        </p>
                                                        <p>
                                                            <i className="icon-doctor-bag2"></i>
                                                            <span className="dark-text">{Doctor.location}</span>
                                                        </p>
                                                        <span className="dark-text">{Doctor.facility}</span>
                                                    </div>
                                                </div>
                                                <div className="doct-price mob-split-lab">
                                                    <span className="dark-text font-normal mob-none">Price</span>
                                                    <h6 className="price-tag swap-dir">
                                                        <span className="d-i-dir-ltr">SAR</span> {Doctor.price}
                                                    </h6>
                                                    <span className="gray-dark-text font-normal">Included visit fee</span>
                                                </div>
                                            </div>
                                        </div>
                                        <TimeCarousel />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="result-error">
                                <img src="https://sanar-assets.com/images/pics/physio_not_available.png" alt="Error Message" className="w30"></img>
                                <h4>No Physiotherapist's available for Today !</h4>
                                <button type="submit" className="btn-primary mt20">Check next availability</button>
                            </div>
                        )}
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Doctorcard