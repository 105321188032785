import React from 'react'
import ProgramImage from '../../images/sanar-business-program-section.jpg';

function BussinessProgram() {
    return (
        <div className="container">
            <div className="row">
                <div className="mb-3">
                    <div className="row g-0">
                        <div className="col-md-8">
                            <div className="bp-body">
                                <h1 className="bp-title"><b>Our programs for companies and groups</b></h1>&nbsp;
                                <p className="bp-decsription">Health setbacks do not only affect individuals, but also in the companies they work,insurance costs rise,morale declines,productivity,decreases,and other. Therefore, our solutions are part of today's business world, which is accelerating and new things have been imposed on employers to take more care and attention for their employee</p>&nbsp;
                                <ul className="bp-groups bp-mt50">
                                    <li><i className="icon-checked" ></i> <span>Medical and psychological counseling</span></li>
                                    <li><i className="icon-checked" ></i> <span>Chronic Disease Management Program</span></li>
                                    <li><i className="icon-checked" ></i> <span>Routine tests package for employees</span></li>
                                    <li><i className="icon-checked" ></i> <span>Seasonal vaccinations for employees</span></li>
                                    <li><i className="icon-checked" ></i> <span>Manage Health Certificate Reservations (Baladi)</span></li>
                                    <li><i className="icon-checked" ></i> <span>Hajj package for Hajj campaigns</span></li>
                                    <li><i className="icon-checked" ></i> <span>Pregnancy follow-up programme</span></li>
                                    <li><i className="icon-checked" ></i> <span>And more...</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={ProgramImage} className="bp-image" alt="..." />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BussinessProgram
