import React from 'react'
import '../.././Style/Herosection.css'
function Card() {
    return (
        <div>
            <div className="sa-container sa-our-vision sa-top120">
                <div className="sa-who-we sa-top120">
                    <h2 className="h2">Who we serve</h2>
                    <div className="sa-row">
                        <div className=" col-md-8 col-sm-6 col-xs-12" style={{ 'paddingRight': "20px" }}>
                            <div className="card card-lg card-shadow card-primary" >
                                <div className="who-bg1" >
                                    <h4 className="h4">Patients and their families</h4>
                                    <p>
                                        provide a wide rang of service from video medical consultations to home delivery of medicines. As well as follow-up of their health, periodic vaccinations, physiotherapy and many.
                                    </p>
                                </div>
                                <a href='/' className="sa-arrow-right-bg sa-arrow-right-light" >
                                    <i className="icon-arrow-right">

                                    </i>
                                    See services
                                </a>
                            </div>
                        </div>
                        <div className=" col-md-4 col-sm-6 col-xs-12" style={{ 'paddingRight': "20px" }} >
                            <div className="card card-lg card-shadow card-success" >
                                <div >
                                    <h4 className="h4">Companies and employees</h4>
                                    <p>
                                        We support you in apply occupational safety and health to enhance loyalty of all of your employees
                                    </p>
                                </div>
                                <a href='/' className="sa-arrow-right-bg sa-arrow-right-light" >
                                    <i className="icon-arrow-right">

                                    </i>
                                    See services
                                </a>
                            </div>
                        </div>

                        <div className=" col-md-12 col-sm-12 col-xs-12" style={{ 'paddingRight': "20px" }}>
                            <div className="card card-lg card-shadow card-primary-trans">
                                <div className="who-bg2">
                                    <h4 className="h4">Doctors and specialists</h4>
                                    <p>Would you like to provide your medical advice through Sanar?</p>
                                </div>
                                <a href='/' className="sa-arrow-right-bg sa-arrow-right-light" >
                                    <i className="icon-arrow-right">

                                    </i>
                                    See services
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12" style={{ 'paddingRight': "20px" }}>
                            <div className="card card-lg card-shadow card-success" >
                                <div>
                                    <h4 className="h4">Insurance companies</h4>
                                    <p>
                                        Do you want to provide distinguished medical services to members and subscribers?
                                    </p>
                                </div>
                                <a href='/' className="sa-arrow-right-bg sa-arrow-right-light" >
                                    <i className="icon-arrow-right">

                                    </i>
                                    See services
                                </a>
                            </div>

                        </div>
                        <div className=" col-md-8 col-sm-6 col-xs-12" style={{ 'paddingRight': "20px" }}>
                            <div className="card card-lg card-shadow card-primary">
                                <div>
                                    <h4 className="h4">Hospitals and healthcare centers</h4>
                                    <p>Would you like to provide your medical services through Sanar?</p>
                                </div>
                                <a href='/' className="sa-arrow-right-bg sa-arrow-right-light" >
                                    <i className="icon-arrow-right">

                                    </i>
                                    See services
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Card
