import React from 'react'
import '../../Style/Download_Zwaara.css';

function Download_Zwaara() {
    return (
        <div className="row top120 join-down">
            <div className="col-6 col-md-6 col-sm-6 col-xs-12">
                <div className="vir-head down-sanar">
                    <h2 className="h2">Download Zwaara Pro</h2>
                    <div className="d-flex mb30">
                        <button className="cursor active">Application Download </button>
                        <button className="cursor">Desktop Software</button>
                    </div>
                    <div className="app app-stores join-stores">
                        <a href="https://play.google.com/store/apps/details?id=com.sanarcare.app&amp;hl=en_IN" target="_blank" rel="noreferrer">
                            <img src="https://sanar-assets.com/images/static/play-store.svg" alt="" className="w100"></img>
                        </a>
                        <a href="https://apps.apple.com/us/app/sanar-%D8%B3%D9%86%D8%A7%D8%B1/id1473258160" target="_blank" rel="noreferrer">
                            <img src="https://sanar-assets.com/images/static/app-store.svg" alt="" className="w100"></img>

                        </a>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-6 col-sm-6 col-xs-12 mob-none">
                <figure>
                    <img src="https://sanar-assets.com/images/static/new-theme/join-sanar-mob.png" alt="" className="img-cover1"></img>
                </figure>
            </div>
        </div>
    )
}

export default Download_Zwaara