import React, { useState, useEffect, useCallback } from 'react'
import '../../../Style/Home.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import mapmarker from '../../../images/marker.jpeg'

function Service() {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [pagename, setPagename] = useState('');
    const navigate = useNavigate();

    const openModel = (id) => {
        setShow(true);
        setPagename(id);
    }

    const handleClose = () => {
        setShow(false);
    }

    const redirect = () => {
        navigate('/')
    }

    const handleLocation = () => {
        navigate(`/${pagename}/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04`)
    }

    const [servicesData, setServicesData] = useState([]);

    useEffect(() => {
        fetch('https://zwarra.biztechnosys.com/api/zwaara-services')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setServicesData(data);
            });
    }, []);

    const [viewport, setViewport] = useState({
        latitude: 20.9471,
        longitude: 72.8990,
        zoom: 10
    });

    const logEvents = useCallback(event => {
        console.log(event);
    }, []);

    const setMarker = useCallback(() => {
    }, []);

    const handleViewportChange = useCallback(viewport => {
        setViewport(viewport);
    }, []);

    const marker = { longitude: 0, latitude: 0 };
    const [markerPosition, setMarkerPosition] = useState({
        longitude: 0,
        latitude: 0
    });

    const onMarkerDragStart = useCallback(event => {
        // logEvents(_events => ({ ..._events, onDragStart: event.lngLat }));
    }, []);

    const onMarkerDrag = useCallback(event => {
        // logEvents(_events => ({ ..._events, onDrag: event.lngLat }));
    }, []);

    const onMarkerDragEnd = useCallback(event => {
        // logEvents(_events => ({ ..._events, onDragEnd: event.lngLat }));
        setMarker({
            longitude: event.lngLat[0],
            latitude: event.lngLat[1]
        });
    }, []);


    return (
        <>
            <div className="z-service-bg z-service-ins-bg">
                <div className="container pos-rel">
                    <div className="z-mob-block sa-link-ins-bg">
                        <div className="col-6 col-md-6 col-sm-6 col-xs-12 z-link-insur">
                            <p className="z-add-ins sa-sprite sa-ins-icon"> We accept Bupa, Tawuniya, MEDGULF, Malath and AlRajhi Takaful insurance for telemedicine
                                <span className="sa-link">To link your insurance login or sign up</span>
                            </p>
                        </div>
                    </div>
                    <div className="z-insur-ofr">
                        <h5 className="z-mob-block">Virtual Medical Services</h5>
                    </div>
                    <ul className="z-telemedicine-list z-mob-block">
                        <li>
                            <img src="https://sanar-assets.com/images/home-services/v5/telemedicine_new.svg" alt="services" title="Virtual Consultations" />
                            <div className="z-telemedicine-desc ms-2">
                                Virtual Consultations
                                <p>Book an instant virtual consultation with doctors in 25+ different specialties</p>
                            </div>
                        </li>
                    </ul>
                    <div className="sa-services">
                        <h1 className='poppins-semibold'>Our Service</h1>
                        <h2 className='poppins-semibold zwaara_h2'>Medical</h2>
                        <div className="sa-pos-rel">
                            <ul className="z-services-list">
                                {servicesData.filter(item => item.TypeOfService === 'medical').map((item) => (
                                    <li key={item.id} id={item.id} onClick={() => openModel(item.id)}>
                                        <img src={item.Image} alt="services" title={item.Title} />
                                        <span className='poppins-regular zwaara_sr'>{t(`${item.Title}`)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="sa-services">
                        <h2 className='poppins-semibold zwaara_h2'>Non-Medical</h2>
                        <div className="sa-pos-rel">
                            <ul className="z-services-list">
                                {servicesData.filter(item => item.TypeOfService === 'non-medical').map((item) => (
                                    <li key={item.id} id={item.id} onClick={() => openModel(item.id)}>
                                        <img src={item.Image} alt="services" title={item.Title} />
                                        <span className='poppins-regular zwaara_sr'>{t(`${item.Title}`)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose} animation={false}>
                <div className="sps-dialog sps-dialog-full zw_select_location">
                    <div className="sps-dialog-body sps-dialog-overflow-unset sps-map">
                        <button className="sps-dialog-close" onClick={handleClose}>
                            <i className="icon-close"></i>
                        </button>
                        <div className="sps-loc-map">
                            <div id="spsmapbox" className="sps-mapboxgl-map" >
                                <ReactMapGL
                                    initialViewState={{
                                        longitude: -122.4,
                                        latitude: 37.8,
                                        zoom: 14
                                    }}
                                    style={{ width: "100vw", height: "100vh" }}
                                    mapStyle="mapbox://styles/mapbox/streets-v9"
                                    mapboxAccessToken='pk.eyJ1IjoiYWJkdWxyYTdtYW4iLCJhIjoiY2x0bzlvNTEwMDVoZTJrbWg4bHRxeXRwciJ9._WDrK6r6rayfB4WnardOwA'
                                >
                                    <Marker
                                        longitude={markerPosition.longitude}
                                        latitude={markerPosition.latitude}
                                        draggable
                                        onDragStart={onMarkerDragStart}
                                        onDrag={onMarkerDrag}
                                        onDragEnd={onMarkerDragEnd}
                                    >
                                        <img src={mapmarker} alt="Marker" />
                                    </Marker>
                                    <NavigationControl showZoom={true}></NavigationControl>
                                </ReactMapGL>

                                <header className="sps-loc-header zw_current_loc">
                                    <button type="submit" className="sps-btn sps-loc-crnt-btn icon-current-location"></button>
                                    <div className="sps-loc-field">
                                        <i className="sps-sprite sps-search"></i>
                                        <input placeholder="Search" className="sps-form-control search-location pac-target-input zw_secondary poppins-regular zw_16" type="text" id="search" name="search" autoComplete="off"></input>
                                        <i className="icon-close2 sps-form-icon"></i>
                                    </div>
                                </header>
                            </div>
                        </div>
                        <footer className="sps-loc-footer zw_loc_footer">
                            <div className="row">
                                <div className="col-md-6 sps-d-f-sbetween">
                                    <h5 className="sps-sprite sps-loc-map-ico sps-loc-type poppins-semibold zw_16">
                                        Visit Location
                                    </h5>
                                </div>
                                <div className='col-md-6'>
                                    <h5 className="poppins-semibold zw_16 text-right">
                                        Use my curent location
                                    </h5>
                                </div>
                            </div>
                            <div className='row zw_loc_icon'>
                                <div className="col-md-12 sps-mt10">
                                    <div className="sps-lcn-card">
                                        <div className="sps-d-f-sbetween">
                                            <h5>
                                                <i className="icon-location2 sps-mr5 zw_title_color"></i>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className="col-md-6 sps-ftr-res-btn poppins-medium zw_btn_18">
                                    <button type="submit" className="btn btn-outline-primary sps-btn-view zw_btn_view ">View saved address</button>
                                </div>
                                <div className="col-md-6 sps-ftr-res-btn poppins-medium zw_btn_18">
                                    <button id='locbtn' data-bs-dismiss="modal" className="btn btn-primary sps-btn-confirm zw_btn_confirm " type="submit">
                                        <Link to='/laboratory'>
                                            Confirm Location
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default Service
