import React, { useState } from 'react'

import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import '../../Style/Vitamins1.css';
import { Link } from 'react-router-dom';
import v1 from '../../assets/img/v1.png';
import v2 from '../../assets/img/v2.png';
import v3 from '../../assets/img/v3.png';
import v4 from '../../assets/img/v4.jpg';
import v5 from '../../assets/img/v5.png';
import v6 from '../../assets/img/v6.jpg';
import v7 from '../../assets/img/v7.jpg';
import v8 from '../../assets/img/v8.png';
import v9 from '../../assets/img/v9.png';
import v10 from '../../assets/img/v10.png';
import v12 from '../../assets/img/v12.png';
import v13 from '../../assets/img/v13.jpg';
import TasksModal from './TasksModal';

function VitaminIVs1() {

    const [selectedVaccinations, setSelectedVaccinations] = useState([]);

    // const handleVaccinationSelection = (e) => {
    //     const selectedVaccinationId = e.target.value;
    //     const selectedVaccinationLabel = e.target.parentElement.querySelector('label').textContent.trim();

    //     const isAlreadySelected = selectedVaccinations.some(
    //         (vaccination) => vaccination.id === selectedVaccinationId
    //     );


    //     if (!isAlreadySelected) {
    //         setSelectedVaccinations([{ id: selectedVaccinationId, label: selectedVaccinationLabel }]);

    //     } else {
    //         setSelectedVaccinations([]);

    //     }
    //     console.log('Selected Vaccinations:', selectedVaccinations);
    // };

    const handleVaccinationSelection = (e) => {
        const selectedVaccinationId = e.target.value;
        const selectedVaccinationLabel = e.target.parentElement.querySelector('label').textContent.trim();

        const isAlreadySelected = selectedVaccinations.some(
            (vaccination) => vaccination.id === selectedVaccinationId
        );

        if (!isAlreadySelected) {
            setSelectedVaccinations([{ id: selectedVaccinationId, label: selectedVaccinationLabel }]);
        } else {
            setSelectedVaccinations([]);
        }

        console.log('Selected Vaccinations:', selectedVaccinations);
    };

    const isVaccinationSelected = selectedVaccinations.length > 0;






    const [searchTerm, setSearchTerm] = useState('');


    const packages = [
        { id: 'MTA55', name: 'Energy Boost', price: 1300, details: 'To restore energy, build proteins, support antioxidants, and increase productivity.', includes: 'Includes Vitamin B12, Vitamin C, Magnesium Sulphate , Potassium Chloride, Zinc, Selenium, L-Carnitine, Glutamine, Normal Saline, Multivitamins Mixture' },
        { id: 'MTA56', name: 'Hydration', price: 1100, details: 'Ideal for recovery from an action-packed weekend or busy lifestyle, boost energy levels while replenishing the electrolytes in your body. ', includes: 'Includes Vitamin C, Potassium Chloride, Magnesium Sulphate, Zinc , Normal Saline, Thiamine (Vitamin B1), Amino Acids, Water soluble vitamins' },
        { id: 'MTA57', name: 'Jet Lag', price: 1100, details: 'Hydration after a flight is essential for the body, this is recommended for frequent travelers to support with energy production and the immune system. ', includes: 'Includes Vitamin B12, Zinc, Magnesium Sulphate, Vitamin C, Calcium Gluconate, Amino Acids, Normal Saline, Thiamine (Vitamin B1), Water soluble vitamins' },
        { id: 'MTA58', name: 'Post Sleeve Gastrectomy', price: 1150, details: 'To hydrate the body after gastric sleeve and provide better absorption for the nutrients and supplements. ', includes: 'Includes Calcium Gluconate , Magnesium Sulphate, Vitamin B12, Potassium Chloride, MultiVitamins Mixture, Vitamin C, Vitamin B1, Vitamin D3, Selenium, Amino Acid, Normal saline, Trace Elements Mixture, Zinc' },
        { id: 'MTA59', name: 'Antiaging', price: 1100, details: 'The ladies favourite: a powerful hit of antioxidants with antiaging properties supporting detox, hydration, optimal hair, nail and skin health. ', includes: 'Includes MultiVitamins Mixture, Vitamin C, Zinc, Selenium, Magnesium Sulphate, Potassium Chloride, Amino Acids, Vitamin B12, Normal Saline' },
        { id: 'MTA60', name: 'Healthy Hair', price: 1150, details: 'For healthy, strong, balanced hair, reduces hair loss and supports nails and skin. ', includes: 'Includes Vitamin C, Zinc, Selenium, Water soluble vitamins, Vitamin D3, Vitamin B12, Magnesium Sulphate, Folic acid, Amino acids, Normal saline"' },
        { id: 'MTA61', name: 'Depression Relief', price: 1100, details: 'This multivitamin relieves Depression, Anxiety, and their related symptoms ', includes: 'Includes Selenium, Zinc, Calcium Gluconate, Amino Acids, Vitamin C, Vitamin B12, Vitamin D3, Folic acid, Thiamine (Vitamin B1), Magnesium sulphate, Normal saline, Trace elements mixture, Water soluble vitamins' },
        { id: 'MTA62', name: 'Weight loss and Fat burn', price: 1300, details: 'This multivitamin aids in improving body metabolism and the rate of fat burning within the body ابتداءً من 1300 ريال', includes: 'Includes L-Carnitine, Glutamine, Zinc, Calcium Gluconate, Vitamin C, Vitamin B12, Vitamin D3, Thiamine (Vitamin B1), Magnesium sulphate, Normal saline, Trace elements mixture, Water soluble vitamins' },
        { id: 'MTA63', name: 'Anti Stress', price: 1000, details: 'It calms the body, improves mood, reduces anxiety and other signs of stress. ', includes: 'Includes Magnesium Sulphate, Zinc, Selenium , Amino Acids, Vitamin C, Vitamin B12, Normal Saline, Thiamine (Vitamin B1), Water Soluble Vitamins' },
        { id: 'MTA64', name: 'Boost energy and fitness', price: 1200, details: 'Recommended for fitness enthusiasts or those who maintain an active lifestyle. ', includes: 'Includes Vitamin C, Magnesium Sulphate, Calcium Gluconate, Potassium Chloride, Zinc, Vitamin B12, Glutamine, Normal saline , Water soluble vitamins, L-Carnitine, Thiamine (Vitamin B1)' },
        { id: 'MTA66', name: 'Memory Management', price: 1100, details: 'It calms the stimulated mind and plays an important role in forming brain cells and enhances memory. ', includes: 'Includes Vitamin B12, Zinc, Selenium, Calcium Gluconate, Magnesium Sulphate, Vitamin C, Amino Acids, Potassium Chloride, Normal Saline, Water soluble vitamins, Thiamine (Vitamin B1)' },
        { id: 'MTA67', name: 'Migraine Relief', price: 1050, details: 'This multivitamin relieves migraine headaches and their related symptoms. starting from 1050 SAR', includes: 'Includes Vitamin C, Vitamin D3, Folic acid, Thiamine (Vitamin B1), Magnesium sulphate, Vitamin B12, Normal saline, Water soluble vitamins' },
        { id: 'MTA68', name: 'Weight gain', price: 1100, details: 'This multivitamin boosts the body\'s ability to absorb nutrients, increases appetite, and provides greater energy ', includes: 'Includes Dextrose, Amino Acids, Calcium Gluconate, Magnesium sulphate, Zinc, SMOFlipid, Normal saline, Trace elements mixture, Multivitamins Mixture, Water for Injection' },
    ];





    const filteredPackages = packages.filter(packageItem =>
        packageItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const getPackageImage = (packageId) => {
        switch (packageId) {
            case 'MTA55':
                return v1;
            case 'MTA56':
                return v2;
            case 'MTA57':
                return v3;
            case 'MTA58':
                return v4;
            case 'MTA59':
                return v5;
            case 'MTA60':
                return v6;
            case 'MTA61':
                return v7;
            case 'MTA62':
                return v8;
            case 'MTA63':
                return v9;
            case 'MTA64':
                return v10;
            case 'MTA66':
                return v10;
            case 'MTA67':
                return v12;
            case 'MTA68':
                return v13;

            default:
                return null;
        }
    };

    return (
        <div>
            <Navbar />
            <div className='distance'>
                <div class="sa-container sa-content sa-lab-det sa-top-space60 sa-btm-space">
                    <div class="sa-loc-label">
                        <Link className="Home-link" to="/">
                            <span class="sa-btn-back">
                                <i class="icon-arrow-left">

                                </i>
                                <span >Back</span>
                            </span>
                        </Link>
                        <div>
                            <div class="sa-input-group sa-location-label">
                                <button class="sa-btn sa-ellipsis">
                                    Selected Location
                                </button>
                                <i class="icon-location2">

                                </i>
                                <input type="text" placeholder="location" class="sa-form-control sa-ellipsis sa-cursor" readOnly={false} />
                                <i class="icon-down-arrow sa-form-icon">

                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="sa-prog-grid">
                        <p>
                            <span>Step 1</span>
                            of 4
                        </p>
                        <div class="sa-prog-bar">
                            <div class="sa-prog-bar-per" style={{ "width": '25%' }}></div>
                        </div>
                    </div>
                    <div class="sa-row">
                        <div class="sa-col8 sa-col-md8 sa-col-sm7 sa-col-xs12">
                            <div class="sa-lab-srch sa-mob-block sa-top-fixed-search">
                                <span class="sa-srch-text">
                                    Search
                                    <i class="icon-search"></i>
                                </span>
                                <span class="sa-lab-pos">
                                    <i class="icon-arrow-left" autofocus></i>
                                    <i class="icon-search sa-form-icon"></i>
                                </span>
                            </div>
                            <div class="sa-nurse">
                                <div class="sa-row">
                                    <div class="sa-col6 sa-col-md6 sa-col-lg6">
                                        <h4 >Packages</h4>
                                    </div>
                                    <div class="sa-col6 sa-col-md6 sa-col-sm12 sa-col-lg6">
                                        <div class="sa-form-group sa-search-box sa-mob-none">
                                            <i class="icon-search sa-form-icon">

                                            </i>
                                            <input id="search" name="search" type="text" placeholder="Search" class="sa-form-control" required autocomplete="off" style={{ width: "383px", borderRadius: "8px" }} onChange={(e) => setSearchTerm(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                {filteredPackages.length > 0 ? (
                                    <ul class="sa-card-list sa-nurse-checkbox" >
                                        {filteredPackages.map(packageItem => (
                                            <li key={packageItem.id} style={{ borderRadius: "10px" }}>
                                                <div class="sa-card-list-box" style={{ "marginLeft": "-60px", "marginTop": "-35px" }}>
                                                    <figure>
                                                        {getPackageImage(packageItem.id) && (
                                                            <img src={getPackageImage(packageItem.id)} alt="" style={{ "height": "97.78px", width: "85.35px" }}></img>
                                                        )}
                                                    </figure>

                                                    <div class="sa-doct-det" style={{ "marginTop": "30px", marginLeft: "-50px" }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                            <label htmlFor="{packageItem.id}" style={{ fontWeight: "bold" }}>{packageItem.name}</label>

                                                            <input
                                                                type="radio"
                                                                id={packageItem.id}
                                                                name="Vitamin IV Drips"
                                                                value={packageItem.id}
                                                                onClick={handleVaccinationSelection}

                                                                style={{ position: "static" }}
                                                            />
                                                        </div>
                                                        <p>

                                                            {`${packageItem.details}. starting from ${packageItem.price} SAR`}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="sa-status-card" style={{ marginTop: "-15px", fontSize: "14px", fontWeight: "bold" }}>

                                                    {packageItem.includes}

                                                </div>
                                            </li>
                                        ))}



                                    </ul>
                                ) : searchTerm.length > 0 ? (
                                    <p>No results found.</p>
                                ) : null}

                            </div>

                        </div>
                        <div class="sa-col4 sa-col-md4 sa-col-sm5 sa-col-xs12" style={{ paddingLeft: "25px" }}>
                            <h4 className="tit mob-none">Selected Items</h4>
                            <div className="selected-data" style={{ backgroundColor: "#edf8ff", paddingTop: "30px", paddingBottom: "30px" }}>
                                <div className="item-selected">
                                    <h4 className="mob-header mob-block">Selected services</h4>

                                    <ul>
                                        {selectedVaccinations.map((vaccination, index) => (
                                            <li key={index}>
                                                <div className="f--label">
                                                    <label htmlFor={`selected_${vaccination.id}`} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#fff", borderRadius: "10px", border: "none", margin: "30px", padding: "20px", position: "relative", cursor: "pointer", }} >
                                                        {vaccination.label}
                                                        <input type="checkbox" id={`selected_${vaccination.id}`} checked={true} readOnly style={{ position: "absolute", opacity: 0, cursor: "pointer", }} />
                                                        <span style={{ width: "20px", height: "20px", backgroundColor: "#26a9e0", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "5px", color: "#fff" }}>✓</span>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>


                                    <span className="btn-back mob-block">
                                        <i class="icon-arrow-left"></i>
                                        <div className="prog-grid">
                                            <p><span>Step 1</span> of 4</p>
                                            <div className="prog-bar">
                                                <div className="prog-bar-per" style={{ width: "25%" }}>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                </div>
                                <div className="item-btns">
                                    <Link to={isVaccinationSelected ? "/vitamin-center" : "#"}>
                                        <button
                                            className="btn btn-primary btn-block"
                                            style={{
                                                width: "323.33px",
                                                marginLeft: "20px",
                                                backgroundColor: isVaccinationSelected ? "#26a9e0" : "#c2c9e1",
                                            }}
                                            disabled={!isVaccinationSelected}
                                        >
                                            Continue
                                        </button>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <Footer />
            </div>
            <TasksModal selectedVaccination={selectedVaccinations} />
        </div>
    )
}

export default VitaminIVs1
