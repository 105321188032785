import React from 'react'
import '../.././Style/Herosection.css'

function Herosection() {
    return (
        <div>
            <div className="sa-about-us" >
                <div className="sa-container sa-pos-rel">
                    <div className="sa-abt-tit">
                        <h1 className="h1">Primary care service and telemedicine consultations providers </h1>
                        <p>We provided an integrated from providing consultations by elit of licensed specialist and consultants who are acceredited by saudi(MOH),to delivary of medicines at your Home </p>
                    </div>
                    <div className="sa-con-provider">
                        <div className="sa-consultaion">
                            <figure className="sa-pb20">
                                <img src="https://sanar-assets.com/images/static/new-theme/telemedicen.png" alt="doc"></img>
                            </figure>
                        </div>
                        <div className="sa-service-provi">
                            <div className="sa-primary-bg sa-serv-inje">
                                <figure>
                                    <img src="https://sanar-assets.com/images/static/v5/medical-services/vaccination-en.png" alt=""></img>
                                </figure>

                            </div>
                            <div className="sa-card-success sa-serv-inje">
                                <img className="sa-serv-img" src="https://sanar-assets.com/images/static/v5/medical-services/lab-en.png" alt=""></img>
                                <div className="sa-serv-position">
                                    <span>2200+</span>
                                    <span>Service providers</span>
                                </div>
                            </div>
                        </div>
                        <div className="sa-heart-ser">
                            <figure>
                                <img src="https://sanar-assets.com/images/static/v5/medical-services/heart.png" alt="heart"></img>
                            </figure>
                            <div className="sa-heart-positi">
                                <span>204+</span>
                                <span>Doctors</span>
                            </div>
                        </div>
                        <div className="sa-service-provi">
                            <div className="sa-primary-bg sa-serv-cnt">
                                <span>Cities Covered</span>
                                <span>24</span>
                            </div>
                            <div className="sa-card-success sa-serv-inje">
                                <img src="https://sanar-assets.com/images/static/v5/medical-services/hands.png" alt="" ></img>
                            </div>
                        </div>
                        <div className="sa-consultaion">
                            <figure className="sa-pt20">
                                <img className="sa-img-cover" src="https://sanar-assets.com/images/static/v5/medical-services/radiology-en.png" alt="doc"></img>
                            </figure>
                        </div>
                    </div>
                    <div className="sa-health-ins sa-mob-none">
                        <div className="sa-d-flex">
                            <img src="https://sanar-assets.com/images/log.png" alt="sanar licence" className="sa-avtar-sm" />
                            <p>
                                Sanar is a licensed company by the Saudi Ministry of Health with License No.1400006807
                            </p>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Herosection
