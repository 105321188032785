import React from 'react'

function OTP() {
    return (
        <div className='zw_popup'>
            <div className="modal fade" id="OTP" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body mb-4">
                            {/* <div className="body-cnt"> */}
                            <div className="reg-spcv1 regspcv2">
                                <div className='btn-space'>
                                    <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="icon-close"></i>
                                    </button>
                                </div>
                                <div className="set-up mob-header">
                                    <i class="icon-left-arrow f-size18 cursor mob-block"></i>
                                    <h6 className='poppins-semibold zw_text_color zw_32 mb-4' style={{ textAlign: "center" }}>Account Setup</h6>
                                </div>
                                <div className="container">
                                    <p className='poppins-regular zw_24 zw_9B9B9B mb-0'>
                                        Step 1 completed of 4
                                    </p>
                                    <div className="prog-grid1">
                                        <div className="prog-bar1" style={{ backgroundColor: "#DDE0E6" }}>
                                            <div className="prog-bar-per" style={{ width: "100%" }}></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card card-primary-light card-no-border last-child sign-space card-shadow"> */}
                                <div className="container">
                                    <div className="form-group verifi-code">
                                        <div className="row dir-ltr">
                                            <div className="col-3 col-md-3 col-sm-3 col-xs-3">
                                                <input type="tel" placeholder='0' maxlength="1" className="zw_secondary poppins-regular zw_16 form-control zw_form_control text-center plr10" id="otpfield" name="Otp"></input>
                                            </div>
                                            <div className="col-3 col-md-3 col-sm-3 col-xs-3">
                                                <input type="tel" placeholder='0' maxlength="1" className="zw_secondary poppins-regular zw_16 form-control zw_form_control text-center plr10" id="otpfield" name="Otp"></input>
                                            </div>
                                            <div className="col-3 col-md-3 col-sm-3 col-xs-3">
                                                <input type="tel" placeholder='0' maxlength="1" className="zw_secondary poppins-regular zw_16 form-control zw_form_control text-center plr10" id="otpfield" name="Otp"></input>
                                            </div>
                                            <div className="col-3 col-md-3 col-sm-3 col-xs-3">
                                                <input type="tel" placeholder='0' maxlength="1" className="zw_secondary poppins-regular zw_16 form-control zw_form_control  text-center plr10" id="otpfield" name="Otp"></input>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center dir-ltr m-4">
                                        <p className="zw_20 poppins-medium zw_text_color verification-link">Please type the verification code</p>
                                        <span className='zw_20 poppins-semibold zw_text_color'>(number)</span>

                                    </div>
                                    <div className="d-f-end d-flex-dir-col text-center mb-4">
                                        <p className="poppins-regular zw_btn_18 zw_title_color resend-otp">did,t receive resend</p>
                                    </div>
                                    <button type='button' className='btn btn-primary btn-lg btn-block poppins-regular zw_bg_gradient zw_btn_18 zw_border_none' disabled="" style={{ width: "70%", margin: " 0 15%" }} data-bs-toggle="modal" data-bs-target="#successs">
                                        Done
                                    </button>
                                </div>
                            </div>
                            <div className="reg-spcv3">
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OTP