
import React, { useState } from "react";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
	const [selectedItems, setSelectedItems] = useState([]);

	return (
		<Context.Provider value={{ selectedItems, setSelectedItems }}>
			{children}
		</Context.Provider>
	);
};
