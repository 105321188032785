import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import '../../Style/Caregivert2.css';
import care2 from '../../assets/img/care2.jpeg';
import { Link } from 'react-router-dom';
function Caregivertask2() {
    return (
        <div>
            <Navbar />
            <div class="sa-container sa-content" >
                <Link className="care-giver" to="/Caregiver/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04">
                    <span class="sa-btn-back">
                        <i class="icon-arrow-left" >

                        </i>
                        <span class="back-text">Back</span>
                    </span>
                </Link>
                <div class="sa-prog-grid">
                    <p>
                        <span>
                            Step 2
                        </span>
                        of 3
                    </p>
                    <div class="sa-prog-bar">
                        <div class="sa-prog-bar-per" style={{ "width": "66.6667%" }}></div>
                    </div>
                </div>
                <h5 class="sa-mob-header sa-mob-block">Details</h5>
                <div class="sa-row sa-top-space40">
                    <div class="sa-col7 sa-col-md8 sa-col-sm6 sa-col-xs12">
                        <div class="sa-info-light-bg sa-card-no-border sa-caregiver-helper">
                            <div class="sa-caregiver-in">
                                <h5 style={{ fontWeight: "large" }}>Baby Sitter </h5>
                                <p>
                                    Taking care of your children, feeding them, keeping them clean and orderly, accompanying them in their activities, and dealing with their outbursts of anger, changing diapers, and breastfeeding. Staying at home <br /> with children for 12 hours a day.
                                </p>
                                <figure>
                                    <img src={care2} alt="" style={{ width: "112px", height: "87px" }} />
                                </figure>
                            </div>
                            <h6 class="sa-primary-text sa-mt10 sa-mb10" style={{ fontWeight: "bold" }}>Includes</h6>
                            <ul></ul><br />
                        </div>
                    </div>
                    <div class="sa-col5 sa-col-md4 sa-col-sm6 sa-col-xs12">
                        <h5 class="sa-mb20 sa-fw500" style={{ marginLeft: "20px", fontWeight: "large" }}>Select duration of care</h5>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>1 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>3000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>2 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>5000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>3 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>6800</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>4 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>8000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div><br />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Caregivertask2
