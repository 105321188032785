import React, { useState, useEffect } from 'react';
import '../../Style/Carousel.css'

function Carousel() {
    const [reviews, setReviews] = useState([]);


    useEffect(() => {
        const apiUrl = 'https://zwarra.biztechnosys.com/api/customer-reviews';

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => setReviews(data))
            .catch(error => console.error('Error fetching customer reviews:', error));
    }, []);

    return (
        <>
            <div className='container zw_teatimonial'>
                <div className="justify-space-between">
                    <h1 className="z-carousel-title poppins-semibold zw_title_color zw_46"> Customer review </h1>
                </div>
                <div id="carouselExample" className="z-mob-none carousel slide z-carousel-container" data-bs-ride="carousel">
                    <div className="carousel-inner z-carousel">
                        {reviews.map((item, index) => (
                            <div className={(index === 1 ? "active " : "") + "carousel-item text-center"}>
                                <div className="card-wrapper container-sm d-flex  justify-content-around" key={index}>
                                    <div className="z-carousel-item zw_mt">
                                        <div className="card-body z-card-show">
                                            <div className="zw_testimonial_bg zw_tes_des">
                                                <p className="poppins-regular zw_12">
                                                    {item.Review}
                                                </p>
                                            </div>
                                            <div className="avatar mx-auto">
                                                <img src={item.Image} className="rounded-circle img-fluid" alt='' />
                                            </div>
                                            <div className="text-center pt-4" style={{ fontSize: "25px" }}>
                                                <p className="text-warning mt-4">
                                                    {[1, 2, 3, 4, 5].map((star, i) => (
                                                        <span key={i} className={i < parseInt(item.Ratings, 5) ? '' : 'fa fa-star'}></span>
                                                    ))}
                                                </p>
                                                <h5 className="card-title my-2 poppins-semibold zw_12"> {item.Name} </h5>
                                                <h5 className="card-title my-2 poppins-medium zw_12"> {item.TypeOfReviewer} </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Carousel
