import React from 'react'
import BussinessBanner from '../Components/CareBussiness/BussinessBanner'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import WhyBussiness from '../Components/CareBussiness/WhyBussiness'
import BussinessProgram from '../Components/CareBussiness/BussinessProgram'
import '../Style/CareBussiness.css';

function CareBusiness() {
  return (
    <div>
      <>
        <Navbar />
        <BussinessBanner />
        <WhyBussiness />
        <BussinessProgram />
        <div className="container">
          <div className="cb-emp-request-service cb-emp-join-sanar-card cb-emp-top120 cb-emp-mb40">
            <h2><b>Take care of your employee health</b></h2>
            <p>And join to the leading companies to ensure wellness and prosperity at work and in the workplace</p>
          </div>
        </div>
        <Footer />
      </>
    </div>
  )
}

export default CareBusiness
