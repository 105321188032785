import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import { useNavigate } from 'react-router-dom'

function PrivacyPolicy() {
    let navigate = useNavigate();
    return (
        <div>
            <Navbar />
            <div className="container content" id="scrollToTop" style={{ marginTop: "80px" }}>
                <span className="btn-back zw_btn_18 poppins-medium zw_black" onClick={() => navigate(-1)}>
                    <i className="icon-arrow-left"></i>
                    <span className="mob-none">Back</span>
                </span>
                <div className='mt-4 pt-4'>
                    <h4 className="tit zw_24 zw_text_color poppins-semibold">Privacy Policy</h4>
                    <p className='poppins-regular zw_16 zw_text_color'>Your privacy is critically important to us.</p>

                </div>
                <div className="container zw_faq zw_privacy_faq">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item mb-4">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    General
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        The mobile / tablet application Sanar App & Sanar Pro (hereinafter referred to as ‘Application’ or ‘App’), is owned by Sanar eCommerce Company LLC.
                                        Sanar eCommerce Company LLC
                                        2351 Anas Ibn Malek- Al-Sahafah Dis. Unit No 458Riyadh 13321- 8358Kingdom of Saudi Arabia
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    WebsiteVisitor
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Like most website operators, Sanar eCommerce Company LLC collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Sanar eCommerce Company LLC's purpose in collecting non-personally identifying information is to better understand how Sanar eCommerce Company LLC's visitors use its website. From time to time, Sanar eCommerce Company LLC may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Gathering of Personally-Identifying Information
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Certain visitors to Sanar eCommerce Company LLC's websites choose to interact with Sanar eCommerce Company LLC in ways that require Sanar eCommerce Company LLC to gather personally-identifying information. The amount and type of information that Sanar eCommerce Company LLC gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://www.sanar.sa to provide a username and email address.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    Security
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    Advertisements
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Sanar eCommerce Company LLC and does not cover the use of cookies by any advertisers.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                    Links To External Sites
                                </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    Protection of Certain Personally-Identifying Information
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Sanar eCommerce Company LLC discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Sanar eCommerce Company LLC's behalf or to provide services available at Sanar eCommerce Company LLC's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Sanar eCommerce Company LLC's website, you consent to the transfer of such information to them. Sanar eCommerce Company LLC will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Sanar eCommerce Company LLC discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Sanar eCommerce Company LLC believes in good faith that disclosure is reasonably necessary to protect the property or rights of Sanar eCommerce Company LLC, third parties or the public at large.
                                        If you are a registered user of https://www.sanar.sa and have supplied your email address, Sanar eCommerce Company LLC may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Sanar eCommerce Company LLC and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Sanar eCommerce Company LLC takes all measures reasonably necessary to protect against unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    Aggregated Statistics
                                </button>
                            </h2>
                            <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Sanar eCommerce Company LLC may collect statistics about the behavior of visitors to its website. Sanar eCommerce Company LLC may display this information publicly or provide it to others. However, Sanar eCommerce Company LLC does not disclose your personally-identifying information

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    Cookies
                                </button>
                            </h2>
                            <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        To enrich and perfect your online experience, Sanar eCommerce Company LLC uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
                                        A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Sanar eCommerce Company LLC uses cookies to help Sanar eCommerce Company LLC identify and track visitors, their usage of https://www.sanar.sa, and their website access preferences. Sanar eCommerce Company LLC visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Sanar eCommerce Company LLC's websites, with the drawback that certain features of Sanar eCommerce Company LLC's websites may not function properly without the aid of cookies.
                                        By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Sanar eCommerce Company LLC's use of cookies.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4" >
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed poppins-semibold zw_16 zw_text_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    Privacy Policy Changes
                                </button>
                            </h2>
                            <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p className='poppins-regular zw_text_color'>
                                        Although most changes are likely to be minor, Sanar eCommerce Company LLC may change its Privacy Policy from time to time, and in Sanar eCommerce Company LLC's sole discretion. Sanar eCommerce Company LLC encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy