import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import { useNavigate} from "react-router-dom";

export default function Doctor_Specialist() {
  let navigate = useNavigate();
  return (
    <>
    <Navbar />
    <div className="container py-4">
      <div className="card-shadow service-prov" style={{top: "77px",
    marginBottom: "115px"}}>
        <span className="btn-back mob-block">
          <i className="icon-arrow-left"></i>
        </span>
        <h4 className="tit mob-header mob-block">Request Form</h4>
        <div className="text-center">
          <h3 className="h3">Provide my services via Sanar Request form</h3>
          <p>We are pleased to cooperate with us in providing and facilitating access to your services via Sanar platform</p>
        </div>
        <ul className="track-order list-unstyled d-flex justify-content-center">
          <li className="accepted">
            <span className="track-dot icon-checked"></span>
          </li>
          <li className="packed">
            <span className="track-dot fill-dot"></span>
          </li>
          <li className="idelivered">
            <span className="track-dot"></span>
          </li>
        </ul>
        <form noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="fname">First Name</label>
                <input type="text" placeholder="First Name" className="form-control" id="fname" name="First Name" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lname">Last Name</label>
                <input type="text" placeholder="Last Name" className="form-control" id="lname" name="Last Name" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Gender</label>
            <div className="d-block">
              <div className="form-check form-check-inline">
                <input type="radio" id="M" name="gender" value="M" className="form-check-input" />
                <label htmlFor="M" className="form-control">Male</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="radio" id="F" name="gender" value="F" className="form-check-input" />
                <label htmlFor="F" className="form-control">Female</label>
              </div>
            </div>
          </div>
          <div className="row">
          <label>Phone number</label>
            <div className="col-md-3">
              <div className="form-group">
                <img className="form-icon-jb form-img-jb" src="https://sanar-assets.com/flags/sa_64.png" alt='' />
                {/* <input type="text" id="countrycode" name="countrycode" className="form-control inp-flag" readOnly /> */}
                <select className="form-control inp-flag" readOnly="" id="countryCode" name="countryCode" placeholder="Phone Number">
                  <option value="+91">+91</option>
                  <option value="+..">+..</option>
                  <option value="+..">+..</option>
                  <option value="+..">+..</option>
                </select>
                <i className="icon-down-arrow form-icon-jb"></i>
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <input maxLength="15" type="tel" placeholder="Mobile Number" className="form-control" id="mnumber" name="Mobile Number" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail ID</label>
            <input type="email" className="form-control" placeholder="E-mail ID" id="email" name="E-mail ID" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="country">Country</label>
                {/* <select className="form-control" placeholder="Select Country">
                  <option value="India">India</option>
                </select> */}
                <input className="form-control cursor" placeholder="Select Country" id="country" name="Select Country" />
                <i className="icon-down-arrow form-icon-cc"></i>
                {/* <div className="custom-dropd">
                  <button className="dialog-close line-close mob-block">
                    <i className="icon-close"></i>
                  </button>
                  <div className="form-group mob-mt20">
                    <i className="icon-search form-icon form-icon-left"></i>
                      <input id="search" name="search" type="text" placeholder="Search" className="form-control"></input>
                  </div>
                  <ul className="more-list left-nospace">
                    <li className="">Cuba</li>
                    <li className="">Turkey</li>
                    <li className="">Algeria</li>
                    <li className="">Dominica</li>
                    <li className="">GuineaBissau</li>
                    <li className="">Cook Islands</li>
                    <li className="">Gibraltar</li>
                    <li className="">Pakistan</li>
                    <li className="">South Africa</li>
                    <li className="">Afghanistan</li>
                    <li className="">Yemen</li>
                    <li className="">Israel</li>
                    <li className="">Togo</li>
                    <li className="">Moldova</li>
                    <li className="">Morocco</li>
                    <li className="">Kenya</li>
                    <li className="">Nauru</li>
                    <li className="">India</li>
                    <li className="">Angola</li>
                    <li className="">Ethiopia</li>
                    <li className="">Mauritania</li>
                    <li className="">Italy</li>
                    <li className="">Cape Verde</li>
                    <li className="">Norway</li>
                    <li className="">Croatia</li>
                    <li className="">Syria</li>
                    <li className="">Malawi</li>
                    <li className="">Dominican Republic</li>
                    <li className="">Guyana</li>
                    <li className="">Niger</li>
                    <li className="">Ghana</li>
                    <li className="">Paraguay</li>
                    <li className="">Peru</li>
                    <li className="">Belgium</li>
                    <li className="">Brazil</li>
                    <li className="">Lebanon</li>
                    <li className="">Netherlands Antilles</li>
                    <li className="">Belize</li>
                    <li className="">Hungary</li>
                    <li className="">Luxembourg</li>
                    <li className="">Saint Lucia</li>
                    <li className="">Tanzania</li>
                    <li className="">Honduras</li>
                    <li className="">Trinidad And Tobago</li>
                    <li className="">Senegal</li>
                    <li className="">El Salvador</li>
                    <li className="">Bahamas</li>
                    <li className="">Germany</li>
                    <li className="">Spain</li>
                    <li className="">Jordan</li>
                    <li className="">Kuwait</li>
                    <li className="">Solomon Islands</li>
                    <li className="">Portugal</li>
                    <li className="">Greece</li>
                    <li className="">Greenland</li>
                    <li className="">Macedonia</li>
                    <li className="">Central African Republic</li>
                    <li className="">China</li>
                    <li className="">Azerbaijan</li>
                    <li className="">Bahrain</li>
                    <li className="">Estonia</li>
                    <li className="">Sudan</li>
                    <li className="">Malaysia</li>
                    <li className="">Bosnia And Herzegovina</li>
                    <li className="">Kosovo</li>
                    <li className="">Liechtenstein</li>
                    <li className="">Ecuador</li>
                    <li className="">Iceland</li>
                    <li className="">Romania</li>
                    <li className="">Egypt</li>
                    <li className="">Turkmenistan</li>
                    <li className="">French Polynesia</li>
                    <li className="">Japan</li>
                    <li className="">Ireland</li>
                    <li className="">Congo</li>
                    <li className="">Anguilla</li>
                    <li className="">Ukraine</li>
                    <li className="">Tunisia</li>
                    <li className="">Netherlands</li>
                    <li className="">Barbados</li>
                    <li className="">Canada</li>
                    <li className="">Guinea</li>
                    <li className="">Myanmar</li>
                    <li className="">Martinique</li>
                    <li className="">Bolivia</li>
                    <li className="">Aruba</li>
                    <li className="">Cayman Islands</li>
                    <li className="">American Samoa</li>
                    <li className="">Andorra</li>
                    <li className="">Reunion</li>
                    <li className="">Cameroon</li>
                    <li className="">Tuvalu</li>
                    <li className="">Nicaragua</li>
                    <li className="">Oman</li>
                    <li className="">Eritrea</li>
                    <li className="">Bhutan</li>
                    <li className="">Equatorial Guinea</li>
                    <li className="">Palestinian Territory Occupied</li>
                    <li className="">French Guiana</li>
                    <li className="">Democratic Republic of the Congo</li>
                    <li className="">Burkina Faso</li>
                    <li className="">Thailand</li>
                    <li className="">France</li>
                    <li className="">Maldives</li>
                    <li className="">Turks and Caicos Island</li>
                    <li className="">Iran</li>
                    <li className="">Monaco</li>
                    <li className="">Brunei</li>
                    <li className="">Slovenia</li>
                    <li className="">Russia</li>
                    <li className="">Chad</li>
                    <li className="">Fiji</li>
                    <li className="">Papua New Guinea</li>
                    <li className="">Laos</li>
                    <li className="">New Zealand</li>
                    <li className="">Vanuatu</li>
                    <li className="">Zimbabwe</li>
                    <li className="">Sao Tome And Principe</li>
                    <li className="">Botswana</li>
                    <li className="">Belarus</li>
                    <li className="">Slovakia</li>
                    <li className="">Gambia</li>
                    <li className="">United States of America</li>
                    <li className="">Sierra Leone</li>
                    <li className="">Guatemala</li>
                    <li className="">Poland</li>
                    <li className="">Guam</li>
                    <li className="">Curacao</li>
                    <li className="">Czech Republic</li>
                    <li className="">Chile</li>
                    <li className="">Timor-Leste</li>
                    <li className="">New Caledonia</li>
                    <li className="">Indonesia</li>
                    <li className="">Faroe Islands</li>
                    <li className="">Tonga</li>
                    <li className="">Sint Maarten</li>
                    <li className="">Bermuda</li>
                    <li className="">Gabon</li>
                    <li className="">San Marino</li>
                    <li className="">Australia</li>
                    <li className="">South Sudan</li>
                    <li className="">Latvia</li>
                    <li className="">Nigeria</li>
                    <li className="">Mali</li>
                    <li className="">Rwanda</li>
                    <li className="">Uganda</li>
                    <li className="">Virgin Islands British</li>
                    <li className="">Samoa</li
                    ><li className="">Virgin Islands Us</li>
                    <li className="">Liberia</li>
                    <li className="">Mozambique</li>
                    <li className="">Georgia</li>
                    <li className="">Lesotho</li>
                    <li className="">Saudi Arabia</li>
                    <li className="">Zambia</li>
                    <li className="">Sri Lanka</li>
                    <li className="">United Kingdom</li>
                    <li className="">Uzbekistan</li>
                    <li className="">Mongolia</li>
                    <li className="">Saint Kitts And Nevis</li>
                    <li className="">Cyprus</li>
                    <li className="">Lithuania</li>
                    <li className="">Costa Rica</li>
                    <li className="">Kazakhstan</li>
                    <li className="">Comoros</li>
                    <li className="">Cambodia</li>
                    <li className="">Bonaire Saint Eustatius and Saba</li>
                    <li className="">Federated States Of Micronesia</li>
                    <li className="">Northern Mariana Islands</li>
                    <li className="">Bangladesh</li>
                    <li className="">Suriname</li>
                    <li className="">Burundi</li>
                    <li className="">Namibia</li>
                    <li className="">Panama</li>
                    <li className="">Austria</li>
                    <li className="">Mexico</li>
                    <li className="">Sweden</li>
                    <li className="">Benin</li>
                    <li className="">Libya</li>
                    <li className="">Nepal</li>
                    <li className="">Hong Kong</li>
                    <li className="">Norfolk Island</li>
                    <li className="">Somalia</li>
                    <li className="">Haiti</li>
                    <li className="">Seychelles</li>
                    <li className="">Serbia</li>
                    <li className="">Palau</li>
                    <li className="">Mauritius</li>
                    <li className="">Tajikistan</li>
                    <li className="">Vietnam</li>
                    <li className="">Cote dIvoire</li>
                    <li className="">Taiwan</li>
                    <li className="">Armenia</li>
                    <li className="">Jamaica</li>
                    <li className="">Swaziland</li>
                    <li className="">Djibouti</li>
                    <li className="">Kiribati</li>
                    <li className="">Colombia</li>
                    <li className="">Unknown</li>
                    <li className="">Switzerland</li>
                    <li className="">Kyrgyzstan</li>
                    <li className="">Argentina</li>
                    <li className="">South Korea</li>
                    <li className="">Madagascar</li>
                    <li className="">Iraq</li>
                    <li className="">Antigua And Barbuda</li>
                    <li className="">Saint Martin (French part)</li>
                    <li className="">Denmark</li>
                    <li className="">Singapore</li>
                    <li className="">North Korea</li>
                    <li className="">Malta</li>
                    <li className="">United Arab Emirates</li>
                    <li className="">Venezuela</li>
                    <li className="">Uruguay</li>
                    <li className="">Albania</li>
                    <li className="">Puerto Rico</li>
                    <li className="">Saint Vincent And The Grenadines</li>
                    <li className="">Montenegro</li>
                    <li className="">Finland</li>
                    <li className="">Bulgaria</li>
                    <li className="">Qatar</li>
                    <li className="">Philippines</li>
                    <li className="">Guadeloupe</li>
                    <li className="">Grenada</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input className="form-control cursor" placeholder="Select City" id="city" name="Select City" />
                <i className="icon-down-arrow form-icon-cc"></i>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around">
            <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>
              <i className="icon-arrow-left"></i>
              <span className="ml-2">Previous</span>
            </button>
            <button type="button" className="btn btn-primary">
              <span className="mr-2">Next</span>
              <i className="icon-arrow-right"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
    <Footer />
    </>
  );
}
