export const homeVisitDoctorData = [
    {
        "id": 1,
        "doctorImage": "https://static.sanar.sa/mis/250a080f6d12f827665acb348b06e923.png",
        "doctorName": "Tagwa Alfdel",
        "doctorDesig": "General Practitioner",
        "doctorLoc": "Taibah Care , Madinah",
        "docVisit": "Home Visit",
        "currency": "SAR",
        "price": "400"
    },
    {
        "id": 2,
        "doctorImage": "https://static.sanar.sa/mis/665f1df72c69c1540a8c3a330f1d25d9.jpeg",
        "doctorName": "Mariam Yusaf",
        "doctorDesig": "General Practitioner",
        "doctorLoc": "Taibah Care , Madinah",
        "docVisit": "Home Visit",
        "currency": "SAR",
        "price": "50"
    },

]