import React from 'react'

function BiOrder() {
    return (
        <div className="container ci-odr-order-sanar ci-odr-top120">
            <h2 className="h2"><b>How to order from SANAR</b></h2>
            <ul>
                <li>
                    <div className="ci-odr-common-title">
                        <i className="icon-home-plus ci-odr-icon-bg">
                            <small>1</small>
                        </i>
                    </div>
                    <h3 className="ci-odr-header-body">Choose the service or clinic</h3>
                    <span>Doctor visits, Lab, vaccinations, home nursing services, etc</span>
                    <i className="icon-arrow-right-thick"></i>
                </li>
                <li>
                    <div className="ci-odr-common-title">
                        <i className="icon-online-meeting ci-odr-icon-bg">
                            <small>2</small>
                        </i>
                    </div>
                    <h3 className="ci-odr-header-body">Select location and schedule</h3>
                    <span>Synchronized with your schedule from comfort of your home</span>
                    <i className="icon-arrow-right-thick"></i>
                </li>
                <li>
                    <div className="ci-odr-common-title">
                        <i className="icon-online-meeting ci-odr-icon-bg">
                            <small>3</small>
                        </i>
                    </div>
                    <h3 className="ci-odr-header-body">Explain your problem</h3>
                    <span>And get an appropriate medical diagnosis</span>
                    <i className="icon-arrow-right-thick"></i>
                </li>
                <li>
                    <div className="ci-odr-common-title">
                        <i className="icon-medical-report ci-odr-icon-bg">
                            <small>4</small>
                        </i>
                    </div>
                    <h3 className="ci-odr-header-body">Find report in the application</h3>
                    <span>Medical reports and prescriptions will be attached to the application</span>
                </li>
            </ul>
        </div>
    )
}

export default BiOrder
