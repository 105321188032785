import React, { useState } from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import { Link } from 'react-router-dom';

function PersonalizedRequest() {
    const [selectedCountry, setSelectedCountry] = useState('in');
    const countries = [
        { code: 'in', name: 'India +91' },
        { code: 'sa', name: 'Saudi Arabia +966' },
        { code: 'eg', name: 'Egypt +20' },
        { code: 'au', name: 'Australia +61' },
        { code: 'cn', name: 'Canada +1' },
        { code: 'fr', name: 'France +33' },
        { code: 'pk', name: 'Pakistan +92' },
        { code: 'sl', name: 'Sri Lanka +94' }
    ];

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };
    return (
        <>
            <Navbar />
            <div className="container prf-top-space">
                <div className="prf-card-shadow prf-service-prov">
                    <Link to="/forindividuals" className="prf-btn-back">
                        <i className="icon-arrow-left"></i>
                    </Link>
                    <div className="prf-text-center prf-mb50">
                        <h3 className="prf-title">Personalized service request form</h3>
                        <p className="prf-subtitle">Would you like to get an additional service other than our service that suit your needs?</p>
                    </div>
                    <form>
                        <div className="prf-row prf-gutter5">
                            <div className="prf-col6 prf-col-md6 prf-col-sm6 prf-col-xs12">
                                <div className="prf-form-group">
                                    <label>First Name</label>
                                    <input type="text" placeholder="First Name" className="prf-form-control" id="fname" name="First Name"></input>
                                </div>
                            </div>
                            <div className="prf-col6 prf-col-md6 prf-col-sm6 prf-col-xs12">
                                <div className="prf-form-group">
                                    <label>Last Name</label>
                                    <input type="text" placeholder="Last Name" className="prf-form-control" id="lname" name="Last Name"></input>
                                </div>
                            </div>
                        </div>
                        <div className="prf-ccode">
                            <label>Phone Number</label>
                            <div className="prf-row prf-gutter10">
                                <div className="prf-col3 prf-col-md3 prf-col-sm3 prf-col-xs4">
                                    <div className="prf-form-group">
                                        {selectedCountry &&(
                                        <img
                                            className="prf-form-country-icon prf-form-img"
                                            src={`https://sanar-assets.com/flags/${selectedCountry}_64.png`}
                                            alt="Country Flags"
                                        />
                                        )}
                                        <select
                                            id="countrycode"
                                            name="countrycode"
                                            className="prf-form-control prf-inp-flag"
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                        >
                                            {countries.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        <i className="icon-down-arrow prf-form-icon"></i>
                                    </div>
                                </div>
                                <div className="prf-col9 prf-col-md9 prf-col-sm9 prf-col-xs8">
                                    <div className="prf-form-group">
                                        <input maxLength={10} type="tel" placeholder="Mobile Number" className="prf-form-control" id="mnumber" name="Mobile number"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label>Wanted service details</label>
                            <div className="prf-form-group">
                                <textarea id="comment" rows={4} placeholder="write the details here....." className="prf-form-control prf-textarea" name="Comment" data-gramm="false" wt-ignore-input="true"></textarea>
                            </div>
                        </div>
                        <div className="prf-pag-btn">
                            <span className="prf-cursor">
                                <span className="prf-primary-text prf-mr10">Send Request</span>
                                <button className="prf-btn-primary" type="button">
                                    <i className="icon-arrow-right prf-arrow"></i>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PersonalizedRequest
