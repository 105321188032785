import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import '../../Style/login_popup.css';

function AccountSetup_gender() {
    const [startDate, setstartDate] = useState(new Date());

    return (
        <div className='zw_popup'>
            <div className="modal fade" id="accountsetup_gender" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='btn-space'>
                                <button type="button" className="sps-dialog-close  regv2back" data-bs-dismiss="modal" aria-label="Close">
                                    <i className="icon-close"></i>
                                </button>
                            </div>

                            <div>
                                <h6 className='poppins-semibold zw_text_color zw_32 mb-4' style={{ textAlign: "center" }}>Account Setup</h6>
                            </div>
                            <div className="container">
                                <p className='poppins-regular zw_24 zw_9B9B9B mb-0'>
                                    Step 2 completed of 4
                                </p>
                                <div className="prog-grid1">
                                    <div className="prog-bar1" style={{ backgroundColor: "#DDE0E6" }}>
                                        <div className="prog-bar-per" style={{ width: "50%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className='container' style={{ backgroundColor: "#f7e9ec", padding: "20px", marginTop: "20px" }}>
                                <form>
                                    <div className="form-group zw_form_group">
                                        <div className="date-picker form-control zw_form_control zw_secondary poppins-regular zw_16 pt-3 zw_date">
                                            <i className="fa fa-calendar cal" aria-hidden="true"></i>
                                            <DatePicker className="custom-input1 zw_border_none" selected={startDate} onChange={(date) => setstartDate(date)} />
                                        </div>
                                    </div>


                                    <div className='mt-4 mb-4'>
                                        <label className='poppins-medium zw_text_color zw_24 zw_radio_btn_label'>Gender</label>
                                        <button type='radio' className='zw_radio_btn btn poppins-regular zw_22  zw_black zw_radio_group' >Male</button>
                                        <button type='radio' className='zw_radio_btn btn poppins-regular zw_22 zw_black zw_radio_group' >Female</button>
                                        <button type='radio' className='zw_radio_btn btn poppins-regular zw_22 zw_black zw_radio_group' >Other</button>

                                    </div>
                                    <button type='button' className='btn btn-primary btn-lg btn-block poppins-regular zw_bg_gradient zw_btn_18 zw_border_none' style={{ width: "80%", margin: " 0 10%" }} data-bs-toggle="modal" data-bs-target="#LogInPopUpModal4">
                                        Next
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSetup_gender