import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import { Link } from 'react-router-dom';
import '../../Style/Caregivert1.css';
import care1 from '../../assets/img/care1.jpeg';
function Caregivertask1() {
    return (
        <div>
            <Navbar />

            <div class="sa-container sa-content " style={{ marginTop: "150px" }}>

                <Link className="care-giver" to="/Caregiver/lat=24.7557387&long=46.63021479999999&stid=ST0&gnd=any&pId=ST04">
                    <span class="sa-btn-back">
                        <i class="icon-arrow-left">

                        </i>
                        <span class="back-text">Back</span>
                    </span>
                </Link>
                <br />
                <br />

                <div class="sa-prog-grid">
                    <p>
                        <span>Step 2</span>
                        of 3
                    </p>
                    <div class="sa-prog-bar">
                        <div class="sa-prog-bar-per" style={{ "width": "66.6667%" }}></div>
                    </div>
                </div>
                <h5 class="sa-mob-header sa-mob-block">Details</h5>
                <br />
                {/* <div class="sa-row sa-top-space40" >
                    <div class="sa-col7 sa-col-md8 sa-col-sm6 sa-col-xs12" >
                        <div class="sa-info-light-bg sa-card-no-border sa-caregiver-helper" style={{ borderRadius: "10px", border: "none" }}>
                            <div class="sa-caregiver-in" >
                                <h5 style={{ fontWeight: "bold" }}>Caregiver - Senior Helpers</h5>
                                <p style={{ marginTop: "-10px" }}>
                                    Assisting with daily tasks, keeping track of dietary needs, reminding of medication,<br />accompanying the elderly to the hospital, taking care of you at home for 12 hours a day.</p>
                                <figure >
                                    <img className="sa-img-cover" src={care1} alt="/" />
                                </figure>
                            </div>
                            <h6 class="sa-primary-text sa-mt10 sa-mb10">Includes</h6>
                            <ul style={{ padding: "10px", fontWeight: "bold" }}>
                                <li>
                                    <span>
                                        <i class="icon-checked2">

                                        </i>
                                        ** Does not include specialized nursing services such as injections.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="sa-col5 sa-col-md4 sa-col-sm6 sa-col-xs12" style={{ paddingLeft: "20px", marginTop: "-30px" }}>
                        <h5 class="sa-mb20 sa-fw500" style={{ fontWeight: "bold" }}>Select duration of care</h5>
                        <div class="sa-card-shadow" style={{ marginTop: "-10px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>1 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>3000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <br />
                        <div class="sa-card-shadow" style={{ marginTop: "-10px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>2 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>5000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <br />
                        <div class="sa-card-shadow" style={{ marginTop: "-10px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>3 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>6800</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <br />
                        <div class="sa-card-shadow" style={{ marginTop: "-10px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>4 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>8000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div> */}
                <div class="sa-row sa-top-space40">
                    <div class="sa-col7 sa-col-md8 sa-col-sm6 sa-col-xs12">
                        <div class="sa-info-light-bg sa-card-no-border sa-caregiver-helper">
                            <div class="sa-caregiver-in">
                                <h5 style={{ fontWeight: "large" }}>Caregiver - Senior Helpers </h5>
                                <p>
                                    Assisting with daily tasks, keeping track of dietary needs, reminding of medication,<br />accompanying the elderly to the hospital, taking care of you at home for 12 hours a day.
                                </p>
                                <figure>
                                    <img src={care1} alt="" style={{ width: "112px", height: "53px" }} />
                                </figure>
                            </div>
                            <h6 class="sa-primary-text sa-mt10 sa-mb10" style={{ fontWeight: "bold" }}>Includes</h6>
                            <ul style={{ padding: "10px", fontWeight: "bold" }}>
                                <li>
                                    <span>
                                        <i class="icon-checked2">

                                        </i>
                                        ** Does not include specialized nursing services such as injections.
                                    </span>
                                </li>

                            </ul><br />
                        </div>
                    </div>
                    <div class="sa-col5 sa-col-md4 sa-col-sm6 sa-col-xs12">
                        <h5 class="sa-mb20 sa-fw500" style={{ marginLeft: "20px", fontWeight: "large" }}>Select duration of care</h5>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>1 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>3000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>2 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>5000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>3 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>6800</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div>
                        <div class="sa-card-shadow" style={{ marginTop: "20px", marginLeft: "20px" }}>
                            <h5 class="title" style={{ marginTop: "0px", fontWeight: "bold" }}>
                                <span>4 Week- </span>
                                <span>12 Hours</span>
                            </h5>
                            <div class="sa-select-duration" style={{ margin: "20px", marginLeft: "-2px", padding: "10px", borderRadius: "10px" }}>
                                <p style={{ margin: "10px" }}>
                                    <i class="icon-tagnew">

                                    </i>
                                    <span>Start from</span>
                                    <strong class="sa-mr5">SAR</strong>
                                    <strong>8000</strong>
                                </p>
                                <button style={{ marginRight: "10px" }} >
                                    <i class="icon-arrow-right">

                                    </i>
                                </button>
                            </div>
                        </div><br />
                    </div>
                </div>
                <br />
            </div>
            <Footer />
        </div>
    )
}

export default Caregivertask1
