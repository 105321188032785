import React, { useState } from 'react';
import '../../Style/Home.css'

function Joinus() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubscribe = async (e) => {
        e.preventDefault();
        if (!email.trim()) {
            setError('Please enter your email.');
            return;
        }
        const response = await fetch('https://zwarra.biztechnosys.com/api/subscribe', {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            console.log('Subscription successful');
            setSuccess(true);
            setEmail('');
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        } else {
            console.error('Failed to subscribe');
            setSuccess(false);
        }
        const result = await response.json();
        console.log(result);
    }

    const handleChange = (e) => {
        if (error) {
            setError('');
        }
        setEmail(e.target.value);
    }
    return (
        <>
            <div className='zw_joinus'>
                <div className='z-join-us-bg'>
                    <div className='container z-mob-none '>
                        <div className='row'>
                            <div className='col-md-4' >
                                <img src="images/zawaara-medical portal.svg" className="img-fluid z-card-img rounded-start" alt="..." />
                            </div>
                            <div className='col-md-8 '>
                                <h5 className='zw_newsletter poppins-semibold  zw_16 zw_title_color'> Newsletter</h5>
                                <h5 className='zw_new_title poppins-semibold  zw_46 zw_news_text'> JOIN US</h5>
                                <p className='zw_news_des zw_16 poppins-regular'>Subscribe to our newsletter for new updates, and medical information.</p>
                                <div class="input-group mb-3">
                                    <input type="text" className="form-control zw_secondary poppins-regular zw_16" placeholder="Your Email" aria-label="Recipient's username" aria-describedby="basic-addon2" value={email}
                                        onChange={handleChange} />
                                    <div className="input-group-append  poppins-regular zw_btn_18">
                                        <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                            onClick={handleSubscribe}>Subscribe</button>
                                    </div>
                                </div>
                                <div className='zw_error zw_16 poppins-regular'>
                                    {error && <div className="text-danger">{error}</div>}
                                    {success && <div className="text-success">Subscription successful</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Joinus

